import lodash from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Field, FieldArray } from 'redux-form'
import classnames from 'classnames'
import { SelectField, CheckboxField, FileField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'

import AbstractAction from './AbstractAction'
import VariableField from '../common/fields/VariableField'
import CardTextField from '../common/fields/CardTextField'
import UrlField from '../common/fields/UrlField'
import { isValidUrlOrVariable, lengthWithoutVariable } from '../../helpers/validation'
import { hasVariable } from '../../helpers/actionHelper'

export class ChooseScenarioAction extends AbstractAction {
  static propTypes = {
    ...AbstractAction.propTypes,
    domains: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    onClickImageReset: PropTypes.func.isRequired,
    onChangeImageFile: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = { ...this.state, previewImageUrl: undefined }
  }

  getTitle() {
    return this.context.t('topic.actionList.choose_scenario.title')
  }
  getType() {
    return 'user'
  }

  static validate = action => {
    const errors = super.validate(action)

    if (lodash.filter(action.choices).length === 0) {
      errors.choices = { _error: 'validate.required' }
    }

    return errors
  }

  static validateForLine = action => {
    if (lengthWithoutVariable(action.text) > 60) {
      return false
    }
    return true
  }

  componentDidMount() {
    const { action } = this.props

    if (!hasVariable(action.image_url)) {
      this.setState({ previewImageUrl: action.image_url })
    }
  }

  changePreviewImage = url => {
    this.setState({ previewImageUrl: !hasVariable(url) ? url : null })
  }

  onChangeFile = e => {
    const { t } = this.context

    this.props.onChangeImageFile(e, url => {
      this.changePreviewImage(url)
      if (url == null) window.alert(t('validate.invalidImage'))
    })
  }

  onImageUrlBlur = e => {
    const input_image_url = e.nativeEvent.target.value
    if (isValidUrlOrVariable(input_image_url)) {
      this.changePreviewImage(input_image_url)
    }
  }

  onErrorImageLoading = () => {
    const { t } = this.context
    const { action } = this.props

    this.changePreviewImage(null)
    this.props.onError(
      t(`topic.actionList.choose_scenario.errors.failedToLoadImage.${action.image_method}`),
      { level: 'warn' }
    )
  }

  onClickImageReset = () => {
    const { name } = this.props

    this.props.onClickImageReset(name)
    this.changePreviewImage(null)
  }

  onChangeImageMethod = e => {
    const { action } = this.props

    let url
    if (e.nativeEvent.target.value === 'upload') {
      url = action.upload_image_url
    } else if (e.nativeEvent.target.value === 'url') {
      url = action.input_image_url
    }
    this.changePreviewImage(url)
  }

  generateOptions = (domain, level, isIndent) => {
    if (!domain) return []

    const { domains, topics } = this.props
    const options = []
    //  Skip root domain
    if (domain.parent_id) {
      options.push({
        value: `domain:${domain.id}`,
        display: (isIndent ? '\u00A0'.repeat((level - 1) * 4) : '') + `\uf07c ${domain.name}`,
      })
    }

    const childDomains = lodash.map(lodash.filter(domains, { parent_id: domain.id }), domain => ({
      itemType: 'domain',
      ...domain,
    }))
    const childTopics = lodash.map(lodash.filter(topics, { domain_id: domain.id }), topic => ({
      itemType: 'topic',
      ...topic,
    }))
    lodash.sortBy(lodash.concat(childDomains, childTopics), 'order').forEach(item => {
      if (item.itemType === 'domain') {
        const childOptions = this.generateOptions(item, level + 1, isIndent)
        options.push(...childOptions)
      } else {
        options.push({
          value: `topic:${item.id}`,
          display: (isIndent ? '\u00A0'.repeat(level * 4) : '') + `\uf15c ${item.name}`,
        })
      }
    })

    return options
  }

  renderChoices = ({ fields, meta: { error, submitFailed } }) => {
    const { t } = this.context
    const { domains } = this.props

    const rootDomain = lodash.find(domains, { parent_id: null })
    const items = this.generateOptions(rootDomain, 0, true)
    const flattenItems = this.generateOptions(rootDomain, 0, false)
    const renderCell = name => (
      <Field
        name={name}
        className="form-control dm-form-control with-icon"
        component={SelectField}
        items={field => (field.meta.active ? items : flattenItems)}
        valueKey="value"
        displayKey="display"
        empty="true"
        format={value => (value == null ? '' : `${value.type}:${value.topic_id || value.domain_id}`)}
        parse={value => {
          if (!value) return value
          const [type, id] = value.split(':')
          return { type, [`${type}_id`]: parseInt(id, 10) }
        }}
      />
    )

    const columns = [
      {
        Header: (
          <th key="label">
            {t('topic.actionList.choose_scenario.target')}
            <Tooltip name="topic.tooltip.actionList.choose_scenario.target" />
          </th>
        ),
        Cell: renderCell,
      },
    ]
    return (
      <div>
        <IncrementableTable fields={fields} columns={columns} maxRows={15} />
        {submitFailed && error && <div className="error">{t(error)}</div>}
      </div>
    )
  }

  renderImageSetting = () => {
    const { t } = this.context
    const { name, action } = this.props

    return (
      <React.Fragment>
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-md-4 col-sm-3"
            name="topic.actionList.choose_scenario.image"
          />
          <div className="form-inline col-md-4 col-sm-5">
            <Field
              key="url"
              name={`${name}.image_method`}
              id={`${name}.url`}
              type="radio"
              value="url"
              className="form-control"
              component="input"
              onChange={this.onChangeImageMethod}
            />
            <LabelWithTooltip
              htmlFor={`${name}.url`}
              className="dm-checkbox"
              name="topic.actionList.choose_scenario.pasteUrl"
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 col-sm-5">
            {this.state.previewImageUrl && (
              <div>
                <img
                  src={this.state.previewImageUrl}
                  className="preview"
                  alt=""
                  onError={this.onErrorImageLoading}
                />
                {action.image_method === 'upload' && (
                  <button type="button" className="btn btn-warning dm-btn" onClick={this.onClickImageReset}>
                    {t('topic.actionList.choose_scenario.imageResetButton')}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="col-md-8 col-sm-7">
            <Field
              type="file"
              name={`${name}.image`}
              accept="image/png, image/jpeg"
              className={classnames({
                'form-control': true,
                'dm-form-control': true,
                hidden: action.image_method !== 'upload',
              })}
              component={FileField}
              onChange={this.onChangeFile}
            />
            <UrlField
              name={`${name}.input_image_url`}
              className={classnames({
                'form-control': true,
                'dm-form-control': true,
                hidden: action.image_method !== 'url',
              })}
              onBlur={this.onImageUrlBlur}
              ignoreValidation={(value, allValues) => lodash.get(allValues, `${name}.image_method`) !== 'url'}
              nullable={true}
            />
            <ul className="notes">
              <li>{t('topic.actionList.choose_scenario.notes.authorizedFileFormat')}</li>
              <li>{t('topic.actionList.choose_scenario.notes.maximumImageSize')}</li>
              <li>{t('topic.actionList.choose_scenario.notes.maxFileSize')}</li>
              {action.image_method === 'url' && (
                <li>{t('topic.actionList.choose_scenario.notes.protocol')}</li>
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderBody() {
    const { t } = this.context
    const { name } = this.props

    return (
      <div className="choose">
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="title"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose_scenario.chooseScenarioTitle"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <VariableField name={`${name}.title`} className="form-control dm-form-control" />
          </div>
        </div>
        {this.renderImageSetting()}
        <div className="row form-group">
          <LabelWithTooltip
            htmlFor="text"
            className="control-label col-lg-2 col-md-2 col-sm-12"
            name="topic.actionList.choose_scenario.text"
          />
          <div className="col-lg-10 col-md-10 col-sm-12">
            <CardTextField name={`${name}.text`} className="form-control" />
          </div>
        </div>
        <label>{t('topic.actionList.choose_scenario.choices')}</label>
        <FieldArray name={`${name}.choices`} component={this.renderChoices} />
        <div className="form-group form-inline mt-1">
          <Field
            type="checkbox"
            name={`${name}.enable_classification`}
            id={`${name}.enable_classification`}
            component={CheckboxField}
            className="form-control"
          />
          <LabelWithTooltip
            htmlFor={`${name}.enable_classification`}
            className="dm-checkbox"
            name="bot.menu.intent_option"
          />
        </div>
      </div>
    )
  }
}

export default ChooseScenarioAction
