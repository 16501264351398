import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import Config from '../../helpers/config'
import DataBindingTable from '../common/DataBindingTable'
import { getCredentials } from '../../helpers/sessionHelper'

export class FaqList extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    bot: PropTypes.object.isRequired,
    faqs: PropTypes.array.isRequired,
    faqFiles: PropTypes.array.isRequired,
    tableState: PropTypes.object,
    updateTableState: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
  }

  static defaultProps = {
    faqs: [],
    faqFiles: [],
  }

  render = () => {
    const { t } = this.context
    const { bot, faqs, faqFiles, tableState, updateTableState, handleAdd } = this.props
    const { timezone } = getCredentials(this.context)

    const items = [
      ...faqs.map(faq => ({ ...faq, type: 'faq' })),
      ...faqFiles.map(faqFile => ({ ...faqFile, type: 'file' })),
    ]
    const disabledList = bot.has_tagged_faq_files || bot.number_of_faq_files > 1000

    const addButtonType = {
      'btn-primary': !disabledList && faqs.length < Config.maximumFaqs,
      'btn-alert': disabledList || faqs.length >= Config.maximumFaqs,
    }
    const columns = [
      {
        Header: t('faqfile.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => (
          <>
            {props.original.type === 'faq' && (
              <Link className="faq-item" to={`/bots/${bot.id}/faqs/${props.original.id}`}>
                {props.value}
              </Link>
            )}
            {props.original.type === 'file' && (
              <Link className="faq-item" to={`/bots/${bot.id}/faq_files/${props.original.id}`}>
                {props.value}
              </Link>
            )}
          </>
        ),
        getFooterProps: () => ({ className: 'footer-all' }),
        Footer: (
          <div>
            <div className="pull-left">
              <Link
                className={classnames('dm-btn', 'btn', 'btn-icon-plus', 'mini', addButtonType)}
                onClick={() => handleAdd('faq')}
              >
                <span>{t('faqfile.faq.new')}</span>
              </Link>
            </div>
            <div className="pull-right">
              {t('faqfile.remaining', {
                faqCount: faqs.length,
                faqTotal: Config.maximumFaqs,
                fileCount: bot.number_of_faq_files,
                fileTotal: Config.maximumFaqFiles,
              })}
            </div>
          </div>
        ),
      },
      {
        Header: t('faqfile.type'),
        id: 'type',
        width: 160,
        accessor: item => {
          if (item.type === 'faq') {
            return t('faqfile.faq.type')
          } else if (item.type === 'file') {
            return t('faqfile.faqFile.type')
          }
        },
      },
      {
        Header: t('common.lastUpdatedBy'),
        id: 'lastUpdatedBy',
        accessor: item => item.last_updated_by.name || '',
        width: 160,
      },
      {
        Header: t('common.updatedAt'),
        id: 'updatedAt',
        accessor: item =>
          item.updated_at ? moment.tz(item.updated_at, timezone).format('YYYY/MM/DD HH:mm') : '',
        width: 160,
      },
    ]

    return (
      <div>
        <DataBindingTable
          items={disabledList ? [] : items}
          columns={columns}
          tableName="FaqList"
          tableState={tableState}
          updateTableState={updateTableState}
          noDataText={disabledList ? t('faqfile.disabledListMessage') : undefined}
        />
      </div>
    )
  }
}

export default FaqList
