export default {
  server: 'https://management-api.dp.generative-ai-quick.com',
  messagingServer: 'https://api.dp.generative-ai-quick.com',
  voiceSynthServer: 'https://voicevox.dialogplay.jp',
  voiceSynthAccessToken: '8F9dJnGMYpc4RjZ7',
  toolDirectoryServer: 'https://tool-directory.dialogplay.jp',
  awsIotEndpoint: 'a209pk02fzv39f-ats.iot.ap-northeast-1.amazonaws.com',
  importantMessagesURLs: {
    json: 'https://upload.aiquick-dp/notices/important.json'
  },
  embeddedChatURL: 'https://dp.generative-ai-quick.com/embedded',
  redirectURL: 'https://redirect.dp.generative-ai-quick.com',
  paymentStoreId: '113914',
  IdentityPoolId: 'ap-northeast-1:287e976a-4462-4c3e-a819-3c9fcba625e3'
}
