import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import { Field, FieldArray } from 'redux-form'
import classnames from 'classnames'
import { SelectField, CheckboxField, FileField } from '../common/fields/FormFields'
import IncrementableTable from '../common/IncrementableTable'
import LabelWithTooltip from '../common/LabelWithTooltip'
import CardTextField from '../common/fields/CardTextField'
import UrlField from '../common/fields/UrlField'
import Tooltip from '../common/Tooltip'
import { isValidUrlOrVariable } from '../../helpers/validation'
import { hasVariable } from '../../helpers/actionHelper'

export class MenuEdit extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    formValues: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    defaultBehavior: PropTypes.string,
    defaultOpened: PropTypes.bool.isRequired,
    defaultImageUrl: PropTypes.string,
    onChangeMenuImageFile: PropTypes.func.isRequired,
    addErrorNoticeImageLoading: PropTypes.func.isRequired,
    resetMenuImage: PropTypes.func.isRequired,
  }

  static defaultProps = {
    formValues: {},
    defaultBehavior: 'none',
    defaultOpened: false,
  }

  constructor() {
    super()
    this.state = { previewImageUrl: null }
  }

  componentDidMount() {
    const { defaultImageUrl } = this.props

    if (!hasVariable(defaultImageUrl)) {
      this.setState({ previewImageUrl: defaultImageUrl })
    }
  }

  // Move from bot index page to bot edit page
  componentDidUpdate(prevProps) {
    if (prevProps.defaultImageUrl === this.props.defaultImageUrl) return

    if (!hasVariable(this.props.defaultImageUrl)) {
      this.setState({ previewImageUrl: this.props.defaultImageUrl })
    }
  }

  changePreviewImage = url => {
    this.setState({ previewImageUrl: !hasVariable(url) ? url : null })
  }

  onChangeFile = e => {
    const { t } = this.context

    this.props.onChangeMenuImageFile(e, url => {
      this.changePreviewImage(url)
      if (url == null) window.alert(t('validate.invalidImage'))
    })
  }

  onImageUrlBlur = e => {
    const input_image_url = e.nativeEvent.target.value
    if (isValidUrlOrVariable(input_image_url)) {
      this.changePreviewImage(input_image_url)
    }
  }

  onErrorImageLoading = () => {
    const { t } = this.context
    const { formValues, addErrorNoticeImageLoading } = this.props

    this.changePreviewImage(null)
    addErrorNoticeImageLoading(t(`bot.menu.errors.failedToLoadImage.${formValues.image_method}`))
  }

  onClickImageReset = () => {
    this.props.resetMenuImage()
    this.changePreviewImage(null)
  }

  onChangeImageMethod = e => {
    const { formValues } = this.props

    let url
    if (e.nativeEvent.target.value === 'upload') {
      url = formValues.upload_image_url
    } else if (e.nativeEvent.target.value === 'url') {
      url = formValues.input_image_url
    }
    this.changePreviewImage(url)
  }

  generateOptions = (domain, level, isIndent) => {
    if (!domain) return []

    const { domains, topics } = this.props
    const options = []
    //  Skip root domain
    if (domain.parent_id) {
      options.push({
        value: `domain:${domain.id}`,
        display: (isIndent ? '\u00A0'.repeat((level - 1) * 4) : '') + `\uf07c ${domain.name}`,
      })
    }

    const childDomains = lodash.map(lodash.filter(domains, { parent_id: domain.id }), domain => ({
      itemType: 'domain',
      ...domain,
    }))
    const childTopics = lodash.map(lodash.filter(topics, { domain_id: domain.id }), topic => ({
      itemType: 'topic',
      ...topic,
    }))
    lodash.sortBy(lodash.concat(childDomains, childTopics), 'order').forEach(item => {
      if (item.itemType === 'domain') {
        const childOptions = this.generateOptions(item, level + 1, isIndent)
        options.push(...childOptions)
      } else {
        options.push({
          value: `topic:${item.id}`,
          display: (isIndent ? '\u00A0'.repeat(level * 4) : '') + `\uf15c ${item.name}`,
        })
      }
    })

    return options
  }

  renderImageFields = () => {
    const { t } = this.context
    const { formValues } = this.props

    return (
      <React.Fragment>
        <div className="row">
          <LabelWithTooltip name="bot.menu.image" className="col-sm-3" />
          <div className="form-inline col-sm-5 col-md-4">
            <Field
              key="menu-url"
              name="menu.image_method"
              id="menu-url"
              type="radio"
              value="url"
              className="form-control"
              component="input"
              onChange={this.onChangeImageMethod}
            />
            <LabelWithTooltip htmlFor="menu-url" className="dm-checkbox" name="bot.menu.pasteUrl" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            {this.state.previewImageUrl && (
              <div>
                <img
                  src={this.state.previewImageUrl}
                  className="preview"
                  alt=""
                  onError={this.onErrorImageLoading}
                />
                {formValues.image_method === 'upload' && (
                  <button type="button" className="btn btn-warning dm-btn" onClick={this.onClickImageReset}>
                    {t('bot.menu.imageResetButton')}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="col-sm-9">
            <Field
              type="file"
              name="menu.image"
              accept="image/png, image/jpeg"
              className={classnames({
                'form-control': true,
                'dm-form-control': true,
                hidden: formValues.image_method !== 'upload',
              })}
              component={FileField}
              onChange={this.onChangeFile}
            />
            <UrlField
              name="menu.input_image_url"
              className={classnames({
                'form-control': true,
                'dm-form-control': true,
                hidden: formValues.image_method !== 'url',
              })}
              onBlur={this.onImageUrlBlur}
              ignoreValidation={(value, allValues) => lodash.get(allValues, 'menu.image_method') !== 'url'}
              nullable={true}
            />
            <ul className="notes">
              <li>{t('bot.menu.notes.authorizedFileFormat')}</li>
              <li>{t('bot.menu.notes.maximumImageSize')}</li>
              <li>{t('bot.menu.notes.maxFileSize')}</li>
              {formValues.image_method === 'url' && <li>{t('bot.menu.notes.protocol')}</li>}
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderChoices = ({ fields, meta: { error, submitFailed } }) => {
    const { t } = this.context
    const { domains } = this.props

    const rootDomain = lodash.find(domains, { parent_id: null })
    const items = this.generateOptions(rootDomain, 0, true)
    const flattenItems = this.generateOptions(rootDomain, 0, false)

    const renderCell = name => (
      <Field
        name={name}
        className="form-control dm-form-control with-icon"
        component={SelectField}
        items={field => (field.meta.active ? items : flattenItems)}
        valueKey="value"
        displayKey="display"
        empty="true"
        format={value => (value == null ? '' : `${value.type}:${value.topic_id || value.domain_id}`)}
        parse={value => {
          if (!value) return value
          const [type, id] = value.split(':')
          return { type, [`${type}_id`]: parseInt(id, 10) }
        }}
      />
    )
    const columns = [
      {
        Header: t('bot.menu.target'),
        Cell: renderCell,
      },
    ]
    return (
      <div>
        <IncrementableTable fields={fields} columns={columns} maxRows={15} />
        {submitFailed && error && <div className="error">{t(error)}</div>}
      </div>
    )
  }

  render() {
    const { t } = this.context
    const { defaultBehavior, defaultOpened, topics } = this.props

    return (
      <div className="menu">
        <Collapsible
          open={defaultOpened}
          transitionTime={300}
          overflowWhenOpen="visible"
          trigger={
            <span>
              {t('bot.menu.title')}
              <Tooltip name="bot.tooltip.menu.title" />
            </span>
          }
        >
          <div className="with-indent">
            <div className="form-group form-inline">
              <Field
                key="none"
                name="default_behavior"
                id="none"
                type="radio"
                value="none"
                className="form-control"
                component="input"
              />
              <LabelWithTooltip htmlFor="none" name="bot.menu.default" />
            </div>
            <div className="form-group form-inline">
              <Field
                key="menu"
                name="default_behavior"
                id="menu"
                type="radio"
                value="menu"
                className="form-control"
                component="input"
              />
              <LabelWithTooltip htmlFor="menu" name="bot.menu.enabled" />
            </div>
            {defaultBehavior === 'menu' && (
              <div className="form-group dm-form-group menu-paragraph">
                {this.renderImageFields()}
                <div className="row">
                  <LabelWithTooltip htmlFor="menu.text" className="col-sm-3" name="bot.menu.content" />
                  <div className="col-sm-5">
                    <CardTextField
                      name="menu.text"
                      className="form-control dm-form-control"
                      minRows={4}
                      ignoreValidation={(value, allValues) => allValues.default_behavior !== 'menu'}
                    />
                  </div>
                </div>
                <div className="row">
                  <LabelWithTooltip htmlFor="menu.choices" className="col-sm-3" name="bot.menu.menu" />
                  <div className="col-sm-6">
                    <FieldArray name="menu.choices" component={this.renderChoices} />
                  </div>
                </div>
                <div className="form-group form-inline checkbox">
                  <Field
                    type="checkbox"
                    name="menu.enable_classification"
                    id="menu.enable_classification"
                    className="form-control m-0"
                    component={CheckboxField}
                  />
                  <LabelWithTooltip
                    htmlFor="menu.enable_classification"
                    className="dm-checkbox"
                    name="bot.menu.intent_option"
                  />
                </div>
              </div>
            )}
            <div className="form-group form-inline">
              <Field
                key="default_topic"
                name="default_behavior"
                id="default_topic"
                type="radio"
                value="default_topic"
                className="form-control"
                component="input"
              />
              <LabelWithTooltip htmlFor="default_topic" className="dm-checkbox" name="bot.menu.single" />
            </div>
            {defaultBehavior === 'default_topic' && (
              <div className="form-group row dm-form-group menu-paragraph">
                <LabelWithTooltip htmlFor="default_topic_id" className="col-sm-3" name="bot.menu.scenario" />
                <div className="col-sm-5">
                  <Field
                    name="default_topic_id"
                    items={topics}
                    valueKey="id"
                    displayKey="name"
                    className="form-control dm-form-control col-sm-3"
                    component={SelectField}
                    empty={true}
                    parse={value => value && parseInt(value, 10)}
                    order="asc"
                  />
                </div>
              </div>
            )}
          </div>
        </Collapsible>
      </div>
    )
  }
}

export default MenuEdit
