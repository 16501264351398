import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, Field, reduxForm, propTypes } from 'redux-form'

import { addNotice } from '../../actions/notice'
import { fetchEntity, updateEntity, createEntity, deleteEntity, exportEntity } from '../../actions/entity'
import { updateTableState } from '../../actions/table'
import { fetchBot } from '../../actions/bot'
import { fetchApplications } from '../../actions/application'
import { FileField, InputField, SelectField } from '../../components/common/fields/FormFields'
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'
import { checkApplications } from '../../helpers/checkApplications'
import { downloadBlob } from '../../helpers/download'
import DataBindingTable from '../../components/common/DataBindingTable'
import { Simulator } from '../simulator/Simulator'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else {
    if (data.name.length > 255) {
      errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }

  if (data.type === 'regex') {
    if (!data.pattern) {
      errors.pattern = 'validate.required'
    } else {
      try {
        new RegExp(data.pattern)
      } catch (e) {
        errors.pattern = 'validate.invalidPattern'
      }
    }
  }

  return errors
}

export class EntityEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    entity: PropTypes.object,
    type: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      id: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
    applications: PropTypes.array.isRequired,
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchEntity(state.session.token, this.props.params.id))
    }
    dispatch(fetchBot(state.session.token, this.props.params.bot_id))
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.bot_id }))
    cancelUrl.setRouteLeaveHook(this)
  }

  componentWillUpdate(nextProps) {
    const { t } = this.context
    const prevEntity = this.props.entity || {}
    const nextEntity = nextProps.entity || {}
    if (prevEntity.id !== nextEntity.id && nextEntity.is_system) {
      Promise.resolve()
        .then(() => this.context.router.replace('/'))
        .then(() => nextProps.dispatch(addNotice('error', t('error.forbidden'))))
    }
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const router = this.context.router

    let request_body
    if (data.type === 'regex') {
      request_body = {
        name: data.name,
        type: data.type,
        bot_id: this.props.params.bot_id,
        pattern: data.pattern,
      }
    } else {
      if (data.upload_file) {
        request_body = new FormData()
        request_body.append('name', data.name)
        request_body.append('type', data.type)
        request_body.append('bot_id', this.props.params.bot_id)
        request_body.append('file', data.upload_file[0])
      } else {
        request_body = {
          name: data.name,
          type: data.type,
          bot_id: this.props.params.bot_id,
        }
      }
    }

    if (this.props.params.id) {
      return dispatch(updateEntity(state.session.token, this.props.params.id, request_body))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() =>
          checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
        )
        .finally(() => {
          if (lodash.includes(['dict', 'input_correct', 'output_correct'], data.type)) {
            document.getElementById('upload_file').value = ''
          }
        })
    } else {
      if (
        lodash.includes(['dict', 'input_correct', 'output_correct'], data.type) &&
        (!data.upload_file || !data.upload_file[0])
      ) {
        dispatch(addNotice('error', t('entity.noFileSelectedError')))
        return
      } else {
        return dispatch(createEntity(state.session.token, request_body))
          .then(json =>
            router.push({
              pathname: `/bots/${this.props.params.bot_id}/entities/${json.result}`,
              state: { ignoreBlocking: true },
            })
          )
          .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
          .then(() =>
            checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
          )
          .finally(() => {
            if (lodash.includes(['dict', 'input_correct', 'output_correct'], data.type)) {
              document.getElementById('upload_file').value = ''
            }
          })
      }
    }
  }

  handleDelete = () => {
    const { t } = this.context
    const { entity } = this.props
    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('entity.title'), name: entity.name })))
      return
    const state = this.context.store.getState()
    const router = this.context.router
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(deleteEntity(state.session.token, id))
      .then(() =>
        router.push({
          pathname: `/bots/${this.props.params.bot_id}/entities`,
          state: { ignoreBlocking: true },
        })
      )
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() =>
        checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
      )
  }

  handleExportEntity = () => {
    const state = this.context.store.getState()
    const { entity, dispatch } = this.props

    return dispatch(exportEntity(state.session.token, entity.id)).then(blob => {
      const filename = `${entity.name}.csv`
      downloadBlob(blob, filename)
    })
  }

  clearFile = () => {
    this.props.change('upload_file', null)
  }

  updateTableState = (path, tableName, tableState) => {
    const { dispatch } = this.props
    dispatch(updateTableState(path, tableName, tableState))
  }

  renderContentTable = () => {
    const { t } = this.context
    const { entity, isFetching, tableState } = this.props
    if (!this.props.params.id) return

    const items = lodash.map(entity.value_synonyms, (synonyms, value) => {
      return { value: value, synonyms: synonyms }
    })
    const columns = [
      {
        Header: t('entity.entityValueList.value'),
        accessor: 'value',
      },
      {
        Header: t('entity.entityValueList.synonyms'),
        id: 'synonyms',
        accessor: entityValue => (entityValue.synonyms ? entityValue.synonyms.join() : ''),
      },
    ]

    return (
      <div>
        <LabelWithTooltip className="dm-title-mini" name="entity.content" />
        <DataBindingTable
          items={items}
          columns={columns}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
        />
      </div>
    )
  }

  renderCorrectionTable = () => {
    const { t } = this.context
    const { entity, isFetching, tableState } = this.props
    if (!this.props.params.id) return

    const items = []
    lodash.forEach(entity.corrections, (correction, correctPriority) => {
      correctPriority = correctPriority + 1
      const correctTo = correction[0]
      const correctFrom = correction[1] ? correction[1].join() : ''
      items.push({ correctPriority, correctTo, correctFrom })
    })
    const columns = [
      {
        Header: t('entity.entityValueList.correctPriority'),
        id: 'index',
        accessor: 'correctPriority',
        width: 80,
      },
      {
        Header: t('entity.entityValueList.correctTo'),
        accessor: 'correctTo',
      },
      {
        Header: t('entity.entityValueList.correctFrom'),
        id: 'correctFrom',
        accessor: 'correctFrom',
      },
    ]

    return (
      <div>
        <LabelWithTooltip className="dm-title-mini" name="entity.content" />
        <DataBindingTable
          items={items}
          columns={columns}
          defaultSorted={[{ id: 'index', desc: false }]}
          isFetching={isFetching}
          tableState={tableState}
          updateTableState={this.updateTableState}
        />
      </div>
    )
  }

  render() {
    const { t } = this.context
    const { type, isFetching, submitting, handleSubmit, bot } = this.props
    const isUpdate = !!this.props.params.id
    const types = [{ id: 'regex', name: t('entity.types.regex') }]

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form
            className="text-left col-md-9"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.handleSave)}
          >
            <div className="form-group">
              <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="entity.name" />
              <Field
                type="text"
                name="name"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="type" className="dm-title-mini" name="entity.type" />
              <Field
                name="type"
                items={types}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control"
                disabled={isUpdate}
                component={SelectField}
                onChange={this.clearFile}
              />
            </div>
            {type === 'dict' && (
              <div>
                {this.renderContentTable()}
                <div className="form-group">
                  <LabelWithTooltip htmlFor="upload_file" className="dm-title-mini" name="entity.data" />
                  <Field
                    type="file"
                    name="upload_file"
                    className="form-control dm-form-control"
                    accept=".csv"
                    component={FileField}
                  />
                </div>
                <div className="form-group dm-example">
                  <LabelWithTooltip
                    htmlFor="example"
                    className="dm-title-mini"
                    name="entity.example.csvLayout"
                  />
                  <textarea
                    className="form-control dm-form-control"
                    readOnly={true}
                    rows="5"
                    defaultValue={t('entity.example.sampleData')}
                  />
                  <ul>
                    <li>
                      {t('entity.example.columns', { num: '1' })}
                      {t('entity.example.value')}
                    </li>
                    <li>
                      {t('entity.example.columnsAndLater', { num: '2' })}
                      {t('entity.example.synonym')}
                      <span className="dm-note">{t('entity.example.csvImportNote')}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {lodash.includes(['input_correct', 'output_correct'], type) && (
              <div>
                {this.renderCorrectionTable()}
                <div className="form-group">
                  <LabelWithTooltip htmlFor="upload_file" className="dm-title-mini" name="entity.data" />
                  <Field
                    type="file"
                    name="upload_file"
                    className="form-control dm-form-control"
                    accept=".csv"
                    component={FileField}
                  />
                </div>
                <div className="form-group dm-example">
                  <LabelWithTooltip
                    htmlFor="example"
                    className="dm-title-mini"
                    name="entity.example.csvLayout"
                  />
                  <textarea
                    className="form-control dm-form-control"
                    readOnly={true}
                    rows="6"
                    defaultValue={t('entity.example.sampleCorrectionData')}
                  />
                  <ul>
                    <li>
                      {t('entity.example.columns', { num: '1' })}
                      {t('entity.example.correctTo')}
                    </li>
                    <li>
                      {t('entity.example.columnsAndLater', { num: '2' })}
                      {t('entity.example.correctFrom')}
                    </li>
                  </ul>
                  {type === 'input_correct' && (
                    <span className="dm-note">{t('entity.example.inputCorrectNote')}</span>
                  )}
                  {type === 'output_correct' && (
                    <span className="dm-note">{t('entity.example.outputCorrectNote')}</span>
                  )}
                  <br />
                  <span className="dm-note">{t('entity.example.correctPriorityNote')}</span>
                </div>
              </div>
            )}
            {type === 'regex' && (
              <div className="form-group">
                <LabelWithTooltip htmlFor="pattern" className="dm-title-mini" name="entity.pattern" />
                <Field
                  type="text"
                  name="pattern"
                  className="form-control dm-form-control"
                  maxLength="255"
                  component={InputField}
                  placeholder={t('entity.patternPlaceholder')}
                />
              </div>
            )}
            <div className="form-group text-right">
              {this.props.params.id && lodash.includes(['dict', 'input_correct', 'output_correct'], type) && (
                <button
                  type="button"
                  className="btn btn-primary dm-btn"
                  onClick={this.handleExportEntity}
                  disabled={submitting}
                >
                  {t('common.export')}
                </button>
              )}
              <button type="submit" className="btn btn-primary dm-btn" disabled={submitting}>
                {t('common.save')}
              </button>
              {this.props.params.id && (
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDelete}
                  disabled={submitting}
                >
                  {t('common.delete')}
                </button>
              )}
            </div>
          </form>
          <Sidebar titles={[t('simulator.title')]}>
            <Simulator tabs={['chat']} bot={bot} />
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const EntityEditForm = reduxForm({
  form: 'EntityEdit',
  enableReinitialize: true,
  validate,
})(EntityEdit)

const selector = formValueSelector('EntityEdit')

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const entity = state.entities.entities[props.params.id] || { type: 'regex' }
  const bot = state.entities.bots[props.params.bot_id] || {}
  const type = selector(state, 'type')
  return {
    applications: applications,
    bot: bot,
    isFetching: isFetching(state),
    entity,
    type,
    tableState: state.table[window.location.pathname],
    initialValues: entity,
  }
}

export default connect(mapStateToProps)(EntityEditForm)
