import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { UAParser } from 'ua-parser-js'

import i18n from '../../middleware/i18n'
import CookiebotWidget from './CookiebotWidget'
import NoticeModeSwitcher from './NoticeModeSwitcher'
import { getBrowserLanguageCode } from '../../helpers/language'
import { getCredentials } from '../../helpers/sessionHelper'
import { isPermitted } from '../../helpers/permission'

class ChatMenu extends PureComponent {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    isSimulator: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    enableTranslation: PropTypes.bool.isRequired,
    operatorFunction: PropTypes.bool,
    resetFunction: PropTypes.bool,
    closeMenu: PropTypes.func.isRequired,
    handleSendMessage: PropTypes.func.isRequired,
    onChangeImageFile: PropTypes.func.isRequired,
    callingOperatorMessage: PropTypes.string,
    callingOperatorButton: PropTypes.string,
    noticeMode: PropTypes.string.isRequired,
    speakerNames: PropTypes.array,
    changeNoticeMode: PropTypes.func.isRequired,
    changeSpeaker: PropTypes.func.isRequired,
    stopReadingOutByVoiceSynth: PropTypes.func.isRequired,
    skipCookieConsent: PropTypes.bool,
    imageUploaderDisabled: PropTypes.bool,
    aitalkEnabled: PropTypes.bool,
    speechSynthesisDisabled: PropTypes.bool,
  }

  static defaultProps = {
    operatorFunction: false,
    resetFunction: false,
    skipCookieConsent: false,
    imageUploaderDisabled: false,
    aitalkEnabled: false,
    speechSynthesisDisabled: false,
  }

  onChangeImageFile = e => {
    const { onChangeImageFile, closeMenu } = this.props

    onChangeImageFile(e)
    // Can select same image file in a row
    document.querySelector('input#uploadImage').value = ''

    closeMenu()
  }

  callOperator = async () => {
    const { language, handleSendMessage, closeMenu, callingOperatorMessage } = this.props

    await i18n.loadLanguages(language)
    const message = callingOperatorMessage || this.context.t('triggers.operator', { lng: language })

    handleSendMessage({ text: message, skip_translation: true })
    closeMenu()
  }

  reset = async () => {
    const { language, handleSendMessage, closeMenu } = this.props
    await i18n.loadLanguages(language)
    const trigger = this.context.t('triggers.reset', { lng: language })
    handleSendMessage({ text: trigger, skip_translation: true })
    closeMenu()
  }

  render() {
    const { t } = this.context
    const {
      isSimulator,
      language,
      show,
      enableTranslation,
      operatorFunction,
      resetFunction,
      callingOperatorButton,
      noticeMode,
      speakerNames,
      changeNoticeMode,
      changeSpeaker,
      stopReadingOutByVoiceSynth,
      skipCookieConsent,
      aitalkEnabled,
      speechSynthesisDisabled,
    } = this.props

    //  Check permission in dashboard only
    const { plan } = getCredentials(this.context)
    const showOperatorButton =
      operatorFunction && (plan === undefined || isPermitted('feature_operator', this.context))

    const callingOperatorButtonLabel = callingOperatorButton || t('embeddedChat.callOperator')
    const noticeDisabled = new UAParser().getBrowser().name === 'IE'

    const serverMessageLanguage = enableTranslation ? getBrowserLanguageCode() : language
    const isJapaneseMessage = lodash.startsWith(serverMessageLanguage, 'ja')

    return (
      <div className={`dm-chat-menu ${show ? 'show-menu' : ''}`}>
        {!isSimulator && !skipCookieConsent && <CookiebotWidget />}
        <NoticeModeSwitcher
          noticeMode={noticeMode}
          speakerNames={speakerNames}
          changeNoticeMode={changeNoticeMode}
          changeSpeaker={changeSpeaker}
          stopReadingOutByVoiceSynth={stopReadingOutByVoiceSynth}
          disabled={noticeDisabled}
          aitalkEnabled={isJapaneseMessage && aitalkEnabled}
          voicevoxEnabled={isJapaneseMessage}
          speechSynthesisDisabled={speechSynthesisDisabled}
        />
        {showOperatorButton && (
          <button
            type="button"
            id="operator"
            className="btn btn-block btn-primary dm-btn btn-chat-command"
            onClick={this.callOperator}
          >
            {callingOperatorButtonLabel}
          </button>
        )}
        {resetFunction && (
          <button
            type="button"
            id="reset"
            className="btn btn-block btn-warning dm-btn btn-chat-command"
            onClick={this.reset}
          >
            {t('embeddedChat.reset')}
          </button>
        )}
      </div>
    )
  }
}

export default ChatMenu
