/**
 * Define default configurations which you rarely change
 * depending on the unnning environment
 *
 */
export default {
  callOperatorMessage: 'オペレーターに代わってください',
  resetMessage: 'リセット',
  undoMessage: '戻る',
  newsURLs: [
    'https://guide.dialogplay.jp/api/v2/help_center/ja/sections/360002140832/articles.json',
    'https://guide.dialogplay.jp/api/v2/help_center/ja/sections/360002025151/articles.json',
  ],
  importantMessagesURLs: {
    zendesk: 'https://guide.dialogplay.jp/api/v2/help_center/ja/sections/4409676481945/articles.json',
    json: 'https://upload.aiquick-dp/notices/important.json',
  },
  defaultMaxScenarios: 200,
  maxBotIntents: 1000,
  maximumDomainsNum: 400,
  maximumFaqs: 50,
  maximumFaqFiles: 1000,
  maximumEntitySpecificationNum: 2,
  maximumChoiceLabelLength: 40,
  emailSender: 'no-reply <no-reply@dialogplay.jp>',
  isExperimental: false,
  embeddedChatURL: 'https://dp.generative-ai-quick.com/embedded',
  redirectURL: 'https://redirect.dp.generative-ai-quick.com',
  toolDirectoryServer: 'https://tool-directory.dialogplay.jp',
}
