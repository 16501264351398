import lodash from 'lodash'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import Tabs, { TabPane } from 'rc-tabs'
import TabContent from 'rc-tabs/lib/TabContent'
import SwipeableInkTabBar from 'rc-tabs/lib/SwipeableInkTabBar'

import { SelectField, CheckboxField, FileField } from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import Tooltip from '../common/Tooltip'
import LabelWithTooltip from '../common/LabelWithTooltip'
import TitleField from '../common/fields/TitleField'
import CardTextField from '../common/fields/CardTextField'
import CarouselButtonLabelField from '../common/fields/CarouselButtonLabelField'
import CarouselButtonValueField from '../common/fields/CarouselButtonValueField'
import UrlField from '../common/fields/UrlField'
import { isValidUrlOrVariable } from '../../helpers/validation'
import { hasVariable } from '../../helpers/actionHelper'

export class CarouselTabs extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
  }

  static propTypes = {
    topicType: PropTypes.string,
    items: PropTypes.array.isRequired,
    fields: PropTypes.object,
    onClickImageReset: PropTypes.func.isRequired,
    onChangeCarouselImageFile: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    meta: PropTypes.object,
  }

  static maxTabs = 10

  constructor() {
    super()
    this.state = { activeKey: 'item-1', previewImageUrls: [] }
  }

  componentDidMount() {
    const { items } = this.props

    this.setState({
      previewImageUrls: lodash.map(items, item => (!hasVariable(item.image_url) ? item.image_url : null)),
    })
  }

  changePreviewImage = (index, url) => {
    const imageUrls = lodash.clone(this.state.previewImageUrls)
    if (!hasVariable(url)) {
      imageUrls[index] = url
    } else {
      imageUrls[index] = null
    }
    this.setState({ previewImageUrls: imageUrls })
  }

  onChangeFile = (e, index) => {
    const { t } = this.context

    this.props.onChangeCarouselImageFile(e, url => {
      this.changePreviewImage(index, url)
      if (url == null) window.alert(t('validate.invalidImage'))
    })
  }

  onChangeImageMethod = (e, index) => {
    const { items } = this.props

    let url
    if (e.nativeEvent.target.value === 'upload') {
      url = items[index].upload_image_url
    } else if (e.nativeEvent.target.value === 'url') {
      url = items[index].input_image_url
    }
    this.changePreviewImage(index, url)
  }

  onClickImageReset = (name, index) => {
    this.props.onClickImageReset(name)
    this.changePreviewImage(index, null)
  }

  onImageUrlBlur = (e, index) => {
    const input_image_url = e.nativeEvent.target.value
    if (isValidUrlOrVariable(input_image_url)) {
      this.changePreviewImage(index, input_image_url)
    }
  }

  onErrorImageLoading = (e, index) => {
    const { t } = this.context
    const { items } = this.props

    this.changePreviewImage(index, null)
    if (items[index].image_method === 'upload') {
      this.props.onError(t('topic.actionList.carousel.errors.failedToLoadUploadedImage'), { level: 'warn' })
    } else {
      this.props.onError(t('topic.actionList.carousel.errors.failedToLoadExternalImage'), { level: 'warn' })
    }
  }

  renderContent = (name, index) => {
    const { t } = this.context
    const {
      items,
      meta: { submitting },
    } = this.props
    const enableDefaultAction = items[index].enable_default_action

    return (
      <div>
        <div className="form-group row dm-form-group">
          <LabelWithTooltip className="dm-title-mini col-md-3" name="topic.actionList.carousel.id" />
          <div className="col-md-9">
            <TitleField name={`${name}.carousel_id`} className="form-control dm-form-control" />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini col-md-3"
            name="topic.actionList.carousel.carouselItemTitle"
          />
          <div className="col-md-9">
            <TitleField name={`${name}.title`} className="form-control dm-form-control" nullable={true} />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <LabelWithTooltip className="dm-title-mini col-md-3" name="topic.actionList.carousel.body" />
          <div className="col-md-9">
            <CardTextField name={`${name}.text`} className="form-control dm-form-control" nullable={true} />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <LabelWithTooltip
            className="dm-title-mini col-sm-3 col-md-4"
            name="topic.actionList.carousel.image"
          />
          <div className="form-inline col-sm-5 col-md-4">
            <Field
              key={`${name}-url`}
              name={`${name}.image_method`}
              id={`${name}-url`}
              type="radio"
              value="url"
              className="form-control"
              component="input"
              onChange={e => this.onChangeImageMethod(e, index)}
            />
            <LabelWithTooltip
              htmlFor={`${name}-url`}
              className="dm-checkbox"
              name="topic.actionList.carousel.pasteUrl"
              direction="left"
            />
          </div>
        </div>
        <div className="form-group row dm-form-group">
          <div className="col-sm-5 col-md-4">
            {this.state.previewImageUrls[index] && (
              <div>
                <img
                  src={this.state.previewImageUrls[index]}
                  className="preview"
                  alt=""
                  onError={e => this.onErrorImageLoading(e, index)}
                />
                {items[index].image_method === 'upload' && (
                  <button
                    type="button"
                    className="btn btn-warning dm-btn"
                    onClick={() => this.onClickImageReset(name, index)}
                    disabled={submitting}
                  >
                    {t('topic.actionList.carousel.imageResetButton')}
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="col-sm-7 col-md-8">
            <div className={items[index].image_method === 'upload' ? '' : 'hidden'}>
              <Field
                type="file"
                name={`${name}.image`}
                accept="image/png, image/jpeg"
                className="form-control dm-form-control"
                component={FileField}
                onChange={e => this.onChangeFile(e, index)}
              />
              <ul className="notes">
                <li>{t('topic.actionList.carousel.notes.authorizedFileFormat')}</li>
                <li>{t('topic.actionList.carousel.notes.maximumImageSize')}</li>
                <li>{t('topic.actionList.carousel.notes.maxFileSize')}</li>
              </ul>
            </div>
            <div className={items[index].image_method === 'url' ? '' : 'hidden'}>
              <UrlField
                name={`${name}.input_image_url`}
                className="form-control dm-form-control"
                onBlur={e => this.onImageUrlBlur(e, index)}
                ignoreValidation={(value, allValues) =>
                  lodash.get(allValues, `${name}.image_method`) !== 'url'
                }
                nullable={true}
              />
              <ul className="notes">
                <li>{t('topic.actionList.carousel.notes.authorizedFileFormat')}</li>
                <li>{t('topic.actionList.carousel.notes.protocol')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="form-group dm-form-group">
          <LabelWithTooltip className="dm-title-mini" name="topic.actionList.carousel.choices" />
          <FieldArray name={`${name}.actions`} component={this.renderButtons} />
        </div>
        <div className="form-group dm-form-group">
          <div className="form-inline">
            <Field type="checkbox" name={`${name}.enable_default_action`} component={CheckboxField} />
            <LabelWithTooltip
              className="dm-title-mini pl-1"
              name="topic.actionList.carousel.defaultAction"
              htmlFor={`${name}.enable_default_action`}
              direction="left top"
            />
          </div>
          {enableDefaultAction && this.renderDefaultAction(name, index)}
        </div>
      </div>
    )
  }

  renderDefaultAction = (name, index) => {
    const { t } = this.context
    const { topicType } = this.props
    const items = []
    if (topicType === 'normal') {
      items.push({ label: t('topic.actionList.carousel.action.postback'), value: 'postback' })
      items.push({ label: t('topic.actionList.carousel.action.uri'), value: 'uri' })
    } else {
      items.push({ label: t('topic.actionList.carousel.action.uri'), value: 'uri' })
    }

    let placeholder = null
    if ((this.props.items[index].default_action || {}).type === 'uri') {
      placeholder = t('topic.actionList.carousel.action.uriPlaceholder')
    }

    return (
      <table className="table table-bordered dm-table default-action">
        <thead>
          <tr>
            <th key="type" className="type">
              {t('topic.actionList.carousel.action.type')}
              <Tooltip name="topic.tooltip.actionList.carousel.action.type" />
            </th>
            <th key="value">
              {t('topic.actionList.carousel.action.value')}
              <Tooltip name="topic.tooltip.actionList.carousel.action.value" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td key="type">
              <Field
                name={`${name}.default_action.type`}
                className="form-control dm-form-control"
                items={items}
                valueKey="value"
                displayKey="label"
                empty={true}
                component={SelectField}
              />
            </td>
            <td key="value">
              <CarouselButtonValueField
                name={`${name}.default_action.value`}
                className="form-control dm-form-control"
                placeholder={placeholder}
              />
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  renderButtons = ({ fields }) => {
    const { t } = this.context
    const { topicType } = this.props
    const items = []
    if (topicType === 'normal') {
      items.push({ label: t('topic.actionList.carousel.action.postback'), value: 'postback' })
      items.push({ label: t('topic.actionList.carousel.action.uri'), value: 'uri' })
    } else {
      items.push({ label: t('topic.actionList.carousel.action.uri'), value: 'uri' })
    }
    const columns = [
      {
        Header: (
          <th key="type">
            {t('topic.actionList.carousel.action.type')}
            <Tooltip name="topic.tooltip.actionList.carousel.action.type" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.type`}
            className="form-control dm-form-control"
            items={items}
            valueKey="value"
            displayKey="label"
            empty={true}
            component={SelectField}
          />
        ),
      },
      {
        Header: (
          <th key="label">
            {t('topic.actionList.carousel.action.label')}
            <Tooltip name="topic.tooltip.actionList.carousel.action.label" />
          </th>
        ),
        // eslint-disable-next-line react/display-name
        Cell: (name, _index) => (
          <CarouselButtonLabelField
            name={`${name}.label`}
            className="form-control dm-form-control"
            ignoreValidation={(value, allValues) => !lodash.get(allValues, name)}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('topic.actionList.carousel.action.value')}
            <Tooltip direction="left" name="topic.tooltip.actionList.carousel.action.value" />
          </th>
        ),
        Cell: (name, index) => {
          let placeholder = null
          if ((fields.get(index) || {}).type === 'uri') {
            placeholder = t('topic.actionList.carousel.action.uriPlaceholder')
          }

          return (
            <CarouselButtonValueField
              name={`${name}.value`}
              className="form-control dm-form-control"
              nullable={true}
              placeholder={placeholder}
            />
          )
        },
      },
    ]
    // Render field table for buttons in carousel's column
    return <IncrementableTable fields={fields} columns={columns} maxRows={3} />
  }

  renderTab = (name, index) => {
    const { t } = this.context
    const { fields } = this.props

    return (
      <span className="item-tab">
        {index + 1}
        {fields.length > 1 && (
          <button
            type="button"
            className="icon-arrows-remove"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              if (!window.confirm(t('common.deleteConfirmMessage', { name: index + 1 }))) {
                return
              }
              fields.remove(index)
              const activeIndex = parseInt(this.state.activeKey.match(/^item-(\d+)$/)[1], 10)
              const imageUrls = lodash.clone(this.state.previewImageUrls)
              imageUrls.splice(index, 1)
              this.setState({ previewImageUrls: imageUrls })
              if (fields.length <= activeIndex) {
                this.setState({ activeKey: `item-${activeIndex - 1}` })
              }
            }}
          />
        )}
      </span>
    )
  }

  renderTabPanes() {
    const { fields } = this.props

    // Render fields for carousel columns
    const panes = fields.map((name, index) => {
      return (
        <TabPane tab={this.renderTab(name, index)} key={`item-${index + 1}`}>
          {this.renderContent(name, index)}
        </TabPane>
      )
    })

    if (panes.length < CarouselTabs.maxTabs) {
      panes.push(
        <TabPane
          tab={
            <button
              type="button"
              className="dm-btn btn btn-primary btn-icon-plus mini"
              onClick={() => {
                fields.push({ image_method: 'upload', enable_default_action: false })
                setTimeout(() => {
                  this.setState({ activeKey: `item-${fields.length + 1}` })
                }, 0)
              }}
            />
          }
          key="item-add"
          disabled={true}
        />
      )
    }

    return panes
  }

  render() {
    return (
      <Tabs
        className="dm-tabs"
        activeKey={this.state.activeKey}
        onChange={key => {
          this.setState({ activeKey: key })
        }}
        renderTabBar={() => <SwipeableInkTabBar pageSize={10} speed={10} />}
        renderTabContent={() => <TabContent animatedWithMargin />}
      >
        {this.renderTabPanes()}
      </Tabs>
    )
  }
}

export default CarouselTabs
