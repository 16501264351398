import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import url from 'url'
import { connect } from 'react-redux'
import { formValueSelector, Field, FieldArray, reduxForm, propTypes } from 'redux-form'
import ReactLoader from 'react-loader'
import { validHeaderName, validHeaderValue } from 'check-header'
import { isUUID } from 'validator'
import { isContainVariable, isValidLengthForNormalText } from '../../helpers/validation'

// components
import {
  InputField,
  TextAreaField,
  SelectField,
  AutoCompleteField,
  CheckboxField,
} from '../../components/common/fields/FormFields'
import Sidebar from '../../components/common/Sidebar'
import Loader from '../../components/common/Loader'
import Tooltip from '../../components/common/Tooltip'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import IncrementableTable from '../../components/common/IncrementableTable'
import MultipleSelectList from '../../components/common/MultipleSelectList'
import DataBindingTable from '../../components/common/DataBindingTable'
import IntegrationExecute from '../../components/integration/IntegrationExecute'
import { Simulator } from '../simulator/Simulator'

// actions
import { addNotice } from '../../actions/notice'
import { fetchEmailAddresses } from '../../actions/email_address'
import { fetchOAuthClients, getSalesforceObject, getSalesforceObjectDetail } from '../../actions/oauth_client'
import { createOAuthSession } from '../../actions/oauth_session'
import {
  fetchIntegration,
  updateIntegration,
  createIntegration,
  deleteIntegration,
  clearIntegrationResult,
  executeIntegration,
  setErrorOnIntegration,
} from '../../actions/integration'
import { fetchBot } from '../../actions/bot'
import { fetchApplications } from '../../actions/application'

// helpers
import i18next from '../../middleware/i18n'
import { isFetching } from '../../helpers/selector'
import cancelUrl from '../../helpers/cancelurl'
import { getCredentials } from '../../helpers/sessionHelper'
import { isPermitted } from '../../helpers/permission'
import { checkApplications } from '../../helpers/checkApplications'
import Config from '../../helpers/config'
import {
  getReplaceSystemVariableValue,
  replaceSystemVariableWhenOnChange,
} from '../../helpers/replaceSystemVariable'
import {
  isIncludingInvalidSymbols,
  isValidEmailOrVariable,
  lengthWithoutVariable,
} from '../../helpers/validation'
import { ToolDirectory } from '../../helpers/toolDirectory'

const validate = data => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 100) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 100 } }
  }
  if (data.description && data.description.length > 255) {
    errors.description = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  }
  if (!data.type) {
    errors.type = 'validate.required'
  }

  // HTTP
  if (data.type === 'http') {
    if (!data.url) {
      errors.url = 'validate.required'
    } else if (data.url.length > 255) {
      errors.url = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    } else if (!isContainVariable(data.url)) {
      const result = url.parse(data.url)
      if (!lodash.includes(['http:', 'https:'], result.protocol) || !result.slashes || !result.host) {
        errors.url = 'validate.invalidURL'
      }
    }
    errors.headers =
      data.headers &&
      data.headers.map(row => {
        const error = {}
        if (row.key && !row.value) error.value = 'validate.required'
        if (row.value && !row.key) error.key = 'validate.required'
        if (row.key && !validHeaderName(row.key)) error.key = 'validate.invalid'
        if (row.value && !validHeaderValue(row.value.replace(/\{\{(.+)\}\}/, '')))
          error.value = 'validate.invalid'
        return error
      })

    if (data.response_type === 'json') {
      errors.response_map =
        data.response_map &&
        data.response_map.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    } else if (data.response_type === 'text') {
      if (data.response_body_variable) {
        if (data.response_body_variable.length > 30) {
          errors.response_body_variable = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        } else if (isIncludingInvalidSymbols(data.response_body_variable)) {
          errors.response_body_variable = 'validate.invalidSymbolIncluded'
        }
      }
    }

    if (data.result_variable) {
      if (data.result_variable.length > 30) {
        errors.result_variable = { id: 'validate.exceededMaxLength', values: { length: 30 } }
      } else if (isIncludingInvalidSymbols(data.result_variable)) {
        errors.result_variable = 'validate.invalidSymbolIncluded'
      } else {
        if (data.response_type === 'json') {
          if (data.response_map && lodash.some(data.response_map, { to: data.result_variable })) {
            errors.result_variable = 'validate.nameDuplicatedResponseMap'
          }
        } else if (data.response_type === 'text') {
          if (data.response_body_variable && data.response_body_variable === data.result_variable) {
            errors.result_variable = 'validate.nameDuplicatedVariableForResponseBody'
          }
        }
      }
    }

    let usedStatusCodes = []
    errors.user_status_code_map =
      data.user_status_code_map &&
      data.user_status_code_map.map(row => {
        const error = {}

        if (!row.status_code) {
          if (row.result || row.value) error.status_code = 'validate.required'
        } else if (row.status_code.length > 1000) {
          error.status_code = { id: 'validate.exceededMaxLength', values: { length: 1000 } }
        } else {
          const statusCodes = row.status_code.trim().split(/\s*,\s*/)
          lodash.forEach(statusCodes, statusCode => {
            if (!error.status_code) {
              const pattern = /^(\d{3})(?:-(\d{3}))?$/
              const matchedPattern = statusCode.match(pattern)
              if (!matchedPattern) {
                error.status_code = 'validate.invalid'
              } else {
                const start = parseInt(matchedPattern[1], 10)
                const end = matchedPattern[2] ? parseInt(matchedPattern[2], 10) : start

                if (start > end) {
                  error.status_code = 'validate.invalidRangeForStatusCode'
                } else if (!lodash.every([start, end], code => lodash.inRange(code, 100, 600))) {
                  error.status_code = 'validate.invalidStatusCode'
                } else {
                  const codes = lodash.range(start, end + 1)
                  if (!lodash.isEmpty(lodash.intersection(usedStatusCodes, codes))) {
                    error.status_code = 'validate.statusCodeDuplicated'
                  }

                  usedStatusCodes = lodash.concat(usedStatusCodes, codes)
                }
              }
            }
          })
        }
        if (!row.result) {
          if (row.status_code || row.value) error.result = 'validate.required'
        }
        if (row.value && row.value.length > 1000) {
          error.value = { id: 'validate.exceededMaxLength', values: { length: 1000 } }
        }

        return error
      })
  }

  // Google spreadsheet
  if (data.type === 'google_spreadsheet') {
    if (!data.service_account_key) {
      errors.service_account_key = 'validate.required'
    }
    if (!data.sheet_id) {
      errors.sheet_id = 'validate.required'
    } else if (data.sheet_id.length > 50) {
      errors.sheet_id = { id: 'validate.exceededMaxLength', values: { length: 50 } }
    }
    errors.response_map =
      data.response_map &&
      data.response_map.map(row => {
        const error = {}
        if (!row.from) error.from = 'validate.required'
        if (!row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
  }

  // Email
  if (data.type === 'email') {
    let exists_to_addresses = false
    errors.to_addresses =
      data.to_addresses &&
      data.to_addresses.map(row => {
        if (row && row !== '') exists_to_addresses = true
        return row && !isValidEmailOrVariable(row, true) ? 'validate.invalidEmail' : ''
      })
    if (!exists_to_addresses) errors.to_addresses = ['validate.required']

    errors.cc_addresses =
      data.cc_addresses &&
      data.cc_addresses.map(row => {
        return row && !isValidEmailOrVariable(row, true) ? 'validate.invalidEmail' : ''
      })

    errors.bcc_addresses =
      data.bcc_addresses &&
      data.bcc_addresses.map(row => {
        return row && !isValidEmailOrVariable(row, true) ? 'validate.invalidEmail' : ''
      })

    errors.reply_to_address =
      data.reply_to_address && !isValidEmailOrVariable(data.reply_to_address) ? 'validate.invalidEmail' : ''

    if (data.subject && lengthWithoutVariable(data.subject) > 250) {
      errors.subject = { id: 'validate.exceededMaxLength', values: { length: 250 } }
    }
  }

  // LINE Switcher API
  if (data.type === 'line_switcher') {
    if (!data.destination_id) {
      errors.destination_id = 'validate.required'
    } else if (!isUUID(data.destination_id)) {
      errors.destination_id = 'validate.invalid'
    }
    if (data.as_json_note) {
      if (data.note && !data.note.match(/\{\{.+\}\}/)) {
        try {
          JSON.parse(data.note)
        } catch (e) {
          errors.note = 'validate.invalid'
        }
      }
    }
  }

  // Office 365
  if (data.type === 'office365') {
    if (data.api_subtype === 'get_user') {
      if (data.target_type === 'user') {
        if (!data.user_id) {
          errors.user_id = 'validate.required'
        }
      }
    }

    if (data.api_subtype === 'get_users') {
      errors.filter_items =
        data.filter_items &&
        data.filter_items.map(row => {
          const error = {}
          if (row.key || row.condition || row.value) {
            if (!row.key) error.key = 'validate.required'
            if (!row.condition) error.condition = 'validate.required'
            if (!row.value) error.value = 'validate.required'
          }
          return error
        })
    }

    if (data.api_subtype === 'get_groups') {
      if (data.target_type === 'user') {
        if (!data.user_id) {
          errors.user_id = 'validate.required'
        }
      }
    }

    if (data.api_subtype === 'get_members') {
      if (!data.group_id) {
        errors.group_id = 'validate.required'
      }
    }

    if (data.api_subtype === 'post_event') {
      if (!data.title) {
        errors.title = 'validate.required'
      }
      if (!data.start_time) {
        errors.start_time = 'validate.required'
      }
      if (!data.end_time) {
        errors.end_time = 'validate.required'
      }
    }
    if (data.api_subtype === 'get_event') {
      if (!data.event_id) {
        errors.event_id = 'validate.required'
      }
      if (data.target_type === 'user') {
        if (!data.user_id) {
          errors.user_id = 'validate.required'
        }
      }
    }
    if (data.api_subtype === 'get_events') {
      if (!data.start_time) {
        errors.start_time = 'validate.required'
      }
      if (!data.end_time) {
        errors.end_time = 'validate.required'
      }
      if (!data.search_type) {
        errors.search_type = 'validate.required'
      }
      if (data.target_type === 'user') {
        if (!data.user_id) {
          errors.user_id = 'validate.required'
        }
      }
    }
    if (data.api_subtype === 'update_event') {
      if (!data.event_id) {
        errors.event_id = 'validate.required'
      }
      errors.update_parameters = data.update_parameters.map(row => {
        const error = {}
        if (!row.id && row.value) error.id = 'validate.required'
        return error
      })
      if (
        lodash.uniqWith(data.update_parameters, (a, b) => a.id === b.id).length !==
        data.update_parameters.length
      ) {
        errors.update_parameters._error = 'validate.itemNameDuplicated'
      }
    }
    if (data.api_subtype === 'delete_event') {
      if (!data.event_id) {
        errors.event_id = 'validate.required'
      }
    }
    if (data.api_subtype === 'search_free_room') {
      if (!data.start_time) {
        errors.start_time = 'validate.required'
      }
      if (!data.end_time) {
        errors.end_time = 'validate.required'
      }
      if (!data.conference_time) {
        errors.conference_time = 'validate.required'
      }
      if (!data.activity_domain) {
        errors.activity_domain = 'validate.required'
      }
    }
    if (data.api_subtype === 'custom') {
      if (!data.oauth_scope) {
        errors.oauth_scope = 'validate.required'
      }
      if (!data.url) {
        errors.url = 'validate.required'
      } else if (data.url.length > 255) {
        errors.url = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      } else if (!isContainVariable(data.url)) {
        const result = url.parse(data.url)
        if (!result.protocol || !result.slashes || !result.host) {
          errors.url = 'validate.invalidURL'
        }
      }
      errors.headers =
        data.headers &&
        data.headers.map(row => {
          const error = {}
          if (row.key && !row.value) error.value = 'validate.required'
          if (row.value && !row.key) error.key = 'validate.required'
          if (row.key && !validHeaderName(row.key)) error.key = 'validate.invalid'
          if (row.value && !validHeaderValue(row.value.replace(/\{\{(.+)\}\}/, '')))
            error.value = 'validate.invalid'
          return error
        })
    }
    errors.response_map_get_user =
      data.response_map_get_user &&
      data.response_map_get_user.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_users =
      data.response_map_get_users &&
      data.response_map_get_users.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_groups =
      data.response_map_get_groups &&
      data.response_map_get_groups.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_members =
      data.response_map_get_members &&
      data.response_map_get_members.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_event =
      data.response_map_get_event &&
      data.response_map_get_event.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_events =
      data.response_map_get_events &&
      data.response_map_get_events.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_post_event =
      data.response_map_post_event &&
      data.response_map_post_event.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_update_event =
      data.response_map_update_event &&
      data.response_map_update_event.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_search_free_room =
      data.response_map_search_free_room &&
      data.response_map_search_free_room.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_get_access_token =
      data.response_map_get_access_token &&
      data.response_map_get_access_token.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
    errors.response_map_custom =
      data.response_map_custom &&
      data.response_map_custom.map(row => {
        const error = {}
        if (row.to && !row.from) error.from = 'validate.required'
        if (row.from && !row.to) error.to = 'validate.required'
        if (row.to && row.to.length > 30) {
          error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        }
        return error
      })
  }

  // Excel
  if (data.type === 'excel') {
    if (!data.tenant_id) {
      errors.tenant_id = 'validate.required'
    } else if (data.tenant_id.length > 255) {
      errors.tenant_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
    if (!data.client_id) {
      errors.client_id = 'validate.required'
    } else if (data.client_id.length > 36) {
      errors.client_id = { id: 'validate.exceededMaxLength', values: { length: 36 } }
    } else if (!isUUID(data.client_id)) {
      errors.client_id = 'validate.invalid'
    }
    if (!data.id && !data.client_secret) {
      errors.client_secret = 'validate.required'
    }
    if (data.client_secret) {
      if (data.client_secret.length > 255) {
        errors.client_secret = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      } else if (!/^[0-9a-zA-Z!-/:-@¥[-`{-~]*$/.test(data.client_secret)) {
        errors.client_secret = 'validate.invalid'
      }
    }
    if (!data.drive_id) {
      errors.drive_id = 'validate.required'
    } else if (data.drive_id.length > 255) {
      errors.drive_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
    if (!data.file_name) {
      errors.file_name = 'validate.required'
    } else if (data.file_name.length > 255) {
      errors.file_name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
    if (data.sheet_name) {
      if (data.sheet_name.length > 32) {
        errors.sheet_name = { id: 'validate.exceededMaxLength', values: { length: 32 } }
      }
    }
    if (data.id) {
      errors.request_parameters =
        data.request_parameters &&
        data.request_parameters.map(row => {
          const error = {}
          if (!row.id || !lodash.includes(data.header_columns, row.id)) {
            error.id = 'validate.required'
          }
          if (!row.rule && (row.id || row.value)) {
            error.rule = 'validate.required'
          }
          return error
        })
      errors.update_parameters =
        data.update_parameters &&
        data.update_parameters.map(row => {
          const error = {}
          if (!row.id) error.id = 'validate.required'
          return error
        })
      if (data.request_columns) {
        let valid_columns = lodash.compact(
          data.request_columns.map(request_column => {
            return lodash.includes(data.header_columns, request_column) ? request_column : null
          })
        )
        if (!valid_columns || valid_columns.length < 1) {
          errors.request_columns = 'validate.multipleSelectListRequired'
        }
      }
    }
    if (data.excel_api_subtype !== 'insert') {
      if (!data.variable_name) {
        errors.variable_name = 'validate.required'
      } else if (data.variable_name.length > 255) {
        errors.variable_name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
    }
  }

  // Salesforce
  if (data.type === 'salesforce') {
    if (data.salesforce_api_subtype === 'custom') {
      if (!data.url) {
        errors.url = 'validate.required'
      } else if (data.url.length > 255) {
        errors.url = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      } else if (!isContainVariable(data.url)) {
        const result = url.parse(data.url)
        if (!result.protocol || !result.slashes || !result.host) {
          errors.url = 'validate.invalidURL'
        }
      }
      errors.headers =
        data.headers &&
        data.headers.map(row => {
          const error = {}
          if (row.key && !row.value) error.value = 'validate.required'
          if (row.value && !row.key) error.key = 'validate.required'
          if (row.key && !validHeaderName(row.key)) error.key = 'validate.invalid'
          if (row.value && !validHeaderValue(row.value.replace(/\{\{(.+)\}\}/, '')))
            error.value = 'validate.invalid'
          return error
        })

      errors.response_map =
        data.response_map &&
        data.response_map.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    } else if (data.salesforce_api_subtype === 'search') {
      if (!data.object_name) {
        errors.object_name = 'validate.required'
      }

      if (!data.salesforce_columns || data.salesforce_columns.length < 1) {
        errors.salesforce_columns = 'validate.multipleSelectListRequired'
      } else if (data.use_distinct && data.salesforce_columns.length > 32) {
        errors.salesforce_columns = { id: 'validate.exceededMaxSelected', values: { maximum: 32 } }
      }

      errors.salesforce_conditions =
        data.salesforce_conditions &&
        data.salesforce_conditions.map(row => {
          const error = {}
          if (row.value && !row.id) error.id = 'validate.required'
          if (row.rule && row.rule === '⊃' && !row.value) error.value = 'validate.required'
          if (row.id && !row.rule) error.rule = 'validate.required'
          return error
        })

      errors.response_map =
        data.response_map &&
        data.response_map.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    } else if (data.salesforce_api_subtype === 'insert') {
      if (!data.object_name) {
        errors.object_name = 'validate.required'
      }

      errors.insert_parameters =
        data.insert_parameters &&
        data.insert_parameters.map(row => {
          const error = {}
          if (row.value && !row.id) error.id = 'validate.required'
          if (row.id && !row.value) error.value = 'validate.required'
          return error
        })

      errors.response_map_salesforce_insert =
        data.response_map_salesforce_insert &&
        data.response_map_salesforce_insert.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    } else if (data.salesforce_api_subtype === 'keyword') {
      if (!data.object_name) {
        errors.object_name = 'validate.required'
      }
      if (!data.search_word) {
        errors.search_word = 'validate.required'
      } else if (data.search_word.length > 255) {
        errors.search_word = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      } else if (data.search_word.length < 2) {
        errors.search_word = { id: 'validate.insufficientMinLength', values: { length: 2 } }
      }
      if (!data.salesforce_columns || data.salesforce_columns.length < 1) {
        errors.salesforce_columns = 'validate.multipleSelectListRequired'
      }

      errors.response_map =
        data.response_map &&
        data.response_map.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    } else if (data.salesforce_api_subtype === 'picklist') {
      if (!data.object_name) {
        errors.object_name = 'validate.required'
      }
      if (!data.picklist_column) {
        errors.picklist_column = 'validate.required'
      }

      errors.response_map =
        data.response_map &&
        data.response_map.map(row => {
          const error = {}
          if (row.to && !row.from) error.from = 'validate.required'
          if (row.from && !row.to) error.to = 'validate.required'
          if (row.to && row.to.length > 30) {
            error.to = { id: 'validate.exceededMaxLength', values: { length: 30 } }
          }
          return error
        })
    }
  }

  // OKBIZ. for FAQ
  if (data.type === 'okbiz') {
    if (!isValidLengthForNormalText(data.question)) {
      errors.question = { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (!data.website_url) {
      errors.website_url = 'validate.required'
    } else if (data.website_url.length > 255) {
      errors.website_url = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    } else {
      const result = url.parse(data.website_url)
      if (!lodash.includes(['http:', 'https:'], result.protocol) || !result.slashes || !result.host) {
        errors.website_url = 'validate.invalidURL'
      }
    }
    if (!data.client_id) {
      errors.client_id = 'validate.required'
    } else if (data.client_id !== data.client_id.trim()) {
      errors.client_id = 'validate.onlyAlphanumeric'
    }
    if (data.client_secret && data.client_secret !== data.client_secret.trim()) {
      errors.client_secret = 'validate.onlyAlphanumeric'
    }
  }
  return errors
}

export const convertSystemVariablesOfIntegration = (integration, isSave) => {
  if (integration.type === 'http') {
    ;['url', 'request_body'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(integration, replaceKey, isSave, true)
    })
    if (integration.headers) {
      integration.headers.forEach((item, index) => {
        replaceSystemVariableWhenOnChange(integration.headers[index], 'value', isSave, true)
      })
    }
  }
  if (integration.type === 'google_spreadsheet') {
    if (integration.sheet_values) {
      integration.sheet_values.forEach((sheet_value, index) => {
        replaceSystemVariableWhenOnChange(integration.sheet_values, index, isSave, true)
      })
    }
  }
  if (integration.type === 'email') {
    ;['subject', 'body', 'reply_to_address'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(integration, replaceKey, isSave, true)
    })
    if (integration.to_addresses) {
      integration.to_addresses.forEach((item, index) => {
        replaceSystemVariableWhenOnChange(integration.to_addresses, index, isSave, true)
      })
    }
    if (integration.cc_addresses) {
      integration.cc_addresses.forEach((item, index) => {
        replaceSystemVariableWhenOnChange(integration.cc_addresses, index, isSave, true)
      })
    }
    if (integration.bcc_addresses) {
      integration.bcc_addresses.forEach((item, index) => {
        replaceSystemVariableWhenOnChange(integration.bcc_addresses, index, isSave, true)
      })
    }
  }
  if (integration.type === 'line_switcher') {
    replaceSystemVariableWhenOnChange(integration, 'note', isSave, true)
  }
  if (integration.type === 'office365') {
    let target_items = []
    if (integration.api_subtype === 'get_user') {
      if (integration.target_type === 'user') {
        target_items = ['user_id']
      }
    } else if (integration.api_subtype === 'get_users') {
      target_items = ['search_word']
      const filter_items = integration.filter_items || []
      filter_items.forEach((value, index) => {
        const change_value = getReplaceSystemVariableValue(value.value, isSave, true)
        if (change_value !== value.value) {
          integration.filter_items[index].value = change_value
        }
      })
    } else if (integration.api_subtype === 'get_groups') {
      if (integration.target_type === 'user') {
        target_items = ['user_id']
      }
    } else if (integration.api_subtype === 'get_members') {
      target_items = ['group_id']
    } else if (integration.api_subtype === 'get_event') {
      if (integration.target_type === 'user') {
        target_items = ['user_id', 'event_id']
      } else {
        target_items = ['event_id']
      }
    } else if (integration.api_subtype === 'get_events') {
      if (integration.target_type === 'user') {
        target_items = ['user_id', 'start_time', 'end_time']
      } else {
        target_items = ['start_time', 'end_time']
      }
    } else if (integration.api_subtype === 'post_event') {
      target_items = ['title', 'start_time', 'end_time', 'room_id', 'attendees']
    } else if (integration.api_subtype === 'update_event') {
      target_items = ['event_id']
      if (integration.update_parameters) {
        integration.update_parameters.forEach((item, index) => {
          replaceSystemVariableWhenOnChange(integration.update_parameters[index], 'value', isSave, true)
        })
      }
    } else if (integration.api_subtype === 'delete_event') {
      target_items = ['event_id']
    } else if (integration.api_subtype === 'search_free_room') {
      target_items = ['start_time', 'end_time', 'conference_time', 'room_id', 'attendees']
    } else if (integration.api_subtype === 'custom') {
      target_items = ['url', 'request_body']
      if (integration.headers) {
        integration.headers.forEach((item, index) => {
          replaceSystemVariableWhenOnChange(integration.headers[index], 'value', isSave, true)
        })
      }
    }
    target_items.forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(integration, replaceKey, isSave, true)
    })
  }
  if (integration.type === 'excel') {
    if (integration.request_parameters) {
      integration.request_parameters.forEach((request_parameters, index) => {
        replaceSystemVariableWhenOnChange(integration.request_parameters[index], 'value', isSave, true)
      })
    }
    if (integration.insert_parameters) {
      integration.insert_parameters.forEach((insert_parameters, index) => {
        replaceSystemVariableWhenOnChange(integration.insert_parameters[index], 'value', isSave, true)
      })
    }
    if (integration.update_parameters) {
      integration.update_parameters.forEach((update_parameters, index) => {
        replaceSystemVariableWhenOnChange(integration.update_parameters[index], 'value', isSave, true)
      })
    }
  }
  if (integration.type === 'salesforce') {
    let target_items = []
    if (integration.api_subtype === 'custom' || integration.salesforce_api_subtype === 'custom') {
      target_items = ['url', 'request_body']
      if (integration.headers) {
        integration.headers.forEach((item, index) => {
          replaceSystemVariableWhenOnChange(integration.headers[index], 'value', isSave, true)
        })
      }
    } else if (integration.api_subtype === 'insert' || integration.salesforce_api_subtype === 'insert') {
      if (integration.insert_parameters) {
        integration.insert_parameters.forEach((insert_parameters, index) => {
          replaceSystemVariableWhenOnChange(integration.insert_parameters[index], 'value', isSave, true)
        })
      }
    } else if (integration.api_subtype === 'keyword' || integration.salesforce_api_subtype === 'keyword') {
      target_items = ['search_word']
    } else if (integration.api_subtype === 'search' || integration.salesforce_api_subtype === 'search') {
      if (integration.request_parameters) {
        integration.request_parameters.forEach((item, index) => {
          replaceSystemVariableWhenOnChange(integration.request_parameters[index], 'value', isSave, true)
        })
      }
      if (integration.salesforce_conditions) {
        integration.salesforce_conditions.forEach((item, index) => {
          replaceSystemVariableWhenOnChange(integration.salesforce_conditions[index], 'value', isSave, true)
        })
      }
    }
    target_items.forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(integration, replaceKey, isSave, true)
    })
  }
  if (integration.type === 'okbiz') {
    ;['question'].forEach(replaceKey => {
      replaceSystemVariableWhenOnChange(integration, replaceKey, isSave, true)
    })
  }
}

export class IntegrationEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
    request_body: PropTypes.string,
    isFetching: PropTypes.bool,
    isExecuting: PropTypes.bool,
    isQuery: PropTypes.bool,
    results: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    errorMessage: PropTypes.string,
    oauthErrorMessage: PropTypes.string,
    headers: PropTypes.array,
    search_word: PropTypes.string,
    filter_items: PropTypes.array,
    response_map: PropTypes.array,
    result_variable: PropTypes.string,
    sheet_values: PropTypes.array,
    subject: PropTypes.string,
    body: PropTypes.string,
    type: PropTypes.string,
    emailAddresses: PropTypes.array,
    api_subtype: PropTypes.string,
    salesforce_api_subtype: PropTypes.string,
    excel_api_subtype: PropTypes.string,
    target_type: PropTypes.string,
    user_id: PropTypes.string,
    group_id: PropTypes.string,
    event_id: PropTypes.string,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    title: PropTypes.string,
    room_id: PropTypes.string,
    attendees: PropTypes.string,
    conference_time: PropTypes.string,
    drive_id: PropTypes.string,
    file_name: PropTypes.string,
    sheet_name: PropTypes.string,
    header_columns: PropTypes.array,
    use_distinct: PropTypes.bool,
    request_columns: PropTypes.array,
    applications: PropTypes.array.isRequired,
    salesforceObjects: PropTypes.array,
    picklist_column: PropTypes.string,
    // okbiz
    use_site_id: PropTypes.bool,
    use_test_environment: PropTypes.bool,
  }

  constructor() {
    super()
    this.state = { isOpened: false, oauthStatus: 'initialized', oauthStatusMessage: '', aiAssistantTools: {} }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props
    if (this.props.params.id) {
      dispatch(fetchIntegration(state.session.token, this.props.params.id)).then(integration_response => {
        dispatch(fetchBot(state.session.token, this.props.params.bot_id)).then(bot_response => {
          const bot = bot_response.entities.bots[this.props.params.bot_id] || {}
          if (isPermitted('feature_oauth', this.context)) {
            dispatch(fetchOAuthClients(state.session.token)).then(oauth_response => {
              const { type, api_subtype } = integration_response.entities.integrations[
                integration_response.result
              ]
              if (type === 'salesforce') {
                const oauthClient =
                  oauth_response.entities.oauth_clients[bot.oauth_client_id_salesforce] || {}
                this.callSalesforceObject(oauthClient, api_subtype)
              }
            })
          }
        })
      })
    } else {
      dispatch(fetchBot(state.session.token, this.props.params.bot_id))
      if (isPermitted('feature_oauth', this.context)) {
        dispatch(fetchOAuthClients(state.session.token))
      }
    }
    if (isPermitted('integration_email', this.context)) {
      dispatch(fetchEmailAddresses(state.session.token, { is_confirmed: true }))
    }
    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.params.bot_id }))

    const toolDirectory = new ToolDirectory(Config.toolDirectoryServer)
    toolDirectory.fetchToolIndex().then(tools => {
      this.setState({ aiAssistantTools: tools })
    })

    cancelUrl.setRouteLeaveHook(this)

    //  Refresh OAuthClient when finish authentication in another window
    window.addEventListener('message', this.onFinishOAuthFlow)
  }

  componentWillUnmount = () => {
    window.removeEventListener('message', this.onFinishOAuthFlow)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      this.fetchToolFromType(this.props.type)
    }
  }

  fetchToolFromType = type => {
    if (!type.startsWith('tool:')) return

    //  Use cached tool details
    const [_, id] = type.split(':')
    if (this.state.aiAssistantTools[id]?.fetched) return

    const toolDirectory = new ToolDirectory(Config.toolDirectoryServer)
    toolDirectory.fetchTool(id, i18next.language).then(tool => {
      this.setState({ aiAssistantTools: { ...this.state.aiAssistantTools, [id]: tool } })
    })
  }

  handleSave = (isTest, data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const router = this.context.router

    const integration = {
      name: data.name,
      description: data.description || null,
      type: data.type,
      bot_id: this.props.params.bot_id,
    }

    if (integration.type === 'http') {
      integration.primary = data.primary
      integration.url = data.url
      integration.method = data.method
      integration.request_body = data.request_body || null
      integration.response_type = data.response_type
      if (integration.response_type === 'json') {
        integration.response_map = lodash.filter(data.response_map, row => row.from && row.to)
      } else if (integration.response_type === 'text') {
        integration.response_body_variable = data.response_body_variable || null
      }
      integration.headers = lodash.reject(data.headers, lodash.isEmpty)
      if (isPermitted('feature_http_integration_status_code_handing', this.context)) {
        integration.use_status_code_handling = data.use_status_code_handling
        if (integration.use_status_code_handling) {
          integration.result_variable = data.result_variable || null
          integration.status_code_map = lodash
            .chain(data.user_status_code_map)
            .filter(row => row.status_code && row.result)
            .map(row => ({ ...row, type: 'user_setting' }))
            .concat({ ...data.error_status_code_map, type: 'system_error' })
            .value()
        }
      }
    }

    if (integration.type === 'google_spreadsheet') {
      integration.service_account_key = data.service_account_key
      integration.sheet_id = data.sheet_id
      integration.sheet_values = data.sheet_values
    }

    if (integration.type === 'email') {
      integration.reply_to_address = data.reply_to_address
      integration.to_addresses = data.to_addresses
      integration.cc_addresses = data.cc_addresses
      integration.bcc_addresses = data.bcc_addresses
      integration.subject = data.subject || null
      integration.body = data.body || null
    }

    if (integration.type === 'line_switcher') {
      integration.sub_type = data.sub_type || null
      integration.destination_id = data.destination_id || null
      integration.as_json_note = data.as_json_note || false
      integration.note = data.note || null
    }

    if (integration.type === 'office365') {
      integration.api_subtype = data.api_subtype

      integration.title = data.title || null
      integration.start_time = data.start_time || null
      integration.end_time = data.end_time || null
      integration.conference_time = data.conference_time || null
      integration.activity_domain = data.activity_domain || null
      integration.target_type = data.target_type || null
      integration.user_id = data.user_id || null
      integration.group_id = data.group_id || null
      integration.event_id = data.event_id || null
      integration.room_id = data.room_id || null
      integration.attendees = data.attendees || null

      if (data.api_subtype === 'get_user') {
        integration.response_map = data.response_map_get_user
      } else if (data.api_subtype === 'get_users') {
        integration.response_map = data.response_map_get_users
        integration.search_word = data.search_word || null
        integration.search_user_type = data.search_user_type || null
        integration.filter_items = lodash.filter(
          data.filter_items,
          row => row.key && row.condition && row.value
        )
      } else if (data.api_subtype === 'get_groups') {
        integration.response_map = data.response_map_get_groups
      } else if (data.api_subtype === 'get_members') {
        integration.response_map = data.response_map_get_members
      } else if (data.api_subtype === 'get_event') {
        integration.response_map = data.response_map_get_event
      } else if (data.api_subtype === 'get_events') {
        integration.response_map = data.response_map_get_events
        if (data.organizer_room === 'conference_room') {
          integration.is_organizer = true
          integration.is_conference_room = true
        } else if (data.organizer_room === 'organizer') {
          integration.is_organizer = true
          integration.is_conference_room = false
        } else {
          integration.is_organizer = false
          integration.is_conference_room = false
        }
      } else if (data.api_subtype === 'post_event') {
        integration.response_map = data.response_map_post_event
      } else if (data.api_subtype === 'update_event') {
        integration.response_map = data.response_map_update_event
        integration.update_parameters = data.update_parameters
      } else if (data.api_subtype === 'search_free_room') {
        integration.response_map = data.response_map_search_free_room
      } else if (data.api_subtype === 'get_access_token') {
        integration.response_map = data.response_map_get_access_token
      } else if (data.api_subtype === 'custom') {
        integration.oauth_scope = data.oauth_scope || ''
        integration.url = data.url || null
        integration.method = data.method || null
        integration.request_body = data.request_body || null
        integration.response_type = data.response_type || null
        integration.headers = data.headers
        integration.response_map = lodash.compact(data.response_map_custom)
      }
      integration.response_map = lodash.filter(integration.response_map, row => row.from && row.to)
    }

    if (integration.type === 'excel') {
      integration.excel_api_subtype = data.excel_api_subtype
      integration.tenant_id = data.tenant_id
      integration.client_id = data.client_id
      integration.client_secret = data.client_secret
      integration.drive_id = data.drive_id
      integration.file_name = data.file_name
      integration.sheet_name = data.sheet_name || null
      integration.variable_name = data.variable_name || null
      integration.request_parameters = lodash.compact(
        data.request_parameters.map(parameter => {
          if (!parameter.id || !parameter.id.trim()) return null
          return { ...parameter, value: parameter.value || '' }
        })
      )
      integration.insert_parameters = data.insert_parameters
      integration.update_parameters = data.update_parameters
      integration.use_or_operator = data.use_or_operator === 'true' ? true : false
      integration.use_distinct = data.use_distinct

      if (data.request_columns) {
        let valid_columns = lodash.compact(
          data.request_columns.map(request_column => {
            return lodash.includes(data.header_columns, request_column) ? request_column : null
          })
        )
        integration.request_columns = valid_columns || []
      }
    }

    if (integration.type === 'salesforce') {
      integration.api_subtype = data.salesforce_api_subtype

      if (data.salesforce_api_subtype === 'custom') {
        integration.url = data.url || null
        integration.method = data.method || null
        integration.headers = data.headers
        integration.request_body = data.request_body || null
        integration.response_type = data.response_type || null
        integration.response_map = lodash.compact(data.response_map)
        integration.response_map = lodash.filter(integration.response_map, row => row.from && row.to)
      }

      if (data.salesforce_api_subtype === 'search') {
        integration.use_or_operator = data.use_or_operator === 'true' ? true : false
        integration.use_distinct = data.use_distinct || false
        integration.object_name = data.object_name || null
        integration.request_columns = data.salesforce_columns || []
        integration.request_parameters = lodash.compact(
          data.salesforce_conditions.map(parameter => {
            if (!parameter.id || !parameter.id.trim()) return null
            return { ...parameter, value: parameter.value || '' }
          })
        )

        integration.request_orders = lodash.compact(
          data.salesforce_orders.map(parameter => {
            if (!parameter.variable) return null
            if (data.use_distinct && !lodash.includes(integration.request_columns, parameter.variable))
              return null
            return { ...parameter, order: parameter.order || 'asc' }
          })
        )
        integration.response_map = lodash.compact(data.response_map)
        integration.response_map = lodash.filter(integration.response_map, row => row.from && row.to)
      }

      if (data.salesforce_api_subtype === 'keyword') {
        integration.search_group = data.search_group || 'all'
        integration.search_word = data.search_word

        integration.object_name = data.object_name || null
        integration.request_columns = data.salesforce_columns || []
        integration.response_map = lodash.compact(data.response_map)
        integration.response_map = lodash.filter(integration.response_map, row => row.from && row.to)
      }

      if (data.salesforce_api_subtype === 'insert') {
        integration.object_name = data.object_name || null
        integration.response_map = data.response_map_salesforce_insert
        integration.insert_parameters = lodash.filter(data.insert_parameters, row => row.id && row.value)
      }

      if (data.salesforce_api_subtype === 'picklist') {
        integration.object_name = data.object_name || null
        integration.picklist_column = data.picklist_column || null
        integration.response_map = lodash.compact(data.response_map)
        integration.response_map = lodash.filter(integration.response_map, row => row.from && row.to)
      }
    }
    if (integration.type === 'okbiz') {
      integration.question = data.question || null
      integration.website_url = data.website_url || null
      integration.sort_type = data.sort_type || null
      integration.client_id = data.client_id || null
      integration.client_secret = data.client_secret || undefined
      integration.use_test_environment = data.use_test_environment || false
      if (data.use_site_id) {
        integration.site_id = data.site_id || null
      } else {
        integration.site_id = null
      }
      if (data.use_test_environment) {
        integration.basic_auth_user = data.basic_auth_user || null
        integration.basic_auth_password = data.basic_auth_password || undefined
      } else {
        integration.basic_auth_user = null
        integration.basic_auth_password = null
      }
    }

    if (data.type.startsWith('tool:')) {
      const [type, subType] = data.type.split(':')
      integration.type = type
      integration.sub_type = subType
      integration.parameters = data.parameters
    }

    convertSystemVariablesOfIntegration(integration, true)
    const handleOpen = isTest ? this.handleOpen : () => {}
    if (this.props.params.id) {
      integration.id = this.props.params.id
      return dispatch(updateIntegration(state.session.token, integration))
        .then(handleOpen)
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() =>
          checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
        )
    } else {
      return dispatch(createIntegration(state.session.token, integration))
        .then(json => {
          router.push({
            pathname: `/bots/${this.props.params.bot_id}/integrations/${json.result}`,
            state: { ignoreBlocking: true },
          })
        })
        .then(handleOpen)
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
        .then(() =>
          checkApplications(this.props.params.bot_id, this.props.applications, dispatch, this.context)
        )
    }
  }

  handleDelete = () => {
    const state = this.context.store.getState()
    const { t, router } = this.context
    const {
      dispatch,
      params: { id, bot_id },
    } = this.props
    const integration = this.props.initialValues
    if (
      !window.confirm(
        t('common.deleteConfirmMessage', { type: t('integration.name'), name: integration.name })
      )
    )
      return

    dispatch(deleteIntegration(state.session.token, id))
      .then(() => router.push({ pathname: `/bots/${bot_id}/integrations`, state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
  }

  handleExecute = data => {
    const state = this.context.store.getState()
    const { type, dispatch, office365OAuthClient, salesforceOAuthClient } = this.props

    const body = {
      slots: {},
    }

    if (type === 'office365') {
      body.oauth_client_id = office365OAuthClient.id
    } else if (type === 'salesforce') {
      body.oauth_client_id = salesforceOAuthClient.id
    }

    const slots = data.slots || []
    slots.forEach(slot => {
      const key = getReplaceSystemVariableValue(slot.key, true, false)
      lodash.set(body.slots, key, slot.value)
    })

    const id = parseInt(this.props.params.id, 10)
    dispatch(executeIntegration(state.session.token, id, body))
  }

  handleOpen = () => {
    const state = this.context.store.getState()
    const { t } = this.context
    const {
      type,
      params,
      dispatch,
      api_subtype,
      excel_api_subtype,
      salesforce_api_subtype,
      office365OAuthClient,
      salesforceOAuthClient,
    } = this.props

    let queries = this.getSlots(type, api_subtype, excel_api_subtype, salesforce_api_subtype)

    if (type === 'office365') {
      if (lodash.isEmpty(office365OAuthClient)) {
        return Promise.resolve()
          .then(() => dispatch(setErrorOnIntegration(t('integration.office365.failedClientIdMessage'))))
          .then(() => this.setState({ isOpened: true }))
      } else if (office365OAuthClient.use_preauthentication && !office365OAuthClient.is_verified) {
        return Promise.resolve()
          .then(() =>
            dispatch(setErrorOnIntegration(t('integration.office365.notPreauthenticatedYetMessage')))
          )
          .then(() => this.setState({ isOpened: true }))
      }
    } else if (type === 'salesforce') {
      if (lodash.isEmpty(salesforceOAuthClient)) {
        return Promise.resolve()
          .then(() => dispatch(setErrorOnIntegration(t('integration.salesforce.failedClientIdMessage'))))
          .then(() => this.setState({ isOpened: true }))
      } else if (salesforceOAuthClient.use_preauthentication && !salesforceOAuthClient.is_verified) {
        return Promise.resolve()
          .then(() =>
            dispatch(setErrorOnIntegration(t('integration.salesforce.notPreauthenticatedYetMessage')))
          )
          .then(() => this.setState({ isOpened: true }))
      }
    }

    const id = parseInt(params.id, 10)
    if (
      queries.length > 0 ||
      (type === 'office365' && !office365OAuthClient.use_preauthentication) ||
      (type === 'salesforce' &&
        salesforce_api_subtype === 'custom' &&
        !salesforceOAuthClient.use_preauthentication)
    ) {
      dispatch(clearIntegrationResult(true))
    } else {
      dispatch(clearIntegrationResult(false))
      if (type === 'office365') {
        const oauth_body = { slots: {}, oauth_client_id: office365OAuthClient.id }
        dispatch(executeIntegration(state.session.token, id, oauth_body))
      } else if (type === 'salesforce') {
        const oauth_body = { slots: {}, oauth_client_id: salesforceOAuthClient.id }
        dispatch(executeIntegration(state.session.token, id, oauth_body))
      } else {
        const body = { slots: {} }
        dispatch(executeIntegration(state.session.token, id, body))
      }
    }
    this.setState({ isOpened: true })
  }

  handleClose = () => {
    this.setState({ isOpened: false })
  }

  handleOAuthRequest = () => {
    const state = this.context.store.getState()
    const { type, params, dispatch, office365OAuthClient, salesforceOAuthClient } = this.props

    let oauthClient = {}
    if (type === 'office365') {
      oauthClient = office365OAuthClient
    } else if (type === 'salesforce') {
      oauthClient = salesforceOAuthClient
    }
    if (lodash.isEmpty(oauthClient)) return
    if (oauthClient.use_preauthentication) return

    let oauth_request = {
      integration_id: parseInt(params.id, 10),
    }

    dispatch(createOAuthSession(state.session.token, oauthClient.id, oauth_request)).then(json => {
      const oauthWindow = window.open(json.url)
      this.setState({ oauthStatus: 'processing', oauthStatusMessage: '' })
      this.observeOAuthWindow(oauthWindow)
    })
  }

  observeOAuthWindow = oauthWindow => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, type } = this.props
    if (this.state.oauthStatus !== 'processing') return

    if (oauthWindow && oauthWindow.closed) {
      this.setState({ oauthStatus: 'canceled', oauthStatusMessage: t('oauth_callback.canceled') })
      if (type === 'salesforce') {
        dispatch(addNotice('error', t('oauth_callback.canceled')))
      }
      dispatch(fetchOAuthClients(state.session.token)).then(() => {
        dispatch(clearIntegrationResult(true))
      })
      return
    }

    setTimeout(() => {
      this.observeOAuthWindow(oauthWindow)
    }, 1000)
  }

  onFinishOAuthFlow = message => {
    const state = this.context.store.getState()
    const { bot, type, salesforce_api_subtype, dispatch } = this.props
    if (message.data.type === 'OAuthFlowFinished') {
      if (message.data.status === 'success') {
        this.setState({ oauthStatus: 'succeeded', oauthStatusMessage: message.data.message })
        dispatch(clearIntegrationResult(true))
        dispatch(fetchOAuthClients(state.session.token)).then(oauth_response => {
          if (type === 'salesforce') {
            const oauthClient = oauth_response.entities.oauth_clients[bot.oauth_client_id_salesforce] || {}
            this.callSalesforceObject(oauthClient, salesforce_api_subtype)
            dispatch(addNotice('info', message.data.message))
          }
        })
      } else {
        this.setState({ oauthStatus: 'failed', oauthStatusMessage: message.data.message })
        if (type === 'salesforce') {
          dispatch(addNotice('error', message.data.message))
        }
      }
    }
  }

  changePlaceholder = index => {
    const { t } = this.context
    const { update_parameters } = this.props
    let placeholder_string = ''
    if (update_parameters[index] && update_parameters[index].id) {
      const data_key = update_parameters[index].id
      placeholder_string = t(`integration.office365.placeholder.${data_key}`)
    }
    return placeholder_string
  }

  salesforceObjectType = index => {
    const { t } = this.context
    const { salesforceObjectDetails, salesforce_conditions } = this.props

    let placeholder_string = ''
    if (salesforce_conditions[index] && salesforce_conditions[index].id) {
      const variable_key = salesforce_conditions[index].id
      let item = lodash.filter(salesforceObjectDetails['where'], { name: variable_key })
      if (item.length > 0) {
        if (item[0].type === 'int') {
          placeholder_string = t('integration.salesforce.placeholder.int')
        } else if (item[0].type === 'double') {
          placeholder_string = t('integration.salesforce.placeholder.double')
        } else if (item[0].type === 'date') {
          placeholder_string = t('integration.salesforce.placeholder.date')
        } else if (item[0].type === 'datetime') {
          placeholder_string = t('integration.salesforce.placeholder.datetime')
        } else if (item[0].type === 'boolean') {
          placeholder_string = t('integration.salesforce.placeholder.boolean')
        }
      }
    }
    return placeholder_string
  }

  renderHeaders = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: t('integration.http.headerColumns.no'),
        HeaderClass: 'index',
        Cell: (name, index) => `${index + 1}`,
      },
      {
        Header: (
          <th key="name">
            {t('integration.http.headerColumns.name')}
            <Tooltip name="integration.tooltip.http.headerColumns.name" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.key`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.http.headerColumns.value')}
            <Tooltip name="integration.tooltip.http.headerColumns.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderResponseMap = ({ fields }) => {
    const { t } = this.context
    const { salesforce_api_subtype } = this.props
    let columns = []
    if (salesforce_api_subtype !== 'insert') {
      columns.push({
        Header: t('integration.http.responseMapColumns.no'),
        HeaderClass: 'index',
        Cell: (name, index) => `${index + 1}`,
      })
    }

    columns = columns.concat([
      {
        Header: (
          <th key="from">
            {t('integration.http.responseMapColumns.from')}
            <Tooltip name="integration.tooltip.http.responseMapColumns.from" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.from`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
      {
        Header: (
          <th key="to">
            {t('integration.http.responseMapColumns.to')}
            <Tooltip name="integration.tooltip.http.responseMapColumns.to" direction="left" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.to`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
          />
        ),
      },
    ])

    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderFilterItems = ({ fields }) => {
    const { t } = this.context
    const filteredItems = [
      { value: 'surname', display: t('integration.office365.filteredItems.surname') },
      { value: 'givenName', display: t('integration.office365.filteredItems.givenName') },
      { value: 'jobTitle', display: t('integration.office365.filteredItems.jobTitle') },
      { value: 'department', display: t('integration.office365.filteredItems.department') },
      { value: 'officeLocation', display: t('integration.office365.filteredItems.officeLocation') },
    ]
    const condition = [
      { value: 'eq', display: '=' },
      { value: 'ne', display: '<>' },
    ]
    const columns = [
      {
        Header: t('integration.office365.filteredItemHeaderColumns.no'),
        HeaderClass: 'index',
        Cell: (name, index) => `${index + 1}`,
      },
      {
        Header: (
          <th key="key">
            {t('integration.office365.filteredItemHeaderColumns.name')}
            <Tooltip name="integration.tooltip.office365.filteredItemHeaderColumns.name" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.key`}
            className="form-control dm-form-control"
            component={SelectField}
            items={filteredItems}
            valueKey="value"
            displayKey="display"
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="condition">
            {t('integration.office365.filteredItemHeaderColumns.condition')}
            <Tooltip name="integration.tooltip.office365.filteredItemHeaderColumns.condition" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.condition`}
            className="form-control dm-form-control"
            component={SelectField}
            items={condition}
            valueKey="value"
            displayKey="display"
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.office365.filteredItemHeaderColumns.value')}
            <Tooltip name="integration.tooltip.office365.filteredItemHeaderColumns.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
            maxLength="255"
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderResultConditions = ({ fields }) => {
    const { t } = this.context

    const results = [
      { id: 'success', name: t('integration.http.customizeResults.conditions.results.success') },
      { id: 'failed', name: t('integration.http.customizeResults.conditions.results.failed') },
    ]

    const columns = [
      {
        Header: t('integration.http.customizeResults.conditions.no'),
        HeaderClass: 'index',
        Cell: (name, index) => `${index + 1}`,
        Footer: index => `${index + 1}`,
      },
      {
        Header: (
          <th key="statusCode">
            {t('integration.http.customizeResults.conditions.statusCodes.title')}
            <Tooltip name="integration.tooltip.http.customizeResults.conditions.statusCodes.title" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.status_code`}
            className="form-control dm-form-control"
            maxLength="1000"
            component={InputField}
          />
        ),
        Footer: t('integration.http.customizeResults.conditions.statusCodes.other'),
      },
      {
        Header: (
          <th key="result">
            {t('integration.http.customizeResults.conditions.results.title')}
            <Tooltip name="integration.tooltip.http.customizeResults.conditions.results.title" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.result`}
            items={results}
            empty={true}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        ),
        Footer: t('integration.http.customizeResults.conditions.results.failed'),
      },
      {
        Header: (
          <th key="value">
            {t('integration.http.customizeResults.conditions.value')}
            <Tooltip name="integration.tooltip.http.customizeResults.conditions.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            maxLength="1000"
            component={InputField}
          />
        ),
        Footer: () => (
          <Field
            type="text"
            name={'error_status_code_map.value'}
            className="form-control dm-form-control"
            maxLength="1000"
            component={InputField}
          />
        ),
      },
    ]

    return <IncrementableTable fields={fields} columns={columns} withFooter={true} maxRows={10} />
  }

  renderGoogleSpreadsheetValues = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: (
          <th key="columnId">
            {t('integration.googleSpreadsheet.sheetValuesColumns.columnId')}
            <Tooltip name="integration.tooltip.googleSpreadsheet.sheetValuesColumns.columnId" />
          </th>
        ),
        Cell: (name, index) => this.convertIndexToColumnName(index),
      },
      {
        Header: (
          <th key="value">
            {t('integration.googleSpreadsheet.sheetValuesColumns.value')}
            <Tooltip name="integration.tooltip.googleSpreadsheet.sheetValuesColumns.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field type="text" name={name} className="form-control dm-form-control" component={InputField} />
        ),
      },
    ]
    // maxRows: A to Z + AA to ZZ columns
    return <IncrementableTable fields={fields} columns={columns} maxRows={702} />
  }

  convertIndexToColumnName = index => {
    if (typeof index === 'number') {
      if (index >= 0 && index < 26) {
        // A to Z
        return String.fromCharCode(65 + index)
      } else if (index >= 26 && index < 702) {
        // AA to ZZ
        return String.fromCharCode(65 + Math.floor(index / 26) - 1) + String.fromCharCode(65 + (index % 26))
      } else {
        return null
      }
    } else {
      return null
    }
  }

  renderRequestParameterMap = ({ fields }) => {
    const { t } = this.context
    const { header_columns } = this.props
    const condition = [
      { id: '', name: '' },
      { id: '=', name: t('common.conditions.equal') },
      { id: '<>', name: t('common.conditions.notEqual') },
      { id: '<', name: t('common.conditions.lessThan') },
      { id: '<=', name: t('common.conditions.lessOrEqual') },
      { id: '>', name: t('common.conditions.greaterThan') },
      { id: '>=', name: t('common.conditions.greaterOrEqual') },
      { id: 'include', name: t('common.conditions.include') },
      { id: 'not_include', name: t('common.conditions.notInclude') },
    ]

    const columns = [
      {
        Header: (
          <th key="id">
            {t('integration.excel.responseMapColumns.id')}
            <Tooltip name="integration.tooltip.excel.responseMapColumns.id" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.id`}
            className="form-control dm-form-control"
            component={SelectField}
            items={header_columns}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="rule">
            {t('integration.excel.responseMapColumns.rule')}
            <Tooltip name="integration.tooltip.excel.responseMapColumns.rule" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.rule`}
            className="form-control dm-form-control"
            component={SelectField}
            items={condition}
            valueKey="id"
            displayKey="name"
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.excel.responseMapColumns.value')}
            <Tooltip name="integration.tooltip.excel.responseMapColumns.value" direction="left" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderExcelInsertParameters = ({ fields }) => {
    const { t } = this.context
    let rows = []
    fields.forEach((row, index) =>
      rows.push(
        <tr key={index}>
          <td>
            <Field
              type="text"
              name={`${row}.id`}
              className="form-control dm-form-control"
              component={InputField}
              readOnly={true}
            />
          </td>
          <td>
            <Field
              type="text"
              name={`${row}.value`}
              className="form-control dm-form-control"
              component={InputField}
            />
          </td>
        </tr>
      )
    )
    let render_item = (
      <table className="table table-bordered dm-table">
        <thead>
          <tr>
            <th key="id">
              {t('integration.excel.responseMapColumns.insertId')}
              <Tooltip name="integration.tooltip.excel.responseMapColumns.insertId" />
            </th>
            <th key="value">
              {t('integration.excel.responseMapColumns.insertValue')}
              <Tooltip name="integration.tooltip.excel.responseMapColumns.insertValue" />
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
    return render_item
  }

  renderExcelUpdateParameters = ({ fields }) => {
    const { t } = this.context
    const { header_columns } = this.props
    const columns = [
      {
        Header: (
          <th key="id">
            {t('integration.excel.responseMapColumns.updateId')}
            <Tooltip name="integration.tooltip.excel.responseMapColumns.updateId" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.id`}
            className="form-control dm-form-control"
            component={SelectField}
            items={header_columns}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.excel.responseMapColumns.updateValue')}
            <Tooltip name="integration.tooltip.excel.responseMapColumns.updateValue" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderEmailList = ({ fields }) => {
    const { emailAddresses } = this.props
    const columns = [
      {
        Cell: (name, _index) => (
          <Field
            name={`${name}`}
            className="form-control dm-form-control"
            component={AutoCompleteField}
            items={emailAddresses}
            valueKey="email"
            displayKey="email"
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} maxRows={5} withHeader={false} />
  }

  renderEventUpdateParameters = ({ fields, meta: { error } }) => {
    const { t } = this.context
    const update_columns = [
      { id: 'title', name: i18next.t('integration.office365.title') },
      { id: 'start_time', name: i18next.t('integration.office365.start_time') },
      { id: 'end_time', name: i18next.t('integration.office365.end_time') },
      { id: 'room_id', name: i18next.t('integration.office365.room_id') },
      { id: 'attendees', name: i18next.t('integration.office365.attendees') },
    ]
    const columns = [
      {
        Header: (
          <th key="id">
            {t('integration.office365.updateItemHeaderColumns.id')}
            <Tooltip name="integration.tooltip.office365.updateItemHeaderColumns.id" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.id`}
            className="form-control dm-form-control"
            component={SelectField}
            valueKey="id"
            displayKey="name"
            items={update_columns}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.office365.updateItemHeaderColumns.value')}
            <Tooltip name="integration.tooltip.office365.updateItemHeaderColumns.value" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
            placeholder={this.changePlaceholder(_index)}
          />
        ),
      },
    ]

    const components = [<IncrementableTable key="table" fields={fields} columns={columns} />]
    if (error) {
      components.push(
        <div className="error" key="error">
          {t(error)}
        </div>
      )
    }

    return components
  }

  renderSalesforceRequestParameters = ({ fields }) => {
    const { t } = this.context
    const { salesforceObjectDetails } = this.props
    const condition = [
      { id: '', name: '' },
      { id: '=', name: t('common.conditions.equal') },
      { id: '<>', name: t('common.conditions.notEqual') },
      { id: '<', name: t('common.conditions.lessThan') },
      { id: '<=', name: t('common.conditions.lessOrEqual') },
      { id: '>', name: t('common.conditions.greaterThan') },
      { id: '>=', name: t('common.conditions.greaterOrEqual') },
      { id: '⊃', name: t('common.conditions.include') },
    ]

    const columns = [
      {
        Header: (
          <th key="id">
            {t('integration.salesforce.requestMapParameters.variable')}
            <Tooltip name="integration.tooltip.salesforce.requestMapParameters.variable" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.id`}
            items={salesforceObjectDetails['where']}
            valueKey="name"
            displayKey="label"
            className="form-control dm-form-control"
            component={SelectField}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="rule">
            {t('integration.salesforce.requestMapParameters.operator')}
            <Tooltip name="integration.tooltip.salesforce.requestMapParameters.operator" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.rule`}
            className="form-control dm-form-control"
            component={SelectField}
            items={condition}
            valueKey="id"
            displayKey="name"
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.salesforce.requestMapParameters.value')}
            <Tooltip name="integration.tooltip.salesforce.requestMapParameters.value" direction="left" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
            placeholder={this.salesforceObjectType(_index)}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderSalesforceRequestOrderParameters = ({ fields }) => {
    const { t } = this.context
    const { salesforceObjectDetails } = this.props
    const sort = [
      { id: 'asc', name: t('common.sort.asc') },
      { id: 'desc', name: t('common.sort.desc') },
    ]

    const columns = [
      {
        Header: (
          <th key="variable">
            {t('integration.salesforce.requestMapOrderParameters.variable')}
            <Tooltip name="integration.tooltip.salesforce.requestMapOrderParameters.variable" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.variable`}
            items={salesforceObjectDetails['order']}
            valueKey="name"
            displayKey="label"
            className="form-control dm-form-control"
            component={SelectField}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="order">
            {t('integration.salesforce.requestMapOrderParameters.order')}
            <Tooltip name="integration.tooltip.salesforce.requestMapOrderParameters.order" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.order`}
            className="form-control dm-form-control"
            component={SelectField}
            items={sort}
            valueKey="id"
            displayKey="name"
            empty={true}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderSalesforceInsertParameters = ({ fields }) => {
    const { t } = this.context
    const { salesforceObjectDetails } = this.props

    const columns = [
      {
        Header: (
          <th key="id">
            {t('integration.salesforce.requestMapParameters.variable')}
            <Tooltip name="integration.tooltip.salesforce.requestMapParameters.variable" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            name={`${name}.id`}
            items={salesforceObjectDetails['select']}
            valueKey="name"
            displayKey="label"
            className="form-control dm-form-control"
            component={SelectField}
            empty={true}
          />
        ),
      },
      {
        Header: (
          <th key="value">
            {t('integration.salesforce.requestMapParameters.value')}
            <Tooltip name="integration.tooltip.salesforce.requestMapParameters.value" direction="left" />
          </th>
        ),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.value`}
            className="form-control dm-form-control"
            component={InputField}
            placeholder={this.salesforceObjectType(_index)}
          />
        ),
      },
    ]
    return <IncrementableTable fields={fields} columns={columns} />
  }

  render() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { is_experimental } = getCredentials(this.context)
    const {
      type,
      isFetching,
      submitting,
      handleSubmit,
      pristine,
      api_subtype,
      salesforce_api_subtype,
      header_columns,
      bot,
    } = this.props

    const types = []
    if (isPermitted('integration_http', this.context)) {
      types.push({ id: 'http', name: t('common.integrationType.http') })
    }
    let deny_save = false

    const is_column_changed =
      type === 'excel' &&
      lodash.some(this.props.request_columns, request_column => {
        return !lodash.includes(header_columns, request_column) ? true : false
      })

    let isTestDisabled = false
    let titleOfTest
    if (type === 'line_switcher') {
      isTestDisabled = true
      titleOfTest = t('integration.test.titles.lineSwitcher')
    } else if (type === 'office365' && api_subtype === 'logout') {
      isTestDisabled = true
    } else if (type === 'salesforce' && salesforce_api_subtype === 'logout') {
      isTestDisabled = true
    } else if (type === 'excel' && !header_columns) {
      isTestDisabled = true
    }

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <form className="text-left" onSubmit={handleSubmit(this.handleSave.bind(this, false))}>
            <div className="form-group row dm-form-group">
              <LabelWithTooltip htmlFor="name" className="col-sm-2" name="integration.name" />
              <div className="col-sm-5">
                <Field
                  type="text"
                  name="name"
                  className="form-control dm-form-control"
                  maxLength="100"
                  component={InputField}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group">
              <LabelWithTooltip htmlFor="description" className="col-sm-2" name="integration.description" />
              <div className="col-sm-7">
                <Field
                  name="description"
                  minRows={4}
                  className="form-control dm-form-control"
                  component={TextAreaField}
                />
              </div>
            </div>
            <div className="form-group row dm-form-group">
              {!is_experimental ? (
                <LabelWithTooltip htmlFor="type" className="col-sm-2" name="integration.type" />
              ) : (
                <LabelWithTooltip htmlFor="type" className="col-sm-2" name="integration.type_experimental" />
              )}
              <div className="col-sm-5">
                <Field
                  name="type"
                  items={types}
                  valueKey="id"
                  displayKey="name"
                  disabledKey="disabled"
                  className="form-control dm-form-control"
                  disabled={isUpdate}
                  component={SelectField}
                  empty={true}
                />
                {type === 'office365' && (
                  <div className="dm-note">
                    <a
                      href="https://guide.dialogplay.jp/hc/ja/articles/360029199632"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('integration.office365.tutorial')}
                    </a>
                  </div>
                )}
                {type === 'excel' && <div className="dm-note">{t('integration.excel.note')}</div>}
                {type === 'excel' && (
                  <div className="dm-note">
                    <a
                      href="https://guide.dialogplay.jp/hc/ja/articles/360029517552"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('integration.excel.tutorial')}
                    </a>
                    <br />
                    <a href="/files/sample_template_onedrive.xlsx" download target="_new">
                      {t('integration.excel.template')}
                    </a>
                  </div>
                )}
              </div>
            </div>
            {type === 'http' && this.renderHttp()}
            {type === 'google_spreadsheet' && this.renderGoogleSpreadsheet()}
            {type === 'email' && this.renderEmail()}
            {type === 'office365' && this.renderOffice365()}
            {type === 'line_switcher' && this.renderLineSwitcher()}
            {type === 'excel' && this.renderExcel()}
            {type === 'salesforce' && this.renderSalesforce()}
            {type === 'okbiz' && this.renderOkbiz()}
            {type && type.startsWith('tool:') && this.renderTool()}
            <div className="form-group text-right">
              {type && !type.startsWith('tool:') && (
                <>
                  {isUpdate && pristine && !is_column_changed ? (
                    <button
                      type="button"
                      name="test"
                      className="btn btn-primary dm-btn"
                      onClick={handleSubmit(this.handleOpen)}
                      disabled={isTestDisabled || submitting || deny_save}
                      title={titleOfTest}
                    >
                      {t('integration.test.test')}
                    </button>
                  ) : (
                    <button
                      type="button"
                      name="save_and_test"
                      className="btn btn-primary dm-btn"
                      onClick={handleSubmit(this.handleSave.bind(this, true))}
                      disabled={isTestDisabled || submitting || deny_save}
                      title={titleOfTest}
                    >
                      {t('integration.test.saveAndTest')}
                    </button>
                  )}
                </>
              )}
              <button
                type="submit"
                name="save"
                value="submit"
                className="btn btn-primary dm-btn"
                onClick={handleSubmit(this.handleSave.bind(this, false))}
                disabled={submitting || deny_save}
              >
                {t('common.save')}
              </button>
              {isUpdate && (
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDelete}
                  disabled={submitting}
                >
                  {t('common.delete')}
                </button>
              )}
            </div>
          </form>
          {this.state.isOpened && this.renderExecuteForm()}
          <Sidebar titles={[t('simulator.title')]}>
            <Simulator tabs={['chat']} bot={bot} />
          </Sidebar>
        </Loader>
      </div>
    )
  }

  renderHttp() {
    const { response_type, response_map, use_status_code_handling } = this.props
    const methods = [
      { id: 'get', name: 'GET' },
      { id: 'post', name: 'POST' },
      { id: 'put', name: 'PUT' },
      { id: 'delete', name: 'DELETE' },
      { id: 'options', name: 'OPTIONS' },
      { id: 'patch', name: 'PATCH' },
    ]
    const responseTypes = [
      { id: 'json', name: 'JSON' },
      { id: 'text', name: 'TEXT' },
    ]

    return (
      <React.Fragment>
        <div className="form-group row dm-form-group" key="url">
          <LabelWithTooltip htmlFor="url" className="col-sm-2" name="integration.http.url" />
          <div className="col-sm-10">
            <Field
              type="url"
              name="url"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
            />
          </div>
        </div>
        <div className="form-group row dm-form-group" key="method">
          <LabelWithTooltip htmlFor="method" className="col-sm-2" name="integration.http.method" />
          <div className="col-sm-5">
            <Field
              name="method"
              items={methods}
              valueKey="id"
              displayKey="name"
              className="form-control dm-form-control"
              component={SelectField}
            />
          </div>
        </div>
        <div className="form-group row dm-form-group" key="headers">
          <LabelWithTooltip htmlFor="headers" className="col-sm-2" name="integration.http.headers" />
          <div className="col-sm-10">
            <FieldArray name="headers" component={this.renderHeaders} />
          </div>
        </div>
        <div className="form-group row dm-form-group" key="host">
          <LabelWithTooltip htmlFor="request_body" className="col-sm-2" name="integration.http.requestBody" />
          <div className="col-sm-7">
            <Field
              name="request_body"
              minRows={4}
              className="form-control dm-form-control"
              component={TextAreaField}
            />
          </div>
        </div>
        <div className="form-group row dm-form-group" key="response_type">
          <LabelWithTooltip
            htmlFor="response_type"
            className="col-sm-2"
            name="integration.http.responseType"
          />
          <div className="col-sm-5">
            <Field
              name="response_type"
              items={responseTypes}
              valueKey="id"
              displayKey="name"
              className="form-control dm-form-control"
              component={SelectField}
            />
          </div>
        </div>
        {response_type === 'json' && (
          <div className="form-group row dm-form-group" key="response_query">
            <LabelWithTooltip
              htmlFor="response_query"
              className="col-sm-2"
              name="integration.http.responseMap"
            />
            <div className="col-sm-10">
              <FieldArray
                name="response_map"
                response_map={response_map}
                component={this.renderResponseMap}
              />
            </div>
          </div>
        )}
        {response_type === 'text' && (
          <div className="form-group row dm-form-group" key="response_query">
            <LabelWithTooltip
              htmlFor="response_query"
              className="col-sm-2"
              name="integration.http.responseBodyVariable"
            />
            <div className="col-sm-5">
              <Field
                type="text"
                name="response_body_variable"
                className="form-control dm-form-control"
                maxLength="30"
                component={InputField}
              />
            </div>
          </div>
        )}
        {isPermitted('feature_http_integration_status_code_handing', this.context) && (
          <div className="form-group row dm-form-group col-sm-12" key="result">
            <div className="form-group form-inline checkbox">
              <Field
                type="checkbox"
                name="use_status_code_handling"
                id="use_status_code_handling"
                className="form-control m-0"
                component={CheckboxField}
              />
              <LabelWithTooltip
                htmlFor="use_status_code_handling"
                className="dm-checkbox"
                name="integration.http.customizeResults.title"
              />
            </div>
            {use_status_code_handling && (
              <div className="with-indent">
                <div className="form-group row">
                  <LabelWithTooltip
                    htmlFor="result_variable"
                    className="col-sm-2 pr-0"
                    name="integration.http.customizeResults.variable"
                  />
                  <div className="col-sm-5">
                    <Field
                      type="text"
                      name="result_variable"
                      className="form-control dm-form-control"
                      maxLength="30"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <LabelWithTooltip
                    htmlFor="user_status_code_map"
                    className="col-sm-2"
                    name="integration.http.customizeResults.conditions.title"
                  />
                  <div className="col-sm-10">
                    <FieldArray name="user_status_code_map" component={this.renderResultConditions} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    )
  }

  renderGoogleSpreadsheet() {
    const { sheet_values } = this.props

    return [
      <div className="form-group row dm-form-group" key="service_account_key">
        <LabelWithTooltip
          htmlFor="service_account_key"
          className="col-sm-2"
          name="integration.googleSpreadsheet.serviceAccountKey"
        />
        <div className="col-sm-10">
          <Field
            name="service_account_key"
            minRows={12}
            className="form-control dm-form-control"
            component={TextAreaField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="sheet_id">
        <LabelWithTooltip
          htmlFor="sheet_id"
          className="col-sm-2"
          name="integration.googleSpreadsheet.sheetId"
        />
        <div className="col-sm-10">
          <Field
            type="sheet_id"
            name="sheet_id"
            className="form-control dm-form-control"
            maxLength="50"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="sheet_values">
        <LabelWithTooltip
          htmlFor="sheet_values"
          className="col-sm-2"
          name="integration.googleSpreadsheet.sheetValues"
        />
        <div className="col-sm-10">
          <FieldArray
            name="sheet_values"
            sheet_values={sheet_values}
            component={this.renderGoogleSpreadsheetValues}
          />
        </div>
      </div>,
    ]
  }

  renderEmail() {
    const { t } = this.context
    const { emailAddresses, to_addresses, cc_addresses, bcc_addresses } = this.props
    return [
      <div className="form-group row dm-form-group" key="remarks">
        <div className="col-sm-10 pull-right">
          <ul className="notes">
            <li key="addressSumLimit">{t('integration.email.remarks.addressSumLimit')}</li>
            <li key="notSendToBounceOrComplaint">
              {t('integration.email.remarks.notSendToBounceOrComplaint')}
            </li>
            <li key="removeBounceOrComplaint">{t('integration.email.remarks.removeBounceOrComplaint')}</li>
            <li key="notSendIfHasUnconfirmedOrSo">
              {t('integration.email.remarks.notSendIfHasUnconfirmedOrSo')}
            </li>
          </ul>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="from">
        <LabelWithTooltip htmlFor="to" className="col-sm-2" name="integration.email.from" />
        <div className="col-sm-10">
          <Field
            name="from"
            className="form-control dm-form-control"
            component={InputField}
            disabled={true}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="reply">
        <LabelWithTooltip htmlFor="reply" className="col-sm-2" name="integration.email.reply" />
        <div className="col-sm-10">
          <Field
            name="reply_to_address"
            className="form-control dm-form-control"
            component={AutoCompleteField}
            items={emailAddresses}
            valueKey="email"
            displayKey="email"
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group incrementable-field" key="to">
        <LabelWithTooltip htmlFor="to" className="col-sm-2" name="integration.email.to" />
        <div className="col-sm-10">
          <FieldArray name="to_addresses" response_map={to_addresses} component={this.renderEmailList} />
        </div>
      </div>,
      <div className="form-group row dm-form-group incrementable-field" key="cc">
        <LabelWithTooltip htmlFor="cc" className="col-sm-2" name="integration.email.cc" />
        <div className="col-sm-10">
          <FieldArray name="cc_addresses" response_map={cc_addresses} component={this.renderEmailList} />
        </div>
      </div>,
      <div className="form-group row dm-form-group incrementable-field" key="bcc">
        <LabelWithTooltip htmlFor="bcc" className="col-sm-2" name="integration.email.bcc" />
        <div className="col-sm-10">
          <FieldArray name="bcc_addresses" response_map={bcc_addresses} component={this.renderEmailList} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="subject">
        <LabelWithTooltip htmlFor="subject" className="col-sm-2" name="integration.email.subject" />
        <div className="col-sm-10">
          <Field name="subject" className="form-control dm-form-control" component={InputField} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="body">
        <LabelWithTooltip htmlFor="body" className="col-sm-2" name="integration.email.body" />
        <div className="col-sm-10">
          <Field name="body" minRows={6} className="form-control dm-form-control" component={TextAreaField} />
        </div>
      </div>,
    ]
  }

  renderLineSwitcher() {
    const { t } = this.context
    const subtypes = [
      { label: 'Switch', value: 'switch' },
      { label: 'Notice', value: 'notice' },
    ]
    return [
      <div className="form-group row dm-form-group" key="remarks">
        <div className="col-sm-10 pull-right">
          <ul className="notes">
            <li key="allowedPlatform">{t('integration.lineSwitcher.remarks.allowedPlatform')}</li>
          </ul>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="sub_type">
        <LabelWithTooltip htmlFor="sub_type" className="col-sm-2" name="integration.lineSwitcher.subType" />
        <div className="col-sm-10">
          <Field
            name="sub_type"
            className="form-control dm-form-control"
            items={subtypes}
            valueKey="value"
            displayKey="label"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="destination_id">
        <LabelWithTooltip
          htmlFor="destination_id"
          className="col-sm-2"
          name="integration.lineSwitcher.destinationId"
        />
        <div className="col-sm-10">
          <Field name="destination_id" className="form-control dm-form-control" component={InputField} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="note">
        <LabelWithTooltip htmlFor="note" className="col-sm-2" name="integration.lineSwitcher.note" />
        <div className="col-sm-10">
          <Field name="note" className="form-control dm-form-control" component={TextAreaField} />
          <div className="checkbox">
            <Field
              type="checkbox"
              name="as_json_note"
              id="as_json_note"
              component={CheckboxField}
              className="form-control m-0"
            />
            <LabelWithTooltip htmlFor="as_json_note" name="integration.lineSwitcher.asJsonNote" />
          </div>
        </div>
      </div>,
    ]
  }

  renderOffice365() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { api_subtype, office365OAuthClient } = this.props
    const { is_experimental } = getCredentials(this.context)
    let api_subtype_items = [
      {
        id: 'get_user',
        name: t('integration.office365.apiSubType.getUser'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'get_users',
        name: t('integration.office365.apiSubType.getUsers'),
        is_experimental: false,
        allowPreauthentication: false,
      },
      {
        id: 'get_groups',
        name: t('integration.office365.apiSubType.getGroups'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'get_members',
        name: t('integration.office365.apiSubType.getMembers'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'get_event',
        name: t('integration.office365.apiSubType.getEvent'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'get_events',
        name: t('integration.office365.apiSubType.getEvents'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'post_event',
        name: t('integration.office365.apiSubType.postEvent'),
        is_experimental: true,
        allowPreauthentication: false,
      },
      {
        id: 'update_event',
        name: t('integration.office365.apiSubType.updateEvent'),
        is_experimental: true,
        allowPreauthentication: false,
      },
      {
        id: 'delete_event',
        name: t('integration.office365.apiSubType.deleteEvent'),
        is_experimental: false,
        allowPreauthentication: false,
      },
      {
        id: 'search_free_room',
        name: t('integration.office365.apiSubType.searchFreeRoom'),
        is_experimental: true,
        allowPreauthentication: false,
      },
      {
        id: 'get_access_token',
        name: t('integration.office365.apiSubType.getAccessToken'),
        is_experimental: false,
        allowPreauthentication: true,
      },
      {
        id: 'logout',
        name: t('integration.office365.apiSubType.logout'),
        is_experimental: false,
        allowPreauthentication: false,
      },
      {
        id: 'custom',
        name: t('integration.office365.apiSubType.custom'),
        is_experimental: false,
        allowPreauthentication: true,
      },
    ]
    if (!is_experimental) {
      api_subtype_items = lodash.filter(api_subtype_items, { is_experimental: false })
    }
    if (office365OAuthClient.use_preauthentication) {
      api_subtype_items = lodash.reject(api_subtype_items, item => {
        return item.id !== api_subtype && !item.allowPreauthentication
      })
    }

    let render_item = [
      <div className="form-group row dm-form-group" key="api_subtype">
        <LabelWithTooltip
          htmlFor="api_subtype"
          className="col-sm-2"
          name="integration.office365.api_subtype"
        />
        <div className="col-sm-5">
          <Field
            name="api_subtype"
            items={api_subtype_items}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
            disabled={isUpdate}
          />
          {lodash.includes(
            lodash.map(lodash.filter(api_subtype_items, { is_experimental: true }), 'id'),
            api_subtype
          ) && <div className="dm-note">{t('integration.office365.note')}</div>}
        </div>
        {api_subtype === 'custom' && (
          <div className="col-sm-5 dm-note">
            <a
              href="https://developer.microsoft.com/ja-jp/graph/docs/concepts/use_the_api"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('integration.office365.graph_api_reference')}
            </a>
          </div>
        )}
      </div>,
    ]
    if (api_subtype === 'get_user') {
      render_item = render_item.concat(this.renderOffice365GetUser())
    } else if (api_subtype === 'get_users') {
      render_item = render_item.concat(this.renderOffice365GetUsers())
    } else if (api_subtype === 'get_groups') {
      render_item = render_item.concat(this.renderOffice365GetGroups())
    } else if (api_subtype === 'get_members') {
      render_item = render_item.concat(this.renderOffice365GetMembers())
    } else if (api_subtype === 'get_event') {
      render_item = render_item.concat(this.renderOffice365GetEvent())
    } else if (api_subtype === 'get_events') {
      render_item = render_item.concat(this.renderOffice365GetEvents())
    } else if (api_subtype === 'search_free_room') {
      render_item = render_item.concat(this.renderOffice365SearchFreeRoom())
    } else if (api_subtype === 'custom') {
      render_item = render_item.concat(this.renderOffice365Custom())
    } else if (api_subtype === 'delete_event') {
      render_item = render_item.concat(this.renderOffice365DeleteEvent())
    } else if (api_subtype === 'post_event') {
      render_item = render_item.concat(this.renderOffice365PostEvent())
    } else if (api_subtype === 'update_event') {
      render_item = render_item.concat(this.renderOffice365UpdateEvent())
    } else if (api_subtype === 'get_access_token') {
      render_item = render_item.concat(this.renderOffice365GetAccessToken())
    } else if (api_subtype === 'logout') {
      render_item = render_item.concat(this.renderOffice365Logout())
    }
    return render_item
  }

  renderOffice365GetUser() {
    const { response_map_get_user } = this.props
    const { t } = this.context
    const { target_type, office365OAuthClient } = this.props

    let target_type_map = [
      { value: 'me', name: t('integration.office365.targetType.me'), allowPreauthentication: false },
      { value: 'user', name: t('integration.office365.targetType.user'), allowPreauthentication: true },
    ]
    if (office365OAuthClient.use_preauthentication) {
      target_type_map = lodash.reject(target_type_map, type => {
        return type.value !== target_type && !type.allowPreauthentication
      })
    }

    let render_item = [
      <div className="form-group row dm-form-group" key="target_type">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="target_type"
          className="col-sm-2"
          name="integration.office365.target_type"
        />
        <div className="col-sm-4">
          <Field
            name="target_type"
            items={target_type_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
    ]
    if (target_type === 'user') {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="user_id">
          <label className="col-sm-2" />
          <LabelWithTooltip htmlFor="user_id" className="col-sm-2" name="integration.office365.user_id" />
          <div className="col-sm-8">
            <Field
              type="text"
              name="user_id"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
              placeholder={t('integration.office365.placeholder.user_id', {
                interpolation: { suffix: '###', prefix: '###' },
              })}
            />
          </div>
        </div>,
      ])
    }
    render_item = render_item.concat([
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_user"
            response_map={response_map_get_user}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ])

    return render_item
  }

  renderOffice365GetUsers() {
    const { response_map_get_users, filter_items } = this.props
    const { t } = this.context

    const search_user_type_map = [
      { value: 'none', name: t('integration.office365.searchUserType.none') },
      { value: 'user', name: t('integration.office365.searchUserType.user') },
      { value: 'room', name: t('integration.office365.searchUserType.room') },
    ]

    return [
      <div className="form-group row dm-form-group" key="search_user_type">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="search_user_type"
          className="col-sm-2"
          name="integration.office365.search_user_type"
        />
        <div className="col-sm-8">
          <Field
            name="search_user_type"
            items={search_user_type_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="search_word">
        <label className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="search_word"
          className="col-sm-2"
          name="integration.office365.search_word"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="search_word"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.search_word', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="filter_items">
        <label className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="filter_items"
          className="col-sm-2"
          name="integration.office365.filter_items"
        />
        <div className="col-sm-8">
          <FieldArray name="filter_items" response_map={filter_items} component={this.renderFilterItems} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_users"
            response_map={response_map_get_users}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365GetGroups() {
    const { office365OAuthClient, response_map_get_groups, target_type } = this.props
    const { t } = this.context

    let target_type_map = [
      { value: 'me', name: t('integration.office365.targetType.me'), allowPreauthentication: false },
      { value: 'user', name: t('integration.office365.targetType.user'), allowPreauthentication: true },
    ]
    if (office365OAuthClient.use_preauthentication) {
      target_type_map = lodash.reject(target_type_map, type => {
        return type.value !== target_type && !type.allowPreauthentication
      })
    }

    let render_item = [
      <div className="form-group row dm-form-group" key="target_type">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="target_type"
          className="col-sm-2"
          name="integration.office365.target_type"
        />
        <div className="col-sm-4">
          <Field
            name="target_type"
            items={target_type_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
    ]
    if (target_type === 'user') {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="user_id">
          <label className="col-sm-2" />
          <LabelWithTooltip htmlFor="user_id" className="col-sm-2" name="integration.office365.user_id" />
          <div className="col-sm-8">
            <Field
              type="text"
              name="user_id"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
              placeholder={t('integration.office365.placeholder.user_id', {
                interpolation: { suffix: '###', prefix: '###' },
              })}
            />
          </div>
        </div>,
      ])
    }

    render_item = render_item.concat([
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_groups"
            response_map={response_map_get_groups}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ])
    return render_item
  }

  renderOffice365GetMembers() {
    const { response_map_get_members } = this.props
    const { t } = this.context

    return [
      <div className="form-group row dm-form-group" key="group_id">
        <label className="col-sm-2" />
        <LabelWithTooltip htmlFor="group_id" className="col-sm-2" name="integration.office365.group_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="group_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.group_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_members"
            response_map={response_map_get_members}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365GetEvent() {
    const { office365OAuthClient, response_map_get_event, target_type } = this.props
    const { t } = this.context

    let target_type_map = [
      { value: 'me', name: t('integration.office365.targetType.me'), allowPreauthentication: false },
      { value: 'user', name: t('integration.office365.targetType.user'), allowPreauthentication: true },
    ]
    if (office365OAuthClient.use_preauthentication) {
      target_type_map = lodash.reject(target_type_map, type => {
        return type.value !== target_type && !type.allowPreauthentication
      })
    }

    let render_item = [
      <div className="form-group row dm-form-group" key="target_type">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="target_type"
          className="col-sm-2"
          name="integration.office365.target_type"
        />
        <div className="col-sm-4">
          <Field
            name="target_type"
            items={target_type_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
    ]
    if (target_type === 'user') {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="user_id">
          <label className="col-sm-2" />
          <LabelWithTooltip htmlFor="user_id" className="col-sm-2" name="integration.office365.user_id" />
          <div className="col-sm-8">
            <Field
              type="text"
              name="user_id"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
              placeholder={t('integration.office365.placeholder.user_id', {
                interpolation: { suffix: '###', prefix: '###' },
              })}
            />
          </div>
        </div>,
      ])
    }
    render_item = render_item.concat([
      <div className="form-group row dm-form-group" key="event_id">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="event_id" className="col-sm-2" name="integration.office365.event_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="event_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.event_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_event"
            response_map={response_map_get_event}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ])

    return render_item
  }

  renderOffice365GetEvents() {
    const { office365OAuthClient, response_map_get_events, target_type } = this.props
    const { t } = this.context

    let target_type_map = [
      { value: 'me', name: t('integration.office365.targetType.me'), allowPreauthentication: false },
      { value: 'user', name: t('integration.office365.targetType.user'), allowPreauthentication: true },
    ]
    if (office365OAuthClient.use_preauthentication) {
      target_type_map = lodash.reject(target_type_map, type => {
        return type.value !== target_type && !type.allowPreauthentication
      })
    }

    const organizer_room_map = [
      { value: 'none', name: t('integration.office365.searchOrganizerRoom.none') },
      { value: 'organizer', name: t('integration.office365.searchOrganizerRoom.organizer') },
      { value: 'conference_room', name: t('integration.office365.searchOrganizerRoom.conference_room') },
    ]

    let render_item = [
      <div className="form-group row dm-form-group" key="target_type">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="target_type"
          className="col-sm-2"
          name="integration.office365.target_type"
        />
        <div className="col-sm-4">
          <Field
            name="target_type"
            items={target_type_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
    ]
    if (target_type === 'user') {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="user_id">
          <label className="col-sm-2" />
          <LabelWithTooltip htmlFor="user_id" className="col-sm-2" name="integration.office365.user_id" />
          <div className="col-sm-8">
            <Field
              type="text"
              name="user_id"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
              placeholder={t('integration.office365.placeholder.user_id', {
                interpolation: { suffix: '###', prefix: '###' },
              })}
            />
          </div>
        </div>,
      ])
    }
    render_item = render_item.concat([
      <div className="form-group row dm-form-group" key="start_time">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="start_time"
          className="col-sm-2"
          name="integration.office365.search_start_time"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="start_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.search_start_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="end_time">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="end_time"
          className="col-sm-2"
          name="integration.office365.search_end_time"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="end_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.search_end_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
    ])
    if (target_type === 'me') {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="organizer_room">
          <div className="col-sm-2" />
          <LabelWithTooltip
            htmlFor="end_time"
            className="col-sm-2"
            name="integration.office365.organizer_room"
          />
          <div className="col-sm-8">
            <Field
              name="organizer_room"
              items={organizer_room_map}
              valueKey="value"
              displayKey="name"
              className="form-control dm-form-control"
              component={SelectField}
            />
          </div>
        </div>,
      ])
    }
    render_item = render_item.concat([
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_events"
            response_map={response_map_get_events}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ])

    return render_item
  }

  renderOffice365SearchFreeRoom() {
    const { response_map_search_free_room } = this.props
    const { t } = this.context
    const activity_domain_map = [
      { value: 'unrestricted', name: t('integration.office365.activityDomain.unrestricted') },
      { value: 'work', name: t('integration.office365.activityDomain.work') },
      { value: 'personal', name: t('integration.office365.activityDomain.personal') },
    ]

    return [
      <div className="form-group row dm-form-group" key="start_time">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip
          htmlFor="start_time"
          className="col-sm-2"
          name="integration.office365.search_start_time"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="start_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.search_start_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="end_time">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="end_time"
          className="col-sm-2"
          name="integration.office365.search_end_time"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="end_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.search_end_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="conference_time">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="conference_time"
          className="col-sm-2"
          name="integration.office365.conference_time"
        />
        <div className="col-sm-8">
          <Field
            type="text"
            name="conference_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.conference_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="activity_domain">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="activity_domain"
          className="col-sm-2"
          name="integration.office365.activity_domain"
        />
        <div className="col-sm-8">
          <Field
            name="activity_domain"
            items={activity_domain_map}
            valueKey="value"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="room_id">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="room_id" className="col-sm-2" name="integration.office365.room_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="room_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.room_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="attendees">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="attendees" className="col-sm-2" name="integration.office365.attendees" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="attendees"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.attendees', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_search_free_room"
            response_map={response_map_search_free_room}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365PostEvent() {
    const { response_map_post_event } = this.props
    const { t } = this.context
    return [
      <div className="form-group row dm-form-group" key="title">
        <label className="col-sm-2">{t('integration.office365.insertParameter')}</label>
        <LabelWithTooltip htmlFor="title" className="col-sm-2" name="integration.office365.title" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="title"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.title', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="start_time">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="start_time" className="col-sm-2" name="integration.office365.start_time" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="start_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.start_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="end_time">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="end_time" className="col-sm-2" name="integration.office365.end_time" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="end_time"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.end_time', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="room_id">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="room_id" className="col-sm-2" name="integration.office365.room_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="room_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.room_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="attendees">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="attendees" className="col-sm-2" name="integration.office365.attendees" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="attendees"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.attendees', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_post_event"
            response_map={response_map_post_event}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365UpdateEvent() {
    const { response_map_update_event } = this.props
    const { t } = this.context
    return [
      <div className="form-group row dm-form-group" key="event_id">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip htmlFor="event_id" className="col-sm-2" name="integration.office365.event_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="event_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.event_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="update_parameters">
        <LabelWithTooltip
          htmlFor="update_parameters"
          className="col-sm-2"
          name="integration.office365.updateParameter"
        />
        <div className="col-sm-10">
          <FieldArray name="update_parameters" component={this.renderEventUpdateParameters} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_update_event"
            response_map={response_map_update_event}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365GetAccessToken() {
    const { response_map_get_access_token } = this.props
    return [
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_get_access_token"
            response_map={response_map_get_access_token}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365DeleteEvent() {
    const { t } = this.context
    return [
      <div className="form-group row dm-form-group" key="event_id">
        <label className="col-sm-2">{t('integration.office365.requestParameter')}</label>
        <LabelWithTooltip htmlFor="event_id" className="col-sm-2" name="integration.office365.event_id" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="event_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.office365.placeholder.event_id', {
              interpolation: { suffix: '###', prefix: '###' },
            })}
          />
        </div>
      </div>,
    ]
  }

  renderOffice365Logout() {
    return []
  }

  renderOffice365Custom() {
    const { response_map_custom } = this.props

    const methods = [
      { id: 'get', name: 'GET' },
      { id: 'post', name: 'POST' },
      { id: 'put', name: 'PUT' },
      { id: 'delete', name: 'DELETE' },
      { id: 'options', name: 'OPTIONS' },
      { id: 'patch', name: 'PATCH' },
    ]
    const responseTypes = [{ id: 'json', name: 'JSON' }]

    return [
      <div className="form-group row dm-form-group" key="oauth_scope">
        <LabelWithTooltip
          htmlFor="oauth_scope"
          className="col-sm-2"
          name="integration.office365.oauth_scope"
        />
        <div className="col-sm-10">
          <Field
            type="text"
            name="oauth_scope"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="url">
        <LabelWithTooltip htmlFor="url" className="col-sm-2" name="integration.office365.url" />
        <div className="col-sm-10">
          <Field
            type="url"
            name="url"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            value={this.state.url}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="method">
        <LabelWithTooltip htmlFor="method" className="col-sm-2" name="integration.office365.method" />
        <div className="col-sm-5">
          <Field
            name="method"
            items={methods}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="headers">
        <LabelWithTooltip htmlFor="headers" className="col-sm-2" name="integration.office365.headers" />
        <div className="col-sm-10">
          <FieldArray name="headers" component={this.renderHeaders} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="host">
        <LabelWithTooltip
          htmlFor="request_body"
          className="col-sm-2"
          name="integration.office365.requestBody"
        />
        <div className="col-sm-7">
          <Field
            name="request_body"
            minRows={4}
            className="form-control dm-form-control"
            component={TextAreaField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_type">
        <LabelWithTooltip
          htmlFor="response_type"
          className="col-sm-2"
          name="integration.office365.responseType"
        />
        <div className="col-sm-5">
          <Field
            name="response_type"
            items={responseTypes}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.office365.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray
            name="response_map_custom"
            response_map={response_map_custom}
            component={this.renderResponseMap}
          />
        </div>
      </div>,
    ]
  }

  renderExcel() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { dispatch, excel_api_subtype, header_columns, header_error_message } = this.props
    const excel_api_subtype_items = [
      { id: 'search', name: t('integration.excel.excelApiSubType.search') },
      { id: 'insert', name: t('integration.excel.excelApiSubType.insert') },
      { id: 'update', name: t('integration.excel.excelApiSubType.update') },
      { id: 'delete', name: t('integration.excel.excelApiSubType.delete') },
    ]
    let render_item = [
      <div className="form-group row dm-form-group" key="excel_api_subtype">
        <LabelWithTooltip
          htmlFor="excel_api_subtype"
          className="col-sm-2"
          name="integration.excel.api_subtype"
        />
        <div className="col-sm-5">
          <Field
            name="excel_api_subtype"
            items={excel_api_subtype_items}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
            disabled={isUpdate}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="tenant_id">
        <label className="col-sm-2">{t('integration.excel.serviceProvider')}</label>
        <LabelWithTooltip htmlFor="tenant_id" className="col-sm-2" name="integration.excel.tenantId" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="tenant_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="client_id">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="client_id" className="col-sm-2" name="integration.excel.clientId" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="client_id"
            className="form-control dm-form-control"
            maxLength="36"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="client_secret">
        <div className="col-sm-2" />
        <LabelWithTooltip
          htmlFor="client_secret"
          className="col-sm-2"
          name="integration.excel.clientSecret"
        />
        <div className="col-sm-8">
          <Field
            type="password"
            name="client_secret"
            className="form-control dm-form-control"
            autoComplete="new-password"
            maxLength="255"
            component={InputField}
            placeholder={this.props.params.id ? '********' : ''}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="drive_id">
        <label className="col-sm-2">{t('integration.excel.excelProvider')}</label>
        <LabelWithTooltip htmlFor="drive_id" className="col-sm-2" name="integration.excel.driveId" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="drive_id"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="file_name">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="file_name" className="col-sm-2" name="integration.excel.fileName" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="file_name"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="sheet_name">
        <div className="col-sm-2" />
        <LabelWithTooltip htmlFor="sheet_name" className="col-sm-2" name="integration.excel.sheetName" />
        <div className="col-sm-8">
          <Field
            type="text"
            name="sheet_name"
            className="form-control dm-form-control"
            maxLength="32"
            component={InputField}
          />
        </div>
      </div>,
    ]
    if (this.props.params.id) {
      if (!header_columns || header_columns.length === 0) {
        if (header_error_message) {
          dispatch(addNotice('error', header_error_message))
        }
      } else {
        if (excel_api_subtype === 'search') {
          render_item = render_item.concat(this.renderExcelSearch())
        } else if (excel_api_subtype === 'insert') {
          render_item = render_item.concat(this.renderExcelInsert())
        } else if (excel_api_subtype === 'update') {
          render_item = render_item.concat(this.renderExcelUpdate())
        } else if (excel_api_subtype === 'delete') {
          render_item = render_item.concat(this.renderExcelDelete())
        }
      }
    }
    if (lodash.includes(['search', 'update', 'delete'], excel_api_subtype)) {
      render_item = [...render_item, this.renderVariableName(excel_api_subtype)]
    }
    return render_item
  }

  renderExcelSearch() {
    const { t } = this.context
    const { request_parameters, excel_columns } = this.props

    let render_item = [
      <div className="form-group row dm-form-group" key="request_columns">
        <LabelWithTooltip
          htmlFor="request_columns"
          className="col-sm-2"
          name="integration.excel.requestColumns"
        />
        <div className="col-sm-10">
          <Field
            name="request_columns"
            items={excel_columns}
            valueKey="name"
            displayKey="label"
            component={MultipleSelectList}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="use_distinct">
        <div className="col-sm-2" />
        <div className="col-sm-10">
          <fieldset>
            <div className="checkbox">
              <Field
                type="checkbox"
                name="use_distinct"
                id="use_distinct"
                component={CheckboxField}
                className="form-control m-0"
                onChange={this.onChangeUseDistinct}
              />
              <LabelWithTooltip htmlFor="use_distinct" name="integration.excel.use_distinct" />
            </div>
          </fieldset>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="use_or_operator">
        <LabelWithTooltip
          htmlFor="request_parameters"
          className="col-sm-2"
          name="integration.excel.requestParameters"
        />
        <div className="col-sm-10">
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="false"
              component="input"
              className="form-control"
              id="and"
            />
            <label htmlFor="and">{t('integration.excel.compositeItems.and')}</label>
          </div>
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="true"
              component="input"
              className="form-control"
              id="or"
            />
            <label htmlFor="or">{t('integration.excel.compositeItems.or')}</label>
          </div>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="request_parameters">
        <div className="col-sm-2" />
        <div className="col-sm-10">
          <FieldArray
            name="request_parameters"
            response_map={request_parameters}
            component={this.renderRequestParameterMap}
          />
        </div>
      </div>,
    ]

    return render_item
  }

  renderExcelInsert() {
    const { insert_parameters } = this.props

    let render_item = [
      <div className="form-group row dm-form-group" key="insert_parameters">
        <LabelWithTooltip
          htmlFor="insert_parameters"
          className="col-sm-2"
          name="integration.excel.insertParameters"
        />
        <div className="col-sm-10">
          <FieldArray
            name="insert_parameters"
            response_map={insert_parameters}
            component={this.renderExcelInsertParameters}
          />
        </div>
      </div>,
    ]
    return render_item
  }

  renderExcelUpdate() {
    const { t } = this.context
    const { request_parameters, update_parameters } = this.props

    let render_item = [
      <div className="form-group row dm-form-group" key="use_or_operator">
        <LabelWithTooltip
          htmlFor="request_parameters"
          className="col-sm-2"
          name="integration.excel.requestParameters"
        />
        <div className="col-sm-10">
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="false"
              component="input"
              className="form-control"
              id="and"
            />
            <label htmlFor="and">{t('integration.excel.compositeItems.and')}</label>
          </div>
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="true"
              component="input"
              className="form-control"
              id="or"
            />
            <label htmlFor="or">{t('integration.excel.compositeItems.or')}</label>
          </div>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="request_parameters">
        <div className="col-sm-2" />
        <div className="col-sm-10">
          <FieldArray
            name="request_parameters"
            response_map={request_parameters}
            component={this.renderRequestParameterMap}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="update_parameters">
        <LabelWithTooltip
          htmlFor="update_parameters"
          className="col-sm-2"
          name="integration.excel.updateParameters"
        />
        <div className="col-sm-10">
          <FieldArray
            name="update_parameters"
            response_map={update_parameters}
            component={this.renderExcelUpdateParameters}
          />
        </div>
      </div>,
    ]
    return render_item
  }

  renderExcelDelete() {
    const { t } = this.context
    const { request_parameters } = this.props

    let render_item = [
      <div className="form-group row dm-form-group" key="use_or_operator">
        <LabelWithTooltip
          htmlFor="request_parameters"
          className="col-sm-2"
          name="integration.excel.requestParameters"
        />
        <div className="col-sm-10">
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="false"
              component="input"
              className="form-control"
              id="and"
            />
            <label htmlFor="and">{t('integration.excel.compositeItems.and')}</label>
          </div>
          <div className="radio-inline">
            <Field
              name="use_or_operator"
              type="radio"
              value="true"
              component="input"
              className="form-control"
              id="or"
            />
            <label htmlFor="or">{t('integration.excel.compositeItems.or')}</label>
          </div>
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="request_parameters">
        <div className="col-sm-2" />
        <div className="col-sm-10">
          <FieldArray
            name="request_parameters"
            response_map={request_parameters}
            component={this.renderRequestParameterMap}
          />
        </div>
      </div>,
    ]
    return render_item
  }

  renderSalesforce() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { salesforceOAuthClient, salesforce_api_subtype, salesforceObjects, isFetching } = this.props

    let api_subtype_items = []

    if (isPermitted('integration_salesforce_advance', this.context)) {
      api_subtype_items.push({
        id: 'search',
        name: t('integration.salesforce.apiSubType.search'),
        allowPreauthentication: true,
      })
      api_subtype_items.push({
        id: 'insert',
        name: t('integration.salesforce.apiSubType.insert'),
        allowPreauthentication: true,
      })
      api_subtype_items.push({
        id: 'keyword',
        name: t('integration.salesforce.apiSubType.keyword'),
        allowPreauthentication: true,
      })
      api_subtype_items.push({
        id: 'picklist',
        name: t('integration.salesforce.apiSubType.picklist'),
        allowPreauthentication: true,
      })
    }

    api_subtype_items.push({
      id: 'logout',
      name: t('integration.salesforce.apiSubType.logout'),
      allowPreauthentication: false,
    })
    api_subtype_items.push({
      id: 'custom',
      name: t('integration.salesforce.apiSubType.custom'),
      allowPreauthentication: true,
    })

    if (salesforceOAuthClient.use_preauthentication) {
      api_subtype_items = lodash.reject(api_subtype_items, item => {
        return item.id !== salesforce_api_subtype && !item.allowPreauthentication
      })
    }

    let is_verified = false
    if (lodash.includes(['search', 'insert', 'keyword', 'picklist'], salesforce_api_subtype)) {
      is_verified = salesforceOAuthClient.is_verified
    }
    let render_item = [
      <div className="form-group row dm-form-group" key="salesforce_api_subtype">
        <LabelWithTooltip
          htmlFor="salesforce_api_subtype"
          className="col-sm-2"
          name="integration.salesforce.api_subtype"
        />
        <div className="col-sm-5">
          <Field
            name="salesforce_api_subtype"
            items={api_subtype_items}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
            disabled={isUpdate}
            onChange={this.onChangeSalesforceApiSubType}
          />
          {salesforce_api_subtype === 'picklist' && (
            <div className="dm-note text-nowrap">{t('integration.salesforce.picklistNote')}</div>
          )}
        </div>
        {salesforce_api_subtype === 'custom' && (
          <div className="col-sm-5 dm-note">
            <a
              href="https://developer.salesforce.com/docs/atlas.ja-jp.api_rest.meta/api_rest/resources_list.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('integration.salesforce.api_reference')}
            </a>
          </div>
        )}
        {lodash.includes(['search', 'insert', 'keyword', 'picklist'], salesforce_api_subtype) &&
          !salesforceOAuthClient.use_preauthentication && (
            <button
              type="button"
              name="select_oauth"
              className="btn btn-warning dm-btn"
              onClick={this.handleOAuthRequest}
              disabled={isFetching || lodash.isEmpty(salesforceOAuthClient)}
            >
              {is_verified
                ? t('integration.salesforce.signinButtons.signinWithOtherUser')
                : t('integration.salesforce.signinButtons.signin')}
            </button>
          )}
      </div>,
    ]
    if (is_verified && lodash.includes(['insert', 'search', 'keyword', 'picklist'], salesforce_api_subtype)) {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="object_name">
          <LabelWithTooltip
            htmlFor="object_name"
            className="col-sm-2"
            name="integration.salesforce.objectName"
          />
          <div className="col-sm-5">
            <Field
              name="object_name"
              items={salesforceObjects}
              valueKey="name"
              displayKey="label"
              className="form-control dm-form-control"
              component={SelectField}
              empty={true}
              onChange={this.onChangeSalesforceObject}
            />
          </div>
        </div>,
      ])
    }
    if (salesforce_api_subtype === 'custom') {
      render_item = render_item.concat(this.renderSalesforceCustom())
    } else if (salesforce_api_subtype === 'logout') {
      render_item = render_item.concat(this.renderSalesforceLogout())
    } else if (salesforce_api_subtype === 'search' && is_verified) {
      render_item = render_item.concat(this.renderSalesforceSearch())
    } else if (salesforce_api_subtype === 'insert' && is_verified) {
      render_item = render_item.concat(this.renderSalesforceInsert())
    } else if (salesforce_api_subtype === 'keyword' && is_verified) {
      render_item = render_item.concat(this.renderSalesforceKeyword())
    } else if (salesforce_api_subtype === 'picklist' && is_verified) {
      render_item = render_item.concat(this.renderSalesforcePickList())
    }
    return render_item
  }

  onChangeSalesforceApiSubType = event => {
    const { t } = this.context
    const { bot, dispatch, type, salesforceOAuthClient } = this.props

    if (type !== 'salesforce') return

    const selectedIndex = event.target.selectedIndex
    const salesforce_api_subtype = selectedIndex !== -1 ? event.target.options[selectedIndex].value : ''
    if (bot.oauth_client_id_salesforce) {
      this.callSalesforceObject(salesforceOAuthClient, salesforce_api_subtype)
    } else {
      dispatch(addNotice('warn', t('integration.salesforce.failedClientIdMessage')))
    }

    if (lodash.includes(['search', 'keyword', 'picklist'], salesforce_api_subtype)) {
      const subtypeLabel = t(`integration.salesforce.apiSubType.${event.target.value}`)
      this.props.change('response_map', [
        {
          from: '.',
          to: t('integration.salesforce.defaultResponseMap', { type: subtypeLabel }),
        },
      ])
    }
  }

  onChangeSalesforceObject = event => {
    const state = this.context.store.getState()
    const { t } = this.context
    const { bot, dispatch, object_name, salesforce_api_subtype, insert_parameters } = this.props
    const oauth_client_id = bot.oauth_client_id_salesforce
    const selectedIndex = event.target.selectedIndex
    const change_object_name = selectedIndex !== -1 ? event.target.options[selectedIndex].value : ''
    if (
      object_name !== '' &&
      (lodash.includes(['search', 'keyword', 'picklist'], salesforce_api_subtype) ||
        insert_parameters.length > 0)
    ) {
      if (!window.confirm(t('integration.salesforce.confirmObjectColumnChangeMessage'))) {
        event.preventDefault()
        return
      }
    }
    if (change_object_name !== '') {
      dispatch(
        getSalesforceObjectDetail(
          state.session.token,
          oauth_client_id,
          change_object_name,
          salesforce_api_subtype
        )
      )
        .then(value => {
          let columns = this.getSelectObject(value['salesforceObjectDetails']).map(item => {
            return item['name']
          })
          if (salesforce_api_subtype === 'search') {
            this.props.change('salesforce_columns', columns)
            this.props.change('salesforce_conditions', [])
            this.props.change('salesforce_orders', [])
          } else if (salesforce_api_subtype === 'keyword') {
            this.props.change('salesforce_columns', columns)
          } else if (salesforce_api_subtype === 'insert') {
            this.props.change('salesforce_columns', columns)
            this.props.change('insert_parameters', [])
          } else if (salesforce_api_subtype === 'picklist') {
            this.props.change('picklist_column', '')
          }
        })
        .catch(e => {
          dispatch(fetchOAuthClients(state.session.token))
          dispatch(addNotice('error', e.message))
        })
    }
  }

  onChangeUseDistinct = event => {
    const { salesforce_columns, salesforceObjectDetails } = this.props
    if (event.target.checked) {
      let columns = lodash.compact(
        salesforce_columns.map(name => {
          let item = lodash.filter(salesforceObjectDetails['select'], { name: name, groupable: true })
          if (item.length < 1) return null
          return name
        })
      )
      this.props.change('salesforce_columns', columns)
    }
  }

  callSalesforceObject(oauthClient, salesforceApiSubtype) {
    const state = this.context.store.getState()
    const { t } = this.context
    const { dispatch, object_name } = this.props

    if (!lodash.includes(['search', 'insert', 'keyword', 'picklist'], salesforceApiSubtype)) return
    if (lodash.isEmpty(oauthClient)) return
    if (!oauthClient.is_verified) {
      if (oauthClient.use_preauthentication) {
        return dispatch(addNotice('error', t('integration.salesforce.notPreauthenticatedYetMessage')))
      } else {
        return
      }
    }

    return dispatch(getSalesforceObject(state.session.token, oauthClient.id, salesforceApiSubtype))
      .then(() => {
        if (object_name) {
          dispatch(
            getSalesforceObjectDetail(state.session.token, oauthClient.id, object_name, salesforceApiSubtype)
          ).catch(e => {
            dispatch(fetchOAuthClients(state.session.token))
            dispatch(addNotice('error', e.message))
          })
        }
      })
      .catch(e => {
        dispatch(fetchOAuthClients(state.session.token))
        dispatch(addNotice('error', e.message))
      })
  }

  getSelectObject(object) {
    const { use_distinct } = this.props

    return use_distinct ? lodash.filter(object['select'], { groupable: true }) : object['select']
  }

  renderSalesforceSearch() {
    const { t } = this.context
    const {
      salesforceObjectDetails,
      object_name,
      salesforce_conditions,
      salesforce_orders,
      response_map,
    } = this.props
    let render_item = []
    if (object_name !== '' && salesforceObjectDetails !== undefined) {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="salesforce_columns">
          <LabelWithTooltip
            htmlFor="salesforce_columns"
            className="col-sm-2"
            name="integration.salesforce.requestColumn"
          />
          <div className="col-sm-10">
            <Field
              name="salesforce_columns"
              items={this.getSelectObject(salesforceObjectDetails)}
              valueKey="name"
              displayKey="label"
              component={MultipleSelectList}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="use_distinct">
          <div className="col-sm-2" />
          <div className="col-sm-10">
            <fieldset>
              <div className="checkbox">
                <Field
                  type="checkbox"
                  name="use_distinct"
                  id="use_distinct"
                  component={CheckboxField}
                  className="form-control m-0"
                  onChange={this.onChangeUseDistinct}
                />
                <LabelWithTooltip htmlFor="use_distinct" name="integration.salesforce.use_distinct" />
              </div>
            </fieldset>
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="use_or_operator">
          <LabelWithTooltip
            htmlFor="salesforce_conditions"
            className="col-sm-2"
            name="integration.salesforce.requestParameter"
          />
          <div className="col-sm-10">
            <div className="radio-inline">
              <Field
                name="use_or_operator"
                type="radio"
                value="false"
                component="input"
                className="form-control"
                id="and"
              />
              <label htmlFor="and">{t('integration.salesforce.compositeItems.and')}</label>
            </div>
            <div className="radio-inline">
              <Field
                name="use_or_operator"
                type="radio"
                value="true"
                component="input"
                className="form-control"
                id="or"
              />
              <label htmlFor="or">{t('integration.salesforce.compositeItems.or')}</label>
            </div>
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="salesforce_conditions">
          <div className="col-sm-2" />
          <div className="col-sm-10">
            <FieldArray
              name="salesforce_conditions"
              response_map={salesforce_conditions}
              component={this.renderSalesforceRequestParameters}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="salesforce_orders">
          <LabelWithTooltip
            htmlFor="salesforce_orders"
            className="col-sm-2"
            name="integration.salesforce.requestOrderParameter"
          />
          <div className="col-sm-10">
            <FieldArray
              name="salesforce_orders"
              response_map={salesforce_orders}
              component={this.renderSalesforceRequestOrderParameters}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="response_query">
          <LabelWithTooltip
            htmlFor="response_query"
            className="col-sm-2"
            name="integration.salesforce.responseMap"
          />
          <div className="col-sm-10">
            <FieldArray name="response_map" response_map={response_map} component={this.renderResponseMap} />
          </div>
        </div>,
      ])
    }
    return render_item
  }

  renderSalesforcePickList() {
    const { salesforceObjectDetails, object_name, response_map } = this.props
    let render_item = []
    if (object_name !== '' && salesforceObjectDetails !== undefined) {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="picklist_column">
          <LabelWithTooltip
            htmlFor="picklist_column"
            className="col-sm-2"
            name="integration.salesforce.requestColumn"
          />
          <div className="col-sm-5">
            <Field
              name="picklist_column"
              items={salesforceObjectDetails['picklist']}
              valueKey="name"
              displayKey="label"
              className="form-control dm-form-control"
              component={SelectField}
              empty={true}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="response_query">
          <LabelWithTooltip
            htmlFor="response_query"
            className="col-sm-2"
            name="integration.salesforce.responseMap"
          />
          <div className="col-sm-10">
            <FieldArray name="response_map" response_map={response_map} component={this.renderResponseMap} />
          </div>
        </div>,
      ])
    }
    return render_item
  }

  renderSalesforceLogout() {
    return []
  }

  renderSalesforceCustom() {
    const { response_map } = this.props

    const methods = [
      { id: 'get', name: 'GET' },
      { id: 'post', name: 'POST' },
      { id: 'put', name: 'PUT' },
      { id: 'delete', name: 'DELETE' },
      { id: 'options', name: 'OPTIONS' },
      { id: 'patch', name: 'PATCH' },
    ]
    const responseTypes = [{ id: 'json', name: 'JSON' }]

    return [
      <div className="form-group row dm-form-group" key="url">
        <LabelWithTooltip htmlFor="url" className="col-sm-2" name="integration.salesforce.url" />
        <div className="col-sm-10">
          <Field
            type="url"
            name="url"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            value={this.state.url}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="method">
        <LabelWithTooltip htmlFor="method" className="col-sm-2" name="integration.salesforce.method" />
        <div className="col-sm-5">
          <Field
            name="method"
            items={methods}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="headers">
        <LabelWithTooltip htmlFor="headers" className="col-sm-2" name="integration.salesforce.headers" />
        <div className="col-sm-10">
          <FieldArray name="headers" component={this.renderHeaders} />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="host">
        <LabelWithTooltip
          htmlFor="request_body"
          className="col-sm-2"
          name="integration.salesforce.requestBody"
        />
        <div className="col-sm-7">
          <Field
            name="request_body"
            minRows={4}
            className="form-control dm-form-control"
            component={TextAreaField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_type">
        <LabelWithTooltip
          htmlFor="response_type"
          className="col-sm-2"
          name="integration.salesforce.responseType"
        />
        <div className="col-sm-5">
          <Field
            name="response_type"
            items={responseTypes}
            valueKey="id"
            displayKey="name"
            className="form-control dm-form-control"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="response_query">
        <LabelWithTooltip
          htmlFor="response_query"
          className="col-sm-2"
          name="integration.salesforce.responseMap"
        />
        <div className="col-sm-10">
          <FieldArray name="response_map" response_map={response_map} component={this.renderResponseMap} />
        </div>
      </div>,
    ]
  }

  renderOkbiz() {
    const { t } = this.context
    const { use_test_environment, use_site_id } = this.props
    const sorttypes = [
      { label: t('integration.okbiz.sortTypes.keyword'), value: 'sort_keyword' },
      { label: t('integration.okbiz.sortTypes.new'), value: 'sort_new' },
      { label: t('integration.okbiz.sortTypes.important'), value: 'sort_adjust_value' },
    ]

    return [
      <div className="form-group row dm-form-group" key="question">
        <LabelWithTooltip htmlFor="question" className="col-sm-2" name="integration.okbiz.question" />
        <div className="col-sm-10">
          <Field
            name="question"
            minRows={5}
            className="form-control dm-form-control"
            component={TextAreaField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="website_url">
        <LabelWithTooltip htmlFor="website_url" className="col-sm-2" name="integration.okbiz.website_url" />
        <div className="col-sm-10">
          <Field
            type="text"
            name="website_url"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
            placeholder={t('integration.okbiz.placeholder.website_url')}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="client_id">
        <LabelWithTooltip htmlFor="client_id" className="col-sm-2" name="integration.okbiz.client_id" />
        <div className="col-sm-10">
          <Field
            type="text"
            name="client_id"
            className="form-control dm-form-control"
            maxLength="66" // 64 + \t\t
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="client_secret">
        <LabelWithTooltip
          htmlFor="client_secret"
          className="col-sm-2"
          name="integration.okbiz.client_secret"
        />
        <div className="col-sm-10">
          <Field
            type="password"
            name="client_secret"
            className="form-control dm-form-control"
            maxLength="66" // 64 + \t\t
            placeholder={this.props.params.id ? '********' : ''}
            component={InputField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="sort_type">
        <LabelWithTooltip htmlFor="sort_type" className="col-sm-2" name="integration.okbiz.sort_type" />
        <div className="col-sm-10">
          <Field
            name="sort_type"
            className="form-control dm-form-control"
            items={sorttypes}
            valueKey="value"
            displayKey="label"
            component={SelectField}
          />
        </div>
      </div>,
      <div className="form-group row dm-form-group" key="advanced_settings">
        <LabelWithTooltip className="col-sm-2" name="integration.okbiz.advanced_settings" />
        <div className="col-sm-10">
          <div>
            <div className="checkbox mt-0">
              <Field
                type="checkbox"
                name="use_site_id"
                className="form-control dm-form-control ml-0"
                component={InputField}
              />
              <LabelWithTooltip htmlFor="use_site_id" name="integration.okbiz.use_site_id" />
            </div>
            {use_site_id && (
              <div className="pl-5">
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip htmlFor="site_id" className="col-sm-2" name="integration.okbiz.site_id" />
                  <div className="col-sm-10">
                    <Field
                      type="number"
                      name="site_id"
                      className="form-control dm-form-control"
                      component={InputField}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="checkbox mt-0">
              <Field
                type="checkbox"
                name="use_test_environment"
                className="form-control dm-form-control ml-0"
                component={InputField}
              />
              <LabelWithTooltip
                htmlFor="use_test_environment"
                name="integration.okbiz.use_test_environment"
              />
            </div>
            {use_test_environment && (
              <div className="pl-5">
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="basic_auth_user"
                    className="col-sm-2"
                    name="integration.okbiz.basic_auth_user"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="text"
                      name="basic_auth_user"
                      maxLength="255"
                      className="form-control dm-form-control"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="form-group dm-form-group row">
                  <LabelWithTooltip
                    htmlFor="basic_auth_password"
                    className="col-sm-2"
                    name="integration.okbiz.basic_auth_password"
                  />
                  <div className="col-sm-10">
                    <Field
                      type="password"
                      name="basic_auth_password"
                      maxLength="255"
                      className="form-control dm-form-control"
                      placeholder={this.props.params.id ? '********' : ''}
                      component={InputField}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>,
    ]
  }

  renderTool() {
    const { type } = this.props

    const [_, name] = type.split(':')
    const tool = this.state.aiAssistantTools[name]
    if (!tool?.fetched) return <ReactLoader />

    return (
      <>
        <div className="form-group row dm-form-group" key="description">
          <LabelWithTooltip className="col-sm-2" name="integration.aiAssistantTool.description" />
          <div className="col-sm-10">
            <label>{tool.description}</label>
          </div>
        </div>
        {this.renderToolEndpoints()}
        {this.renderToolParameters()}
      </>
    )
  }

  renderToolEndpoints() {
    const { t } = this.context
    const { type } = this.props

    const [_, id] = type.split(':')
    const tool = this.state.aiAssistantTools[id]

    const columns = [
      {
        Header: t('integration.aiAssistantTool.endpoints.endpoint'),
        id: 'endpoint',
        accessor: 'endpoint',
        minWidth: 300,
      },
      {
        Header: t('integration.aiAssistantTool.endpoints.description'),
        id: 'description',
        accessor: 'description',
        Cell: ({ value }) => {
          return <div className="wrap">{value}</div>
        },
        minWidth: 600,
      },
    ]

    return (
      <DataBindingTable
        title={t('integration.aiAssistantTool.endpoints.title')}
        items={tool.endpoints}
        columns={columns}
        tableState={{ sorted: [] }}
      />
    )
  }

  renderToolParameters() {
    const { t } = this.context
    const { type } = this.props

    const [_, id] = type.split(':')
    const tool = this.state.aiAssistantTools[id]

    const columns = [
      {
        Header: t('integration.aiAssistantTool.parameters.name'),
        id: 'name',
        accessor: 'name',
        Cell: props => {
          return (
            <>
              {props.original.name} <span className="note">({props.original.in})</span>
            </>
          )
        },
        minWidth: 200,
      },
      {
        Header: t('integration.aiAssistantTool.parameters.description'),
        id: 'description',
        accessor: 'description',
        Cell: ({ value }) => {
          return <div className="wrap">{value}</div>
        },
        minWidth: 450,
      },
      {
        Header: t('integration.aiAssistantTool.parameters.value'),
        id: 'value',
        accessor: 'value',
        Cell: props => {
          return (
            <Field
              name={`parameters.${props.original.name}`}
              type="text"
              className="form-control"
              component="input"
            />
          )
        },
        minWidth: 250,
      },
    ]

    return (
      <DataBindingTable
        title={t('integration.aiAssistantTool.parameters.title')}
        items={Object.values(tool.getPreconfiguredParameters())}
        columns={columns}
        tableState={{ sorted: [] }}
      />
    )
  }

  renderSalesforceInsert() {
    const {
      salesforceObjectDetails,
      object_name,
      response_map_salesforce_insert,
      insert_parameters,
    } = this.props
    let render_item = []
    if (object_name !== '' && salesforceObjectDetails !== undefined) {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="insert_parameters">
          <div>
            <LabelWithTooltip
              htmlFor="insert_parameters"
              className="col-sm-2"
              name="integration.salesforce.insertParameter"
            />
          </div>
          <div className="col-sm-10">
            <FieldArray
              name="insert_parameters"
              response_map={insert_parameters}
              component={this.renderSalesforceInsertParameters}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="response_query">
          <LabelWithTooltip
            htmlFor="response_query"
            className="col-sm-2"
            name="integration.http.responseMap"
          />
          <div className="col-sm-10">
            <FieldArray
              name="response_map_salesforce_insert"
              response_map={response_map_salesforce_insert}
              component={this.renderResponseMap}
            />
          </div>
        </div>,
      ])
    }
    return render_item
  }

  renderSalesforceKeyword() {
    const { t } = this.context
    const { salesforceObjectDetails, object_name, response_map } = this.props
    const search_group_items = [
      { id: 'all', name: t('integration.salesforce.keywordSearchGroup.all') },
      { id: 'name', name: t('integration.salesforce.keywordSearchGroup.name') },
      { id: 'phone', name: t('integration.salesforce.keywordSearchGroup.phone') },
      { id: 'email', name: t('integration.salesforce.keywordSearchGroup.email') },
    ]
    let render_item = []
    if (object_name !== '' && salesforceObjectDetails !== undefined) {
      render_item = render_item.concat([
        <div className="form-group row dm-form-group" key="salesforce_columns">
          <LabelWithTooltip
            htmlFor="salesforce_columns"
            className="col-sm-2"
            name="integration.salesforce.requestColumn"
          />
          <div className="col-sm-10">
            <Field
              name="salesforce_columns"
              items={this.getSelectObject(salesforceObjectDetails)}
              valueKey="name"
              displayKey="label"
              component={MultipleSelectList}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="search_group">
          <div>
            <LabelWithTooltip
              htmlFor="search_group"
              className="col-sm-2"
              name="integration.salesforce.searchGroup"
            />
          </div>
          <div className="col-sm-10">
            <Field
              name="search_group"
              items={search_group_items}
              valueKey="id"
              displayKey="name"
              className="form-control dm-form-control"
              component={SelectField}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="search_word">
          <LabelWithTooltip
            htmlFor="search_word"
            className="col-sm-2 pr-0"
            name={'integration.salesforce.searchWord'}
          />
          <div className="col-sm-10">
            <Field
              type="text"
              name="search_word"
              className="form-control dm-form-control"
              maxLength="255"
              component={InputField}
            />
          </div>
        </div>,
        <div className="form-group row dm-form-group" key="response_query">
          <LabelWithTooltip
            htmlFor="response_query"
            className="col-sm-2"
            name="integration.salesforce.responseMap"
          />
          <div className="col-sm-10">
            <FieldArray name="response_map" response_map={response_map} component={this.renderResponseMap} />
          </div>
        </div>,
      ])
    }
    return render_item
  }

  renderVariableName = subtype => {
    let subtype_tooltip = 'variableName'
    if (subtype === 'delete') {
      subtype_tooltip = 'variableDeleteName'
    } else if (subtype === 'update') {
      subtype_tooltip = 'variableUpdateName'
    }
    return (
      <div className="form-group row dm-form-group" key="variable_name">
        <LabelWithTooltip
          htmlFor="variable_name"
          className="col-sm-2 pr-0"
          name={`integration.excel.${subtype_tooltip}`}
        />
        <div className="col-sm-5">
          <Field
            type="text"
            name="variable_name"
            className="form-control dm-form-control"
            maxLength="255"
            component={InputField}
          />
        </div>
      </div>
    )
  }

  getSlotNames = text => {
    let slotNames = []
    let m
    const pattern = /\{{2,3}(.+?)\}{2,3}/g
    const pattern_array = /\{\{#(.+?)\}\}.+?\{\{\/.+?\}\}/
    m = pattern_array.exec(text)
    while (m != null) {
      slotNames.push(m[1])
      text = text.replace(pattern_array, '')
      m = pattern_array.exec(text)
    }
    m = pattern.exec(text)
    while (m != null) {
      slotNames.push(m[1])
      m = pattern.exec(text)
    }
    return slotNames
  }

  getSlots = (type, api_subtype, excel_api_subtype, salesforce_api_subtype) => {
    let slots = []
    if (type === 'http') {
      slots = slots.concat(this.getSlotNames(this.props.url))
      this.props.headers.forEach(value => {
        slots = slots.concat(this.getSlotNames(value['value']))
      })
      slots = slots.concat(this.getSlotNames(this.props.request_body))
    }
    if (type === 'google_spreadsheet') {
      slots = this.getSlotNames(this.props.sheet_values.toString())
    }
    if (type === 'email') {
      slots = slots.concat(this.getSlotNames(this.props.subject))
      slots = slots.concat(this.getSlotNames(this.props.body))
      slots = slots.concat(this.getSlotNames(this.props.reply_to_address))
      this.props.to_addresses.forEach(value => {
        slots = slots.concat(this.getSlotNames(value))
      })
      this.props.cc_addresses.forEach(value => {
        slots = slots.concat(this.getSlotNames(value))
      })
      this.props.bcc_addresses.forEach(value => {
        slots = slots.concat(this.getSlotNames(value))
      })
    }
    if (type === 'office365') {
      if (api_subtype === 'get_user') {
        if (this.props.target_type === 'user') {
          slots = slots.concat(this.getSlotNames(this.props.user_id))
        }
      } else if (api_subtype === 'get_users') {
        slots = slots.concat(this.getSlotNames(this.props.search_word))
        const filter_items = this.props.filter_items || []
        filter_items.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      } else if (api_subtype === 'get_event') {
        if (this.props.target_type === 'user') {
          slots = slots.concat(this.getSlotNames(this.props.user_id))
        }
        slots = slots.concat(this.getSlotNames(this.props.event_id))
      } else if (api_subtype === 'get_groups') {
        if (this.props.target_type === 'user') {
          slots = slots.concat(this.getSlotNames(this.props.user_id))
        }
      } else if (api_subtype === 'get_members') {
        slots = slots.concat(this.getSlotNames(this.props.group_id))
      } else if (api_subtype === 'get_events') {
        if (this.props.target_type === 'user') {
          slots = slots.concat(this.getSlotNames(this.props.user_id))
        }
        slots = slots.concat(this.getSlotNames(this.props.start_time))
        slots = slots.concat(this.getSlotNames(this.props.end_time))
      } else if (api_subtype === 'post_event') {
        slots = slots.concat(this.getSlotNames(this.props.title))
        slots = slots.concat(this.getSlotNames(this.props.start_time))
        slots = slots.concat(this.getSlotNames(this.props.end_time))
        slots = slots.concat(this.getSlotNames(this.props.room_id))
        slots = slots.concat(this.getSlotNames(this.props.attendees))
      } else if (api_subtype === 'update_event') {
        slots = slots.concat(this.getSlotNames(this.props.event_id))
        const update_parameters = this.props.update_parameters || []
        update_parameters.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      } else if (api_subtype === 'delete_event') {
        slots = slots.concat(this.getSlotNames(this.props.event_id))
      } else if (api_subtype === 'search_free_room') {
        slots = slots.concat(this.getSlotNames(this.props.start_time))
        slots = slots.concat(this.getSlotNames(this.props.end_time))
        slots = slots.concat(this.getSlotNames(this.props.conference_time))
        slots = slots.concat(this.getSlotNames(this.props.room_id))
        slots = slots.concat(this.getSlotNames(this.props.attendees))
      } else if (api_subtype === 'custom') {
        slots = slots.concat(this.getSlotNames(this.props.url))
        this.props.headers.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
        slots = slots.concat(this.getSlotNames(this.props.request_body))
      }
    }
    if (type === 'excel') {
      slots = slots.concat(this.getSlotNames(this.props.drive_id))
      slots = slots.concat(this.getSlotNames(this.props.file_name))
      slots = slots.concat(this.getSlotNames(this.props.sheet_name))
      this.props.request_parameters.forEach(value => {
        slots = slots.concat(this.getSlotNames(value['value']))
      })
      if (excel_api_subtype === 'insert') {
        this.props.insert_parameters.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      }
      if (excel_api_subtype === 'update') {
        this.props.update_parameters.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      }
    }
    if (type === 'salesforce') {
      if (salesforce_api_subtype === 'custom') {
        slots = slots.concat(this.getSlotNames(this.props.url))
        this.props.headers.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
        slots = slots.concat(this.getSlotNames(this.props.request_body))
      }
      if (salesforce_api_subtype === 'search') {
        this.props.salesforce_conditions.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      }
      if (salesforce_api_subtype === 'insert') {
        this.props.insert_parameters.forEach(value => {
          slots = slots.concat(this.getSlotNames(value['value']))
        })
      }
      if (salesforce_api_subtype === 'keyword') {
        slots = slots.concat(this.getSlotNames(this.props.search_word))
      }
    }
    if (type === 'okbiz') {
      slots = slots.concat('keyword')
    }
    return lodash.uniq(slots)
  }

  renderExecuteForm() {
    const { t } = this.context
    const {
      type,
      isQuery,
      response_map,
      response_body_variable,
      result_variable,
      results,
      errorMessage,
      api_subtype,
      excel_api_subtype,
      salesforce_api_subtype,
      office365OAuthClient,
      salesforceOAuthClient,
    } = this.props
    let slots = this.getSlots(type, api_subtype, excel_api_subtype, salesforce_api_subtype)

    let oauthClient
    if (type === 'office365') {
      oauthClient = office365OAuthClient
    } else if (type === 'salesforce') {
      oauthClient = salesforceOAuthClient
    }

    return (
      <IntegrationExecute
        type={type}
        excel_api_subtype={excel_api_subtype}
        salesforce_api_subtype={salesforce_api_subtype}
        title={t('integration.execute.title')}
        isQuery={isQuery}
        slots={slots}
        response_map={response_map}
        response_body_variable={response_body_variable}
        result_variable={result_variable}
        results={results}
        oauthClient={oauthClient}
        oauthStatus={this.state.oauthStatus}
        oauthStatusMessage={this.state.oauthStatusMessage}
        errorMessage={errorMessage}
        isExecuting={this.props.isExecuting}
        onExecute={this.handleExecute}
        onClose={this.handleClose}
        onOAuthRequest={this.handleOAuthRequest}
        api_subtype={api_subtype}
      />
    )
  }
}

const IntegrationEditForm = reduxForm({
  form: 'IntegrationEdit',
  enableReinitialize: true,
  validate,
  shouldError: () => true,
})(IntegrationEdit)

const selector = formValueSelector('IntegrationEdit')

export const mapStateToProps = (state, props) => {
  const applications = lodash.filter(state.entities.applications)
  const bot = state.entities.bots[props.params.bot_id] || {}

  const oauthIds = state.pagination.oauth_clients.ids || []
  const oauthClients = oauthIds.map(id => state.entities.oauth_clients[id])
  const office365OAuthClient =
    lodash.find(oauthClients, { type: 'office365', id: parseInt(bot.oauth_client_id_office365, 10) }) || {}
  const salesforceOAuthClient =
    lodash.find(oauthClients, { type: 'salesforce', id: parseInt(bot.oauth_client_id_salesforce, 10) }) || {}

  const defaultValues = {
    type: '',
    // http
    method: 'get',
    response_type: 'json',
    headers: [],
    response_map: [],
    user_status_code_map: [
      {
        status_code: '100-399',
        result: 'success',
      },
      {
        status_code: '400-599',
        result: 'failed',
      },
    ],
    error_status_code_map: {},
    // google_spreadsheet
    sheet_values: [],
    // email
    from: Config.emailSender,
    to_addresses: [],
    cc_addresses: [],
    bcc_addresses: [],
    // line_switcher
    sub_type: 'switch',
    as_json_note: true,
    // office365, salesforce
    api_subtype: 'get_user',
    salesforce_api_subtype: 'custom',
    target_type: office365OAuthClient.use_preauthentication ? 'user' : 'me',
    activity_domain: 'unrestricted',
    filter_items: [],
    response_map_get_user: [
      { from: 'id', to: i18next.t('integration.office365.responseMapTemplate.id') },
      {
        from: 'userPrincipalName',
        to: i18next.t('integration.office365.responseMapTemplate.userPrincipalName'),
      },
      { from: 'userType', to: i18next.t('integration.office365.responseMapTemplate.userType') },
      { from: 'mail', to: i18next.t('integration.office365.responseMapTemplate.mail') },
      { from: 'surname', to: i18next.t('integration.office365.responseMapTemplate.surname') },
      { from: 'givenName', to: i18next.t('integration.office365.responseMapTemplate.givenName') },
      { from: 'displayName', to: i18next.t('integration.office365.responseMapTemplate.displayName') },
      { from: 'companyName', to: i18next.t('integration.office365.responseMapTemplate.companyName') },
      { from: 'department', to: i18next.t('integration.office365.responseMapTemplate.department') },
      { from: 'jobTitle', to: i18next.t('integration.office365.responseMapTemplate.jobTitle') },
      { from: 'officeLocation', to: i18next.t('integration.office365.responseMapTemplate.officeLocation') },
      {
        from: 'businessPhones[0]',
        to: i18next.t('integration.office365.responseMapTemplate.businessPhones'),
      },
      { from: 'mobilePhone', to: i18next.t('integration.office365.responseMapTemplate.mobilePhone') },
    ],
    response_map_get_users: [
      { from: 'value', to: i18next.t('integration.office365.responseMapTemplate.userList') },
    ],
    response_map_get_groups: [
      { from: 'value', to: i18next.t('integration.office365.responseMapTemplate.groupList') },
    ],
    response_map_get_members: [
      { from: 'value', to: i18next.t('integration.office365.responseMapTemplate.groupMemberList') },
    ],
    response_map_get_event: [
      { from: 'id', to: i18next.t('integration.office365.responseMapTemplate.id') },
      { from: 'subject', to: i18next.t('integration.office365.responseMapTemplate.subject') },
      { from: 'start.dateTime', to: i18next.t('integration.office365.responseMapTemplate.start') },
      { from: 'end.dateTime', to: i18next.t('integration.office365.responseMapTemplate.end') },
      { from: 'attendees', to: i18next.t('integration.office365.responseMapTemplate.attendees') },
      { from: 'organizer', to: i18next.t('integration.office365.responseMapTemplate.organizer') },
      { from: 'webLink', to: i18next.t('integration.office365.responseMapTemplate.webLink') },
    ],
    response_map_get_events: [
      { from: 'value', to: i18next.t('integration.office365.responseMapTemplate.value') },
    ],
    response_map_post_event: [
      { from: 'id', to: i18next.t('integration.office365.responseMapTemplate.id') },
      { from: 'subject', to: i18next.t('integration.office365.responseMapTemplate.subject') },
      { from: 'start.dateTime', to: i18next.t('integration.office365.responseMapTemplate.start') },
      { from: 'end.dateTime', to: i18next.t('integration.office365.responseMapTemplate.end') },
      { from: 'attendees', to: i18next.t('integration.office365.responseMapTemplate.attendees') },
      { from: 'organizer', to: i18next.t('integration.office365.responseMapTemplate.organizer') },
      { from: 'webLink', to: i18next.t('integration.office365.responseMapTemplate.webLink') },
    ],
    response_map_update_event: [
      { from: 'id', to: i18next.t('integration.office365.responseMapTemplate.id') },
      { from: 'subject', to: i18next.t('integration.office365.responseMapTemplate.subject') },
      { from: 'start.dateTime', to: i18next.t('integration.office365.responseMapTemplate.start') },
      { from: 'end.dateTime', to: i18next.t('integration.office365.responseMapTemplate.end') },
      { from: 'attendees', to: i18next.t('integration.office365.responseMapTemplate.attendees') },
      { from: 'organizer', to: i18next.t('integration.office365.responseMapTemplate.organizer') },
      { from: 'webLink', to: i18next.t('integration.office365.responseMapTemplate.webLink') },
    ],
    response_map_search_free_room: [
      {
        from: 'meetingTimeSuggestions',
        to: i18next.t('integration.office365.responseMapTemplate.meetingTimeSuggestions'),
      },
    ],
    response_map_get_access_token: [
      { from: 'accessToken', to: i18next.t('integration.office365.responseMapTemplate.accessToken') },
    ],
    response_map_custom: [],
    // excel
    excel_api_subtype: 'search',
    use_or_operator: 'false',
    header_columns: null,
    excel_columns: [],
    request_columns: [],
    request_orders: [],
    header_error_message: null,
    request_parameters: [],
    insert_parameters: [],
    update_parameters: [],
    // salesforce
    salesforce_orders: [],
    salesforce_conditions: [],
    salesforce_columns: [],
    object_name: '',
    picklist_column: '',
    response_map_salesforce_insert: [
      { from: 'id', to: i18next.t('integration.salesforce.responseMapTemplate.id') },
      { from: 'success', to: i18next.t('integration.salesforce.responseMapTemplate.success') },
    ],
    // okbiz
    sort_type: 'sort_keyword',
    // tool
    parameters: {},
  }
  const integration = {
    ...defaultValues,
    ...state.entities.integrations[props.params.id],
  }
  if (props.params.id) {
    if (integration.type === 'http') {
      if (integration.use_status_code_handling) {
        integration.user_status_code_map = lodash.filter(integration.status_code_map, {
          type: 'user_setting',
        })
        integration.error_status_code_map = lodash.find(integration.status_code_map, { type: 'system_error' })
      }
    }

    if (integration.type === 'office365') {
      if (integration.api_subtype === 'get_user')
        integration.response_map_get_user = [...integration.response_map]
      if (integration.api_subtype === 'get_users')
        integration.response_map_get_users = [...integration.response_map]
      if (integration.api_subtype === 'get_groups')
        integration.response_map_get_groups = [...integration.response_map]
      if (integration.api_subtype === 'get_members')
        integration.response_map_get_members = [...integration.response_map]
      if (integration.api_subtype === 'get_event')
        integration.response_map_get_event = [...integration.response_map]
      if (integration.api_subtype === 'get_events')
        integration.response_map_get_events = [...integration.response_map]
      if (integration.api_subtype === 'post_event')
        integration.response_map_post_event = [...integration.response_map]
      if (integration.api_subtype === 'update_event')
        integration.response_map_update_event = [...integration.response_map]
      if (integration.api_subtype === 'search_free_room')
        integration.response_map_search_free_room = [...integration.response_map]
      if (integration.api_subtype === 'get_access_token')
        integration.response_map_get_access_token = [...integration.response_map]
      if (integration.api_subtype === 'custom')
        integration.response_map_custom = [...integration.response_map]
    }

    if (integration.type === 'excel') {
      if (integration.excel_api_subtype === 'search') {
        let excel_columns = []
        for (var column in integration.header_columns) {
          const columns_dict = {}
          columns_dict['name'] = integration.header_columns[column]
          columns_dict['label'] = integration.header_columns[column]
          excel_columns.push(columns_dict)
        }
        integration.excel_columns = [...excel_columns]
        if (integration.header_columns && integration.header_columns.length > 0) {
          if (!integration.request_columns || integration.request_columns.length < 1) {
            integration.request_columns = [...integration.header_columns]
          }
        }
      }
      if (integration.excel_api_subtype !== 'insert') {
        if (typeof integration.use_or_operator === 'boolean') {
          if (integration.use_or_operator) {
            integration.use_or_operator = 'true'
          } else {
            integration.use_or_operator = 'false'
          }
        }
      } else if (integration.header_columns && integration.header_columns.length > 0) {
        let parameters = []
        integration.header_columns.forEach(column => {
          let value = ''
          const insert_parameter = lodash.first(lodash.filter(integration.insert_parameters, { id: column }))
          if (insert_parameter) {
            value = insert_parameter.value
          }
          parameters.push({ id: column, value: value })
        })
        integration.insert_parameters = parameters
      }
    }
    if (integration.type === 'salesforce') {
      integration.salesforce_api_subtype = integration.api_subtype
      if (integration.salesforce_api_subtype === 'search') {
        if (typeof integration.use_or_operator === 'boolean') {
          if (integration.use_or_operator) {
            integration.use_or_operator = 'true'
          } else {
            integration.use_or_operator = 'false'
          }
        }
        integration.salesforce_columns = [...integration.request_columns]
        integration.salesforce_orders = [...integration.request_orders]
        integration.salesforce_conditions = [...integration.request_parameters]
      }
      if (integration.salesforce_api_subtype === 'keyword') {
        integration.salesforce_columns = [...integration.request_columns]
      }
      if (integration.salesforce_api_subtype === 'insert') {
        integration.salesforce_orders = [...integration.request_orders]
        integration.response_map_salesforce_insert = [...integration.response_map]
      }
    }
    if (integration.is_conference_room) {
      integration.organizer_room = 'conference_room'
    } else if (integration.is_organizer) {
      integration.organizer_room = 'organizer'
    } else {
      integration.organizer_room = 'none'
    }
  }
  if (integration.type === 'okbiz') {
    if (integration.site_id) {
      integration.use_site_id = true
    }
  }
  if (integration.type === 'tool') {
    integration.type = `tool:${integration.sub_type}`
  }

  convertSystemVariablesOfIntegration(integration, false)
  const isQuery = state.integration.isQuery

  const ids = state.pagination.email_addresses.ids || []
  const emailAddresses = lodash.filter(
    ids.map(id => state.entities.email_addresses[id]),
    {
      is_confirmed: true,
    }
  )

  return {
    bot: bot,
    url: selector(state, 'url'),
    request_body: selector(state, 'request_body'),
    isFetching: isFetching(state),
    isExecuting: state.integration.isExecuting,
    isQuery: isQuery,
    results: state.integration.results || undefined,
    errorMessage: state.integration.errorMessage,
    oauthErrorMessage: state.integration.oauthErrorMessage,
    initialValues: integration,
    applications: applications,
    headers: selector(state, 'headers'),
    response_type: selector(state, 'response_type'),
    response_map: selector(state, 'response_map') || [],
    response_body_variable: selector(state, 'response_body_variable'),
    use_status_code_handling: selector(state, 'use_status_code_handling'),
    result_variable: selector(state, 'result_variable'),
    search_word: selector(state, 'search_word'),
    filter_items: selector(state, 'filter_items'),
    response_map_get_user: selector(state, 'response_map_get_user'),
    response_map_get_users: selector(state, 'response_map_get_users'),
    response_map_get_groups: selector(state, 'response_map_get_groups'),
    response_map_get_members: selector(state, 'response_map_get_members'),
    response_map_get_event: selector(state, 'response_map_get_event'),
    response_map_get_events: selector(state, 'response_map_get_events'),
    response_map_post_event: selector(state, 'response_map_post_event'),
    response_map_update_event: selector(state, 'response_map_update_event'),
    response_map_search_free_room: selector(state, 'response_map_search_free_room'),
    response_map_get_access_token: selector(state, 'response_map_get_access_token'),
    response_map_custom: selector(state, 'response_map_custom'),
    sheet_values: selector(state, 'sheet_values'),
    subject: selector(state, 'subject'),
    body: selector(state, 'body'),
    type: selector(state, 'type'),
    api_subtype: selector(state, 'api_subtype'),
    salesforce_api_subtype: selector(state, 'salesforce_api_subtype'),
    excel_api_subtype: selector(state, 'excel_api_subtype'),
    target_type: selector(state, 'target_type'),
    user_id: selector(state, 'user_id'),
    group_id: selector(state, 'group_id'),
    event_id: selector(state, 'event_id'),
    start_time: selector(state, 'start_time'),
    end_time: selector(state, 'end_time'),
    title: selector(state, 'title'),
    room_id: selector(state, 'room_id'),
    attendees: selector(state, 'attendees'),
    conference_time: selector(state, 'conference_time'),
    drive_id: selector(state, 'drive_id'),
    file_name: selector(state, 'file_name'),
    sheet_name: selector(state, 'sheet_name'),
    request_parameters: selector(state, 'request_parameters'),
    insert_parameters: selector(state, 'insert_parameters'),
    update_parameters: selector(state, 'update_parameters'),
    header_columns: integration.header_columns,
    request_columns: selector(state, 'request_columns') || [],
    picklist_column: selector(state, 'picklist_column'),
    excel_columns: integration.excel_columns,
    header_error_message: integration.header_error_message,
    emailAddresses: emailAddresses,
    office365OAuthClient,
    salesforceOAuthClient,
    to_addresses: selector(state, 'to_addresses'),
    cc_addresses: selector(state, 'cc_addresses'),
    bcc_addresses: selector(state, 'bcc_addresses'),
    reply_to_address: selector(state, 'reply_to_address'),
    salesforceObjects: state.integration.salesforceObjects,
    salesforceObjectDetails: state.integration.salesforceObjectDetails,
    object_name: selector(state, 'object_name'),
    salesforce_orders: selector(state, 'salesforce_orders'),
    salesforce_conditions: selector(state, 'salesforce_conditions'),
    salesforce_columns: selector(state, 'salesforce_columns'),
    use_distinct: selector(state, 'use_distinct'),
    response_map_salesforce_insert: selector(state, 'response_map_salesforce_insert'),
    // OKBiz
    use_site_id: selector(state, 'use_site_id'),
    use_test_environment: selector(state, 'use_test_environment'),
  }
}

export default connect(mapStateToProps)(IntegrationEditForm)
