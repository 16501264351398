import 'element-closest-polyfill'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import LoginUser from './LoginUser'
import { convertReminingTime } from '../../helpers/remainingTime'

class Header extends Component {
  static contextTypes = {
    store: PropTypes.object,
    t: PropTypes.func.isRequired,
  }

  static propTypes = {
    session: PropTypes.object,
    account: PropTypes.object,
    onLogout: PropTypes.func.isRequired,
    onToggleDrawerMenu: PropTypes.func,
    onStartTour: PropTypes.func.isRequired,
    operatorBadge: PropTypes.number,
  }

  constructor() {
    super()
    this.state = {
      isOpened: false,
    }
  }

  componentDidMount = () => {
    document.addEventListener('click', this.closeProfile)
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.closeProfile)
  }

  closeProfile = e => {
    if (this.state.isOpened && !e.target.closest('#profile-button')) {
      this.setState({ isOpened: false })
    }
  }

  startTour = e => {
    e.preventDefault()
    this.props.onStartTour()
  }

  renderRemainingTime() {
    const { t } = this.context
    const { account } = this.props
    if (!account) return

    let accountInfo = t('common.remainingTime.title', { plan: t(`common.plans.${account.plan}`) })

    if (account.remaining_time != null) {
      const remainingTime = convertReminingTime(this.context, account.remaining_time)

      accountInfo = `${accountInfo} (${remainingTime})`
    }

    return (
      <li className="remaining-time">
        <div>
          <div>{account.company_name}</div>
          <div>{accountInfo}</div>
        </div>
      </li>
    )
  }

  render() {
    const { t } = this.context
    const { session, onLogout, onToggleDrawerMenu } = this.props
    const { isOpened } = this.state
    const isNavButton = session.token && onToggleDrawerMenu

    const brandLinkTo = session.token ? '/' : '/login'

    return (
      <div className="dm-navbar">
        <div className="container">
          <div className="navbar-header">
            <span className={`navbar-brand ${session.token ? '' : 'always'}`}>
              <Link to={brandLinkTo}>{t('common.title')}</Link>
            </span>
            <div className={`hidden-sm-up ${isNavButton ? '' : 'hidden'}`}>
              <button href=".dm-sidebar" className="dm-icon-nav" onClick={onToggleDrawerMenu} />
            </div>
          </div>

          {session.loaded && (
            <div className="navbar-main">
              <ul className="nav navbar-nav navbar-right">
                {this.renderRemainingTime()}
                <li className="dm-nav-icon">
                  <a
                    href="https://guide.dialogplay.jp/hc/ja"
                    target="_blank"
                    rel="noopener noreferrer"
                    type="button"
                    title={t('common.document')}
                  >
                    <span className="dm-icon-document">Guide</span>
                  </a>
                </li>
                <li className="dm-nav-icon">
                  <button type="button" title={t('common.help')} onClick={this.startTour}>
                    <span className="dm-icon-help">Help</span>
                  </button>
                </li>
                <li className={isOpened ? 'dm-nav-icon open' : 'dm-nav-icon'}>
                  <button
                    type="button"
                    id="profile-button"
                    className="dropdown-toggle"
                    onClick={() => {
                      this.setState({ isOpened: !isOpened })
                    }}
                  >
                    <span className="dm-icon-human">Profile</span>
                    <span className="icon icon-arrows-down" />
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <div className="username">{session.name}</div>
                    </li>
                    <li>
                      <hr />
                    </li>
                    <li>
                      <Link to={`/users/${session.id}`}>{t('menu.profile')}</Link>
                    </li>
                    <li>
                      <Link to={'/change_password'}>{t('menu.changePassword')}</Link>
                    </li>
                    <li>
                      <hr />
                    </li>
                    <LoginUser onLogout={onLogout} />
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Header
