import lodash from 'lodash'
import ipaddr from 'ipaddr.js'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import url from 'url'
import { connect } from 'react-redux'
import {
  formValueSelector,
  Field,
  FieldArray,
  reduxForm,
  propTypes,
  getFormValues,
  isDirty,
  SubmissionError,
} from 'redux-form'
import { isFQDN, isUUID } from 'validator'

// components
import { EmbeddedTagEdit } from '../../components/application/EmbeddedTagEdit'
import {
  CheckboxField,
  InputField,
  TextAreaField,
  SelectField,
  FileField,
} from '../../components/common/fields/FormFields'
import IncrementableTable from '../../components/common/IncrementableTable'
import { IconFieldWithDialog } from '../../components/common/IconFieldWithDialog'
import Loader from '../../components/common/Loader'
import Tooltip from '../../components/common/Tooltip'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import DeleteConfirm from '../../components/common/DeleteConfirm'
import PublishNotice from './PublishNotice'

// actions
import { addNotice } from '../../actions/notice'
import { fetchBots, fetchBot } from '../../actions/bot'
import {
  fetchApplication,
  updateApplication,
  createApplication,
  abortApplication,
  abortReservation,
  deleteApplication,
  reissueApplicationToken,
  reissueNotificationApiKey,
  reissuePnpApiKey,
  reissueWebAccountLinkApiKey,
  updateEmbeddedTag,
  publishLauncherImage,
  fetchSpMetadata,
} from '../../actions/application'

// helpers
import Config from '../../helpers/config'
import { downloadBlob } from '../../helpers/download'
import { getCredentials } from '../../helpers/sessionHelper'
import { isFetching } from '../../helpers/selector'
import { isPermitted, isPermittedCredentials } from '../../helpers/permission'
import cancelUrl from '../../helpers/cancelurl'
import { convertToFormData } from '../../helpers/request'
import { hasValidationError, isIncludingInvalidSymbols } from '../../helpers/validation'

const defaultColors = {
  header: {
    bg: '#144061',
    font: '#ffffff',
  },
  background: {
    bg: '#e9eff5',
    font: '#9e9e9e',
  },
  botBalloon: {
    bg: '#caeaff',
    font: '#141414',
  },
  userBalloon: {
    bg: '#ffffff',
    font: '#141414',
  },
  sendButton: {
    bg: '#1d5c8b',
    font: '#ffffff',
  },
}

const isValidCIDR = address => {
  try {
    ipaddr.parseCIDR(address)
    return true
  } catch (e) {
    return false
  }
}

const validate = (data, props) => {
  const errors = {}
  if (!data.name) {
    errors.name = 'validate.required'
  } else {
    if (lodash.includes(['skype_for_business', 'teams', 'direct_line'], data.platform)) {
      if (data.name.length > 42) {
        errors.name = { id: 'validate.exceededMaxLength', values: { length: 42 } }
      }
      if (/[<>]/.test(data.name)) {
        errors.name = 'validate.invalidName'
      }
    } else if (data.name.length > 255) {
      errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    }
  }
  if (!data.bot_id) {
    errors.bot_id = 'validate.required'
  }

  if (data.constant_definitions) {
    const constant_definition_names = []
    const constantDefinitionErrors = data.constant_definitions.map(constant_definition => {
      const error = {}
      if (!constant_definition.name) {
        error.name = 'validate.required'
      } else {
        if (constant_definition.name.length > 30) {
          error.name = { id: 'validate.exceededMaxLength', values: { length: 30 } }
        } else if (isIncludingInvalidSymbols(constant_definition.name)) {
          error.name = 'validate.invalidSymbolIncluded'
        } else if (lodash.includes(constant_definition_names, constant_definition.name)) {
          error.name = 'validate.nameDuplicated'
        } else {
          constant_definition_names.push(constant_definition.name)
        }
      }

      if (!constant_definition.value) {
        if (!constant_definition.is_secret) {
          error.value = 'validate.required'
        }
      } else {
        if (constant_definition.value.length > 1000) {
          error.value = { id: 'validate.exceededMaxLength', values: { length: 1000 } }
        }
      }

      return error
    })

    if (!lodash.every(constantDefinitionErrors, lodash.isEmpty)) {
      errors.constant_definitions = constantDefinitionErrors
    }
  }

  if (data.notification_api_service.use_allowed_addresses) {
    errors.notification_api_service = {}
    if (!data.notification_api_service.allowed_addresses) {
      errors.notification_api_service.allowed_addresses = 'validate.required'
    } else if (data.notification_api_service.allowed_addresses.length > 10000) {
      errors.notification_api_service.allowed_addresses = {
        id: 'validate.exceededMaxLength',
        values: { length: 10000 },
      }
    } else {
      const addresses = data.notification_api_service.allowed_addresses.trim().split(/\s*,\s*/)
      if (!lodash.every(addresses, address => ipaddr.isValid(address) || isValidCIDR(address))) {
        errors.notification_api_service.allowed_addresses = 'validate.invalidAddress'
      }
    }
  }

  if (data.has_reservation && data.reserved_at) {
    const error = {}
    if (!data.reserved_at.date) {
      error.date = 'validate.required'
    }
    if (!data.reserved_at.time) {
      error.time = 'validate.required'
    }

    if (data.reserved_at.date && data.reserved_at.time) {
      const reserved_at = moment.tz(data.reserved_at.date + ' ' + data.reserved_at.time, props.timezone)
      if (reserved_at.isBefore(moment())) {
        error.date = 'validate.pastDate'
      }
    }

    if (!lodash.isEmpty(error)) {
      errors.reserved_at = error
    }
  }

  if (data.platform === 'web' || data.platform === 'line') {
    if (data.use_icon_for_each_message) {
      const positiveIconIsNotExist = !(data.positive_icon_url || data.positive_icon_file)
      const negativeIconIsNotExist = !(data.negative_icon_url || data.negative_icon_file)
      if (positiveIconIsNotExist && negativeIconIsNotExist) {
        errors.icon_each_message = 'validate.iconForEachMessageRequired'
      }
    }
  }

  if (data.platform === 'web' || data.platform === 'direct_line' || data.platform === 'api_v1') {
    if (data.use_allowed_addresses) {
      if (!data.allowed_addresses) {
        errors.allowed_addresses = 'validate.required'
      } else if (data.allowed_addresses.length > 10000) {
        errors.allowed_addresses = { id: 'validate.exceededMaxLength', values: { length: 10000 } }
      } else {
        const addresses = data.allowed_addresses.trim().split(/\s*,\s*/)
        if (!lodash.every(addresses, address => ipaddr.isValid(address) || isValidCIDR(address))) {
          errors.allowed_addresses = 'validate.invalidAddress'
        }
      }
    }

    //  WebAccountLink API
    if (data.web_account_link_api_service.use_allowed_addresses) {
      errors.web_account_link_api_service = {}
      if (!data.web_account_link_api_service.allowed_addresses) {
        errors.web_account_link_api_service.allowed_addresses = 'validate.required'
      } else if (data.web_account_link_api_service.allowed_addresses.length > 10000) {
        errors.web_account_link_api_service.allowed_addresses = {
          id: 'validate.exceededMaxLength',
          values: { length: 10000 },
        }
      } else {
        const addresses = data.web_account_link_api_service.allowed_addresses.trim().split(/\s*,\s*/)
        if (!lodash.every(addresses, address => ipaddr.isValid(address) || isValidCIDR(address))) {
          errors.web_account_link_api_service.allowed_addresses = 'validate.invalidAddress'
        }
      }
    }
  }

  if (data.platform === 'web') {
    if (data.authentication_type === 'oidc') {
      if (!data.oidc_idp_client_id) {
        errors.oidc_idp_client_id = 'validate.required'
      }
      if (!data.id && !data.oidc_idp_client_secret) {
        errors.oidc_idp_client_secret = 'validate.required'
      }
      if (!data.oidc_idp_configuration_url) {
        errors.oidc_idp_configuration_url = 'validate.required'
      }
    }
  }

  if (data.platform === 'line') {
    if (!data.id && !data.channel_access_token) {
      errors.channel_access_token = 'validate.required'
    }
    if (props.application.channel_id && !data.channel_access_token) {
      // Application whose platform has been changed from LINE Switcher API to LINE by system
      errors.channel_access_token = 'validate.required'
    }
    if (data.channel_access_token) {
      if (data.channel_access_token.length > 255) {
        errors.channel_access_token = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
      if (!/^[0-9a-zA-Z+/=]*$/.test(data.channel_access_token)) {
        errors.channel_access_token = 'validate.invalid'
      }
    }
    if (!data.id && !data.channel_secret) {
      errors.channel_secret = 'validate.required'
    }
    if (data.channel_secret) {
      if (data.channel_secret.length > 32) {
        errors.channel_secret = { id: 'validate.exceededMaxLength', values: { length: 32 } }
      }
      if (!/^[0-9a-f]*$/.test(data.channel_secret)) {
        errors.channel_secret = 'validate.invalid'
      }
    }
    if (data.use_switcher_api) {
      // Service code
      if (!data.service_code) {
        errors.service_code = 'validate.required'
      } else if (data.service_code.length > 36) {
        errors.service_code = { id: 'validate.exceededMaxLength', values: { length: 36 } }
      } else if (!isUUID(data.service_code)) {
        errors.service_code = 'validate.invalid'
      }
      // Switcher Secret
      if (!props.application.use_switcher_api && !data.switcher_secret) {
        errors.switcher_secret = 'validate.required'
      }
      if (data.switcher_secret) {
        if (data.switcher_secret.length > 32) {
          errors.switcher_secret = { id: 'validate.exceededMaxLength', values: { length: 32 } }
        }
        if (!/^[0-9a-f]{32}$/.test(data.switcher_secret)) {
          errors.switcher_secret = 'validate.invalid'
        }
      }
    }
    const accountLinkError = {}
    if (!data.external_service.login_url) {
      if (data.use_account_link) {
        accountLinkError.login_url = 'validate.required'
      }
    } else {
      const result = url.parse(data.external_service.login_url)
      if (!result.protocol || !result.slashes || !result.host) {
        accountLinkError.login_url = 'validate.invalidURL'
      }
    }
    if (!data.external_service.fetch_user_id_url) {
      if (data.use_account_link) {
        accountLinkError.fetch_user_id_url = 'validate.required'
      }
    } else {
      const result = url.parse(data.external_service.fetch_user_id_url)
      if (!result.protocol || !result.slashes || !result.host) {
        accountLinkError.fetch_user_id_url = 'validate.invalidURL'
      }
    }
    if (data.external_service.unlink_url) {
      const result = url.parse(data.external_service.unlink_url)
      if (!result.protocol || !result.slashes || !result.host) {
        accountLinkError.unlink_url = 'validate.invalidURL'
      }
    }
    errors.external_service = accountLinkError

    if (data.pnp_api_service.use_allowed_addresses) {
      errors.pnp_api_service = {}
      if (!data.pnp_api_service.allowed_addresses) {
        errors.pnp_api_service.allowed_addresses = 'validate.required'
      } else if (data.pnp_api_service.allowed_addresses.length > 10000) {
        errors.pnp_api_service.allowed_addresses = {
          id: 'validate.exceededMaxLength',
          values: { length: 10000 },
        }
      } else {
        const addresses = data.pnp_api_service.allowed_addresses.trim().split(/\s*,\s*/)
        if (!lodash.every(addresses, address => ipaddr.isValid(address) || isValidCIDR(address))) {
          errors.pnp_api_service.allowed_addresses = 'validate.invalidAddress'
        }
      }
    }
  }
  if (data.platform === 'line_works') {
    if (!data.line_works_api_id) {
      errors.line_works_api_id = 'validate.required'
    } else if (data.line_works_api_id.length > 13) {
      errors.line_works_api_id = { id: 'validate.exceededMaxLength', values: { length: 13 } }
    }
    if (!data.line_works_server_api_consumer_key) {
      if (!data.id) {
        errors.line_works_server_api_consumer_key = 'validate.required'
      }
    } else if (data.line_works_server_api_consumer_key.length > 20) {
      errors.line_works_server_api_consumer_key = { id: 'validate.exceededMaxLength', values: { length: 20 } }
    }
    if (!data.line_works_server_id) {
      errors.line_works_server_id = 'validate.required'
    } else if (data.line_works_server_id.length > 32) {
      errors.line_works_server_id = { id: 'validate.exceededMaxLength', values: { length: 32 } }
    } else if (!/^[0-9a-f]{32}$/.test(data.line_works_server_id)) {
      errors.line_works_server_id = 'validate.invalid'
    }
    if (!data.line_works_server_authentication_key) {
      if (!data.id) {
        errors.line_works_server_authentication_key = 'validate.required'
      }
    } else if (!lodash.includes(data.line_works_server_authentication_key, 'BEGIN PRIVATE KEY')) {
      errors.line_works_server_authentication_key = 'validate.invalid'
    }
  }
  if (data.platform === 'line_works_v2') {
    if (!data.line_works_client_id) {
      errors.line_works_client_id = 'validate.required'
    } else if (data.line_works_client_id.length > 20) {
      errors.line_works_client_id = { id: 'validate.exceededMaxLength', values: { length: 20 } }
    }
    if (!data.line_works_client_secret) {
      if (!data.id) {
        errors.line_works_client_secret = 'validate.required'
      }
    } else if (data.line_works_client_secret.length > 10) {
      errors.line_works_client_secret = { id: 'validate.exceededMaxLength', values: { length: 10 } }
    }
    if (!data.line_works_service_account) {
      errors.line_works_service_account = 'validate.required'
    } else if (data.line_works_service_account.length > 255) {
      errors.line_works_service_account = { id: 'validate.exceededMaxLength', values: { length: 255 } }
    } else if (!/^[\w][\w-.]+@[^@]+$/.test(data.line_works_service_account)) {
      errors.line_works_service_account = 'validate.invalid'
    }
    if (!data.line_works_private_key) {
      if (!data.id) {
        errors.line_works_private_key = 'validate.required'
      }
    } else if (!lodash.includes(data.line_works_private_key, 'BEGIN PRIVATE KEY')) {
      errors.line_works_private_key = 'validate.invalid'
    }
    if (!data.line_works_bot_secret) {
      if (!data.id) {
        errors.line_works_bot_secret = 'validate.required'
      }
    } else if (data.line_works_bot_secret.length > 30) {
      errors.line_works_bot_secret = { id: 'validate.exceededMaxLength', values: { length: 30 } }
    }
  }
  if (data.platform === 'slack') {
    if (!data.id && !data.access_token) {
      errors.access_token = 'validate.required'
    }
  }
  if (lodash.includes(['azure_bot_service', 'skype_for_business', 'teams', 'direct_line'], data.platform)) {
    if (data.platform === 'azure_bot_service') {
      if (!data.azure_bot_service_application_id) {
        errors.azure_bot_service_application_id = 'validate.required'
      }
      if (!data.id && !data.azure_bot_service_password) {
        errors.azure_bot_service_password = 'validate.required'
      }
    }
    if (data.azure_bot_service_application_id) {
      if (data.azure_bot_service_application_id.length > 36) {
        errors.azure_bot_service_application_id = { id: 'validate.exceededMaxLength', values: { length: 36 } }
      }
      if (
        !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
          data.azure_bot_service_application_id
        )
      ) {
        errors.azure_bot_service_application_id = 'validate.invalid'
      }
    }
    if (data.azure_bot_service_password) {
      if (data.azure_bot_service_password.length > 255) {
        errors.azure_bot_service_password = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
      if (!/^[0-9a-zA-Z!-/:-@¥[-`{-~]*$/.test(data.azure_bot_service_password)) {
        errors.azure_bot_service_password = 'validate.invalid'
      }
    }
  }
  if (data.platform === 'teams') {
    if (data.use_allowed_domains) {
      if (!data.allowed_domains) {
        errors.allowed_domains = 'validate.required'
      } else if (data.allowed_domains.length > 10000) {
        errors.allowed_domains = {
          id: 'validate.exceededMaxLength',
          values: { length: 10000 },
        }
      } else {
        const domains = data.allowed_domains.trim().split(/\s*,\s*/)
        if (lodash.some(domains, domain => domain.match(/\s/))) {
          errors.allowed_domains = 'validate.invalidDomain'
        }
      }
    }
  }
  if (data.platform === 'mobilus') {
    if (!data.id && !data.mobilus_user_id) {
      errors.mobilus_user_id = 'validate.required'
    }
    if (!data.id && !data.mobilus_api_key) {
      errors.mobilus_api_key = 'validate.required'
    }
    if (data.mobilus_domain_type === 'custom' && !data.mobilus_push_domain) {
      errors.mobilus_push_domain = 'validate.required'
    }
    if (data.mobilus_tenant_id) {
      if (data.mobilus_tenant_id.length > 255) {
        errors.mobilus_tenant_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
    }
    if (data.mobilus_user_id) {
      if (data.mobilus_user_id.length > 255) {
        errors.mobilus_user_id = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
    }
    if (data.mobilus_api_key) {
      if (data.mobilus_api_key.length > 255) {
        errors.mobilus_api_key = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
    }
    if (data.mobilus_domain_type === 'custom' && data.mobilus_push_domain) {
      if (data.mobilus_push_domain.length > 255) {
        errors.mobilus_push_domain = { id: 'validate.exceededMaxLength', values: { length: 255 } }
      }
      if (!isFQDN(data.mobilus_push_domain)) {
        errors.mobilus_push_domain = 'validate.invalidDomain'
      }
    }
  }
  if (data.platform === 'hangouts') {
    if (!data.project_no) {
      errors.project_no = 'validate.required'
    } else if (data.project_no.length > 12) {
      errors.project_no = { id: 'validate.exceededMaxLength', values: { length: 12 } }
    }
  }

  if (!lodash.isNumber(data.auto_popup_seconds)) {
    if (!/^\d+$/.test(data.auto_popup_seconds)) {
      errors.auto_popup_seconds = 'validate.invalid'
    }
  }
  if (3600 < data.auto_popup_seconds) {
    errors.auto_popup_seconds = 'validate.invalidPopupSeconds'
  }

  return errors
}

export class ApplicationEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    application: PropTypes.object,
    formValues: PropTypes.object,
    bots: PropTypes.array,
    currentBot: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    platform: PropTypes.string,
    isFetching: PropTypes.bool,
    isDirtyCustomize: PropTypes.bool,
    mobilus_domain_type: PropTypes.string,
    timezone: PropTypes.string.isRequired,
  }

  constructor() {
    super()
    this.state = {
      image_src: '',
      default_icon: false,
      use_positive_icon: false,
      positiveIconUrl: '',
      use_negative_icon: false,
      negativeIconUrl: '',
      isInvalidImage: false,
      inDeleting: false,
      isDeleteConfirmOpened: false,
      deleteConfirmErrorMessage: '',
      launcherImageState: {
        image_src: '',
        isInvalidImage: false,
        isDirtyImage: false,
      },
      isOpenIdpField: false,
    }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const { dispatch } = this.props

    this.refreshApplication()
    dispatch(fetchBots(state.session.token))
    cancelUrl.setRouteLeaveHook(this, [], ['colors'])
  }

  componentDidUpdate(prevProps) {
    const state = this.context.store.getState()
    const { dispatch } = this.props

    if (prevProps.params.id !== this.props.params.id) {
      // Clear previous data
      clearTimeout(this.timer)
      this.props.reset()
      this.setState({
        default_icon: true,
        image_src: '',
        use_positive_icon: false,
        positiveIconUrl: '',
        use_negative_icon: false,
        negativeIconUrl: '',
        launcherImageState: {
          image_src: '',
          isInvalidImage: false,
          isDirtyImage: false,
        },
      })

      // Move to other application edit page
      if (this.props.params.id) {
        this.refreshApplication()
      } else {
        this.setState({ isOpenIdpField: false })
      }

      // Initialize
      dispatch(fetchBots(state.session.token))
      cancelUrl.setRouteLeaveHook(this, [], ['colors'])
    }

    if (prevProps.currentBot.id !== this.props.currentBot.id) {
      if (!this.props.currentBot.use_fulltext_search) {
        this.props.change('use_suggestion', false)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refreshApplication = () => {
    const state = this.context.store.getState()
    const { dispatch } = this.props

    if (this.props.params.id) {
      dispatch(fetchApplication(state.session.token, this.props.params.id)).then(response => {
        const fetchedApplication = response.entities.applications[response.result]
        if (this.inPublishing(fetchedApplication) || fetchedApplication.has_reservation) {
          this.observePublishing(this.props.params.id)
        }
        if (fetchedApplication.icon_url === undefined || !fetchedApplication.icon_url) {
          this.setState({ default_icon: true })
        } else {
          this.setState({ image_src: fetchedApplication.icon_url })
        }

        if (fetchedApplication.positive_icon_url) {
          this.setState({ use_positive_icon: true, positiveIconUrl: fetchedApplication.positive_icon_url })
        }

        if (fetchedApplication.negative_icon_url) {
          this.setState({ use_negative_icon: true, negativeIconUrl: fetchedApplication.negative_icon_url })
        }

        if (fetchedApplication.latest_bot.original_id) {
          dispatch(fetchBot(state.session.token, fetchedApplication.latest_bot.original_id))
        }

        this.setState({ isOpenIdpField: fetchedApplication.authentication_type !== 'none' })
      })
    } else {
      this.setState({ default_icon: true, use_positive_icon: false, use_negative_icon: false })
    }
  }

  reinitializeForm = platform => {
    const { application, formValues } = this.props
    const disableCache = url => (url ? url + `?${new Date().getTime()}` : url)

    if (['web', 'direct_line', 'skype_for_business', 'teams'].includes(platform)) {
      this.props.initialize({
        ...formValues,
        notification_api_service: application.notification_api_service,
        web_account_link_api_service: application.web_account_link_api_service,
        upload_file: undefined,
        launcher_image_file: undefined,
        positive_icon_file: undefined,
        negative_icon_file: undefined,
      })
      this.setState({
        image_src: disableCache(application.icon_url),
        use_positive_icon: !!application.positive_icon_url,
        positiveIconUrl: disableCache(application.positive_icon_url),
        use_negative_icon: !!application.negative_icon_url,
        negativeIconUrl: disableCache(application.negative_icon_url),
        launcherImageState: {
          image_src: this.props.launcher_image_url,
          isInvalidImage: false,
          isDirtyImage: false,
        },
      })
    } else if (platform === 'line') {
      this.props.initialize({
        ...formValues,
        notification_api_service: application.notification_api_service,
        pnp_api_service: application.pnp_api_service,
        positive_icon_file: undefined,
        negative_icon_file: undefined,
      })
      this.setState({
        use_positive_icon: !!application.positive_icon_url,
        positiveIconUrl: disableCache(application.positive_icon_url),
        use_negative_icon: !!application.negative_icon_url,
        negativeIconUrl: disableCache(application.negative_icon_url),
      })
    } else {
      this.props.initialize({
        ...formValues,
        notification_api_service: application.notification_api_service,
      })
    }
  }

  handleSave = (data, dispatch) => {
    const { t } = this.context
    const { timezone, allow_fulltext_search } = getCredentials(this.context)
    const state = this.context.store.getState()
    const router = this.context.router

    const errors = validate(data, this.props)
    if (hasValidationError(errors)) throw new SubmissionError(errors)

    let application = {
      name: data.name,
      platform: data.platform,
      bot_id: data.bot_id,
      token: data.token,
    }

    application.constant_definitions = (data.constant_definitions || []).map(constant_definition => {
      // Remove empty value
      if (lodash.isEmpty(constant_definition.value)) {
        return lodash.omit(constant_definition, 'value')
      } else {
        return constant_definition
      }
    })

    if (this.props.initialValues.use_account_link && !data.use_account_link) {
      if (!window.confirm(t('application.line.accountLink.disableAccountLinkConfirm'))) return
    }
    if (isPermitted('feature_push_api', this.context)) {
      application.notification_api_service = data.notification_api_service
    } else {
      application.notification_api_service = { use_api: false }
    }

    if (data.has_reservation) {
      application.has_reservation = data.has_reservation
      // For IE replace '/' with '-' because can input invalid format 'yyyy/mm/dd' on normal textbox
      const reserved_at = moment.tz(
        data.reserved_at.date.replace(/\//g, '-') + ' ' + data.reserved_at.time,
        timezone
      )
      // IE does not save invalid formats because they can be freely entered in normal textbox
      if (!reserved_at.isValid()) {
        throw new SubmissionError({ reserved_at: { date: 'validate.invalid' } })
      }

      application.reserved_at = reserved_at.format()
    }

    if (data.platform === 'web') {
      if (isPermitted('feature_web_account_link_api', this.context)) {
        application.web_account_link_api_service = data.web_account_link_api_service
      } else {
        application.web_account_link_api_service = { use_api: false }
      }
      if (allow_fulltext_search && this.props.currentBot.use_fulltext_search) {
        application.use_suggestion = data.use_suggestion
      } else {
        application.use_suggestion = false
      }
      if (isPermitted('feature_idp_support', this.context)) {
        application.authentication_type = data.authentication_type
        if (data.authentication_type === 'saml') {
          if (data.saml_idp_metadata && data.saml_idp_metadata[0]) {
            application.saml_idp_metadata = data.saml_idp_metadata[0]
          }
        }
        if (data.authentication_type === 'oidc') {
          application.oidc_idp_client_id = data.oidc_idp_client_id
          if (data.oidc_idp_client_secret) {
            application.oidc_idp_client_secret = data.oidc_idp_client_secret
          }
          application.oidc_idp_configuration_url = data.oidc_idp_configuration_url
        }
      } else {
        application.authentication_type = 'none'
      }
      if (isPermitted('feature_translation', this.context)) {
        application.enable_translation = data.enable_translation
      } else {
        application.enable_translation = false
      }
      if (isPermitted('feature_logging_url_access', this.context)) {
        application.enable_logging_url_access = data.enable_logging_url_access
      } else {
        application.enable_logging_url_access = false
      }

      application.default_notice_mode = data.default_notice_mode
      application.default_speaker_name = data.default_speaker_name
      application.restrict_image_file_uploading = data.restrict_image_file_uploading
      application.keep_conversation_history = data.keep_conversation_history
    }

    if (isPermitted('feature_operator', this.context)) {
      if (data.platform === 'mobilus') {
        application.operator_function = true
      } else if (
        lodash.includes(
          ['web', 'line', 'line_works', 'line_works_v2', 'slack', 'api_v1', 'hangouts', 'teams'],
          data.platform
        )
      ) {
        application.operator_function = data.operator_function === 'true'
      } else {
        application.operator_function = false
      }
    } else {
      application.operator_function = false
    }

    if (data.platform === 'line') {
      if (data.channel_access_token !== '') {
        application.channel_access_token = data.channel_access_token
      }
      if (data.channel_secret !== '') {
        application.channel_secret = data.channel_secret
      }
      application.use_account_link = !!data.use_account_link
      application.external_service = data.external_service
      if (application.external_service) {
        if (lodash.isEmpty(application.external_service.login_url)) {
          application.external_service.login_url = null
        }
        if (lodash.isEmpty(application.external_service.fetch_user_id_url)) {
          application.external_service.fetch_user_id_url = null
        }
        if (lodash.isEmpty(application.external_service.unlink_url)) {
          application.external_service.unlink_url = null
        }
      }
      if (isPermitted('feature_line_switcher_api', this.context)) {
        application.use_switcher_api = !!data.use_switcher_api
        if (application.use_switcher_api) {
          if (data.switcher_secret !== '') {
            application.switcher_secret = data.switcher_secret
          }
          application.service_code = data.service_code
          application.skip_follow_message = data.skip_follow_message
          application.disable_postback = data.disable_postback
          // Clear old data
          application.channel_id = null
        }
      } else {
        application.use_switcher_api = false
      }

      // LINE PNP
      if (isPermitted('feature_line_pnp', this.context)) {
        application.pnp_api_service = data.pnp_api_service
      } else {
        application.pnp_api_service = { use_api: false }
      }
    }

    if (data.platform === 'line_works') {
      if (data.line_works_server_api_consumer_key !== '') {
        application.line_works_server_api_consumer_key = data.line_works_server_api_consumer_key
      }
      if (data.line_works_server_authentication_key !== '') {
        application.line_works_server_authentication_key = data.line_works_server_authentication_key
      }
      application.line_works_api_id = data.line_works_api_id
      application.line_works_server_id = data.line_works_server_id
    }

    if (data.platform === 'line_works_v2') {
      application.line_works_client_id = data.line_works_client_id
      if (data.line_works_client_secret !== '') {
        application.line_works_client_secret = data.line_works_client_secret
      }
      application.line_works_service_account = data.line_works_service_account
      if (data.line_works_private_key !== '') {
        application.line_works_private_key = data.line_works_private_key
      }
      if (data.line_works_bot_secret !== '') {
        application.line_works_bot_secret = data.line_works_bot_secret
      }
    }

    if (data.platform === 'slack') {
      if (data.access_token !== '') {
        application.access_token = data.access_token
      }
      application.slack_reaction_condition_in_thread = data.slack_reaction_condition_in_thread
      application.slack_reaction_condition_outside_thread = data.slack_reaction_condition_outside_thread
      application.slack_reaction_to_emoji = data.slack_reaction_to_emoji
    }
    let isOfficialAzure = false
    if (lodash.includes(['azure_bot_service', 'skype_for_business', 'teams', 'direct_line'], data.platform)) {
      if (data.azure_bot_service_application_id && data.azure_bot_service_application_id !== '') {
        application.azure_bot_service_application_id = data.azure_bot_service_application_id
      }
      if (data.azure_bot_service_password && data.azure_bot_service_password !== '') {
        application.azure_bot_service_password = data.azure_bot_service_password
      }
      if (lodash.includes(['skype_for_business', 'teams', 'direct_line'], data.platform)) {
        application.target_channel = data.platform
        application.platform = 'azure_bot_service'
        if (application.target_channel === 'skype_for_business') {
          isOfficialAzure = true
        }
      }
    }

    if (data.platform === 'teams') {
      if (isPermitted('feature_teams_restricted_access', this.context)) {
        application.use_allowed_domains = !!data.use_allowed_domains
        application.allowed_domains = data.allowed_domains || null
      }
      if (isPermitted('feature_logging_url_access', this.context)) {
        application.enable_logging_url_access = data.enable_logging_url_access
      } else {
        application.enable_logging_url_access = false
      }
    }

    if (data.platform === 'mobilus') {
      if (data.mobilus_domain_type === 'production') {
        application.mobilus_push_domain = 'agent.mobilus.me'
      } else if (data.mobilus_domain_type === 'trial') {
        application.mobilus_push_domain = 'agent.trial-mobilus.chat'
      } else if (data.mobilus_domain_type === 'custom') {
        application.mobilus_push_domain = data.mobilus_push_domain
      }
      application.mobilus_tenant_id = data.mobilus_tenant_id || null
      application.mobilus_user_id = data.mobilus_user_id
      if (data.mobilus_api_key !== '') {
        application.mobilus_api_key = data.mobilus_api_key
      }
    }

    if (data.platform === 'api_v1') {
      application.message_format = data.message_format
      application.use_simple_conversation = !!data.use_simple_conversation
      application.use_line_extra = data.message_format === 'dialogplay' && !!data.use_line_extra
    }
    if (data.platform === 'hangouts') {
      if (!this.props.params.id) {
        if (!data.service_account_file || !data.service_account_file[0]) {
          dispatch(addNotice('error', t('bot.noFileSelectedError')))
          return
        }
      }
      application.project_no = data.project_no
      if (data.service_account_file && data.service_account_file[0]) {
        application.service_account_file = data.service_account_file[0]
      }
    }

    if (this.props.params.id) {
      application.id = data.id
    }

    if (
      data.platform === 'web' ||
      application.target_channel === 'direct_line' ||
      data.platform === 'api_v1'
    ) {
      if (isPermitted('feature_restricted_access', this.context)) {
        application.use_allowed_addresses = !!data.use_allowed_addresses
        application.allowed_addresses = data.use_allowed_addresses ? data.allowed_addresses : null
      } else {
        application.use_allowed_addresses = false
        application.allowed_addresses = null
      }
    }
    if (data.platform === 'web') {
      if (this.state.default_icon) {
        application.default_icon = true
      }

      application.use_icon_for_each_message = data.use_icon_for_each_message

      // Check if icon file is exist
      if (!this.state.default_icon) {
        application.icon_file = data.upload_file
      }

      if (data.use_icon_for_each_message && this.state.use_positive_icon) {
        application.positive_icon_file = data.positive_icon_file
      } else {
        application.positive_icon_file = null
      }

      if (data.use_icon_for_each_message && this.state.use_negative_icon) {
        application.negative_icon_file = data.negative_icon_file
      } else {
        application.negative_icon_file = null
      }
    }
    if (data.platform === 'line') {
      application.use_icon_for_each_message = data.use_icon_for_each_message

      if (data.use_icon_for_each_message && this.state.use_positive_icon) {
        application.positive_icon_file = data.positive_icon_file
      } else {
        application.positive_icon_file = null
      }

      if (data.use_icon_for_each_message && this.state.use_negative_icon) {
        application.negative_icon_file = data.negative_icon_file
      } else {
        application.negative_icon_file = null
      }
    }
    if (data.platform === 'teams') {
      if (this.state.default_icon) {
        application.default_icon = this.state.default_icon
      } else {
        if (data.upload_file) {
          application.icon_file = data.upload_file
        }
      }
    }

    //  Convert to Formdata if contains File field
    if (lodash.some(application, value => value instanceof File)) {
      application = convertToFormData(application)
    }

    if (this.props.params.id) {
      return dispatch(updateApplication(state.session.token, this.props.params.id, application))
        .then(response => {
          const fetchedApplication = response.entities.applications[response.result]
          if (fetchedApplication.has_reservation) {
            dispatch(addNotice('info', t('application.reserveSuccessMessage')))
          }
        })
        .then(() => this.observePublishing(data.id))
        .then(() => this.reinitializeForm(data.platform))
        .finally(() => {
          if (document.getElementById('service_account_file')) {
            document.getElementById('service_account_file').value = ''
          }
          if (document.getElementById('saml_idp_metadata')) {
            document.getElementById('saml_idp_metadata').value = ''
          }
        })
    } else {
      if (isOfficialAzure) {
        if (!window.confirm(t('common.sendRequestConfirmMessage'))) return
      }

      return dispatch(createApplication(state.session.token, application))
        .then(json => {
          router.push({
            pathname: `/applications/${json.result}`,
            state: { keepNotices: true, ignoreBlocking: true },
          })
          return json
        })
        .then(json => {
          cancelUrl.setRouteLeaveHook(this, [], ['colors'])
          return json
        })
        .then(json => {
          if (isOfficialAzure) {
            dispatch(addNotice('info', t('common.sendRequestSuccessMessage')))
          } else {
            this.observePublishing(json.result)
          }
          return json
        })
        .then(() => this.reinitializeForm(data.platform))
        .finally(() => {
          if (document.getElementById('service_account_file')) {
            document.getElementById('service_account_file').value = ''
          }
          if (document.getElementById('saml_idp_metadata')) {
            document.getElementById('saml_idp_metadata').value = ''
          }
        })
    }
  }

  handleSaveToIcon = file => {
    const image_src = window.URL.createObjectURL(file)

    this.setState({ default_icon: false, image_src: image_src })
    this.props.change('upload_file', file)

    return true
  }

  handleSaveToPositiveIcon = file => {
    const image_src = window.URL.createObjectURL(file)

    this.setState({ use_positive_icon: true, positiveIconUrl: image_src })
    this.props.change('positive_icon_file', file)

    return true
  }

  handleSaveToNegativeIcon = file => {
    const image_src = window.URL.createObjectURL(file)

    this.setState({ use_negative_icon: true, negativeIconUrl: image_src })
    this.props.change('negative_icon_file', file)

    return true
  }

  handleDeletePositiveIcon = () => {
    const { t } = this.context
    const { platform } = this.props

    if (!window.confirm(t(`application.${platform}.deleteIconEachMessageConfirm`))) return false

    this.setState({ use_positive_icon: false, positiveIconUrl: '' })
    this.props.change('positive_icon_url', null)
    this.props.change('positive_icon_file', null)

    return true
  }

  handleDeleteNegativeIcon = () => {
    const { t } = this.context
    const { platform } = this.props

    if (!window.confirm(t(`application.${platform}.deleteIconEachMessageConfirm`))) return false

    this.setState({ use_negative_icon: false, negativeIconUrl: '' })
    this.props.change('negative_icon_url', null)
    this.props.change('negative_icon_file', null)

    return true
  }

  handleAbort = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { application } = this.props
    return dispatch(abortApplication(state.session.token, application.id))
      .then(() => dispatch(addNotice('warn', t('common.publishAbortedMessage'))))
      .then(() => clearTimeout(this.timer))
  }

  handleAbortReservation = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { application } = this.props
    return dispatch(abortReservation(state.session.token, application.id))
      .then(() => dispatch(addNotice('warn', t('application.reserveAbortedMessage'))))
      .then(() => clearTimeout(this.timer))
  }

  renderDeleteConfirmForm = () => {
    return (
      <DeleteConfirm
        targetType="application"
        targetName={this.props.application.name}
        onDeleteConfirmSubmit={this.handleDeleteConfirmSubmit}
        onDeleteConfirmClose={this.handleDeleteConfirmClose}
        errorMessage={this.state.deleteConfirmErrorMessage}
      />
    )
  }

  handleDeleteConfirmOpen = () => {
    this.setState({ isDeleteConfirmOpened: true })
  }

  handleDeleteConfirmClose = () => {
    this.setState({ isDeleteConfirmOpened: false })
  }

  handleDeleteConfirmSubmit = () => {
    const { t } = this.context
    const { application, dispatch } = this.props

    if (this.inPublishing(application)) return
    const state = this.context.store.getState()
    const router = this.context.router
    this.setState({ inDeleting: true })
    dispatch(deleteApplication(state.session.token, parseInt(this.props.params.id, 10), true))
      .then(() => router.push({ pathname: '/applications', state: { ignoreBlocking: true } }))
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .catch(e => {
        this.setState({ deleteConfirmErrorMessage: e.message, inDeleting: false })
      })
  }

  handleReissueApplicationToken = () => {
    const { t } = this.context
    if (!window.confirm(t('application.web.reissueConfirmMessage'))) return
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(reissueApplicationToken(state.session.token, id))
  }

  handleMobilusReissueApplicationToken = () => {
    const { t } = this.context
    if (!window.confirm(t('application.mobilus.reissueConfirmMessage'))) return
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(reissueApplicationToken(state.session.token, id))
  }

  handleReissueApiKey = () => {
    const { t } = this.context
    if (!window.confirm(t('application.pushApi.reissueConfirmMessage'))) return
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(reissueNotificationApiKey(state.session.token, id))
  }

  handleReissuePnpApiKey = () => {
    const { t } = this.context
    if (!window.confirm(t('application.pnpApi.reissueConfirmMessage'))) return
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(reissuePnpApiKey(state.session.token, id))
  }

  handleReissueAccountLinkApiKey = () => {
    const { t } = this.context
    if (!window.confirm(t('application.webAccountLink.reissueConfirmMessage'))) return
    const state = this.context.store.getState()
    const { dispatch } = this.props
    const id = parseInt(this.props.params.id, 10)
    dispatch(reissueWebAccountLinkApiKey(state.session.token, id))
  }

  handleCopy = fieldName => {
    document.querySelector(`[name="${fieldName}"]`).select()
    document.execCommand('Copy')
  }

  observePublishing = id => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch, platform } = this.props

    dispatch(fetchApplication(state.session.token, id, true))
      .then(response => {
        const fetchedApplication = response.entities.applications[response.result]
        if (fetchedApplication.status === 'published' && !fetchedApplication.has_reservation) {
          dispatch(fetchBots(state.session.token))
          dispatch(addNotice('info', t('common.publishSuccessMessage')))
        }
        if (this.inPublishing(fetchedApplication) || fetchedApplication.has_reservation) {
          this.timer = setTimeout(() => this.observePublishing(id), 10000)
        }
      })
      .then(() => this.reinitializeForm(platform))
  }

  isPublished = application => {
    if (!application) return false
    return (
      application.status === 'published' || (application.status === 'aborted' && application.is_published)
    )
  }

  inPublishing = application => {
    if (!application) return false
    return lodash.includes(['enqueued', 'publishing'], application.status)
  }

  restoreDefaultIcon = () => {
    const { t } = this.context

    if (!window.confirm(t('application.web.restoreDefaultConfirm'))) return false

    this.setState({ image_src: '' })
    this.setState({ default_icon: true })
    this.setState({ isInvalidImage: false })
    this.props.change('upload_file', undefined)

    return true
  }

  clearIcon = () => {
    const { platform } = this.props
    if (!lodash.includes(['web', 'teams'], platform)) {
      return
    }
    if (!isPermitted('feature_custom_icon', this.context)) {
      return
    }
    this.setState({ default_icon: true })
    this.props.change('upload_file', undefined)
  }

  handleSaveEmbeddedTag = (data, dispatch) => {
    const { t } = this.context
    const state = this.context.store.getState()
    var application = {
      embedded_title: data.embedded_title,
      embedded_position: data.embedded_position,
      embedded_launcher_type: data.embedded_launcher_type,
      embedded_closer_type: data.embedded_closer_type,
      embedded_theme: data.embedded_theme,
      embedded_font_size: data.embedded_font_size,
      embedded_pause: data.embedded_pause,
      colors: JSON.stringify(data.colors),
      fit_height: data.fit_height,
      auto_hide_scrollbar: data.auto_hide_scrollbar,
      auto_popup: data.auto_popup,
      auto_popup_seconds: data.auto_popup_seconds,
    }
    // Convert to formData in case of launcher custom image file
    const applicationFormData = new FormData()
    if (data.embedded_launcher_type === 'custom-image' && data.launcher_image_file) {
      Object.entries(application).forEach(([key, value]) => {
        applicationFormData.append(key, value)
      })
      applicationFormData.append('launcher_image_file', data.launcher_image_file[0])
      application = applicationFormData
    }
    // Save
    const id = parseInt(this.props.params.id, 10)
    return dispatch(updateEmbeddedTag(state.session.token, id, application))
      .then(() => {
        if (data.embedded_launcher_type === 'custom-image') {
          if (!this.props.application.launcher_image_url) {
            // Publish if first save.
            return dispatch(publishLauncherImage(state.session.token, id))
          } else if (application.launcher_image_url && application.launcher_image_url_pending) {
            // Launcher image is dirty, notice need publishing
            return dispatch(addNotice('info', t('application.web.customize.needPublish')))
          }
        }
      })
      .then(() => dispatch(addNotice('info', t('common.saveSuccessMessage'))))
      .then(() => this.reinitializeForm(data.platform))
  }

  handlePublishLauncherImage = () => {
    const { t } = this.context
    const state = this.context.store.getState()
    const { dispatch } = this.props

    const id = parseInt(this.props.params.id, 10)
    return dispatch(publishLauncherImage(state.session.token, id))
      .then(() => dispatch(addNotice('info', t('application.web.customize.publishSuccessMessage'))))
      .then(() => this.reinitializeForm('web'))
  }

  restoreDefaultColor = () => {
    const { t } = this.context
    if (!window.confirm(t('application.web.customize.colors.restoreDefaultColorConfirm'))) return
    this.props.change('colors', defaultColors)
  }

  checkLauncherImageFile = image => {
    // check of file type and file size
    // maximum file size is 3MB(=3145728byte) for web,
    const authorizedFileFormat = ['image/png', 'image/jpeg', 'image/gif']
    const maxFileSize = 3145728
    const image_src = window.URL.createObjectURL(image)
    if (authorizedFileFormat.indexOf(image.type) === -1 || image.size > maxFileSize) {
      this.setState({
        launcherImageState: {
          ...this.state.launcherImageState,
          isInvalidImage: true,
        },
      })
      return false
    }
    // check height and width
    const minHeight = 48
    const minWidth = 48
    const maxHeight = 200
    const maxWidth = 400
    const img = new Image()
    img.onload = () => {
      if (img.height < minHeight || img.width < minWidth || img.height > maxHeight || img.width > maxWidth) {
        this.setState({
          launcherImageState: {
            ...this.state.launcherImageState,
            isInvalidImage: true,
          },
        })
        return false
      } else {
        this.setState({
          launcherImageState: {
            ...this.state.launcherImageState,
            isInvalidImage: false,
            image_src: img.src,
          },
        })
      }
    }
    img.src = image_src
    return true
  }

  changeLauncherImageFile = e => {
    var files = e.target.files
    this.setState({
      launcherImageState: {
        ...this.state.launcherImageState,
        isDirtyImage: true,
      },
    })
    if (files[0]) {
      this.checkLauncherImageFile(files[0])
    } else {
      this.setState({
        launcherImageState: {
          ...this.state.launcherImageState,
          isInvalidImage: false,
        },
      })
    }
  }

  render() {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const {
      dispatch,
      application,
      bots,
      currentBot,
      platform,
      isFetching,
      submitting,
      handleSubmit,
      formValues,
    } = this.props
    const { timezone, allow_fulltext_search } = getCredentials(this.context)
    const platforms = []

    if (isPermitted('platform_web', this.context)) {
      platforms.push({ id: 'web', name: t('common.platform.web') })
    }

    const lastPublishedAt = application.published_at
      ? moment.tz(application.published_at, timezone).format('YYYY/MM/DD HH:mm')
      : undefined

    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <PublishNotice application={application} />
          <form
            className="text-left col-md-9"
            ref="upload_form"
            encType="multipart/form-data"
            onSubmit={handleSubmit(this.handleSave)}
          >
            <div className="form-group">
              <div className="form-inline">
                <LabelWithTooltip htmlFor="name" className="dm-title-mini" name="application.name" />
                {lastPublishedAt && (
                  <div className="form-group pull-right dm-note">
                    {`${t('application.lastPublishedAt')}: ${lastPublishedAt}`}
                  </div>
                )}
              </div>
              <Field
                type="text"
                name="name"
                className="form-control dm-form-control"
                maxLength="255"
                component={InputField}
                disabled={application.has_reservation}
              />
              {platform === 'teams' && (
                <div className="dm-note">
                  {t('application.teams.note1')}
                  <br />
                  {t('application.teams.note2')}
                  <Tooltip name="application.tooltip.teams.name" />
                </div>
              )}
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="platform" className="dm-title-mini" name="application.platform" />
              <Field
                name="platform"
                items={platforms}
                valueKey="id"
                displayKey="name"
                className="form-control dm-form-control"
                disabled={isUpdate}
                component={SelectField}
                onChange={this.clearIcon}
              />
              {platform === 'skype_for_business' && (
                <div className="dm-note">
                  {t('application.skypeForBusiness.note')}
                  &nbsp;
                  {t('application.skypeForBusiness.note2')}
                </div>
              )}
              {platform === 'direct_line' && (
                <div className="form-group">
                  <div className="dm-note">{t('application.directLine.note')}</div>
                  <div
                    className="dm-note"
                    dangerouslySetInnerHTML={{ __html: t('application.directLine.referenceAnchor') }}
                  />
                </div>
              )}
              {platform === 'mobilus' && (
                <div className="dm-note strong">
                  <div>{t('application.mobilus.notes.availablePlatforms')}</div>
                  <div className="bold">{t('application.mobilus.notes.recommendedSetting')}</div>
                </div>
              )}
            </div>
            <div className="form-group">
              <LabelWithTooltip htmlFor="bot_id" className="dm-title-mini" name="application.bots" />
              <Field
                name="bot_id"
                className="form-control dm-form-control"
                disabled={application.has_reservation}
                component={SelectField}
                items={bots}
                valueKey="id"
                displayKey="name"
                empty={true}
                parse={value => value && parseInt(value, 10)}
                order="asc"
                onChange={event => {
                  const token = this.context.store.getState().session.token
                  dispatch(fetchBot(token, event.target.value))
                }}
              />
            </div>
            {platform === 'web' && allow_fulltext_search && (
              <div className="form-inline checkbox mb-4">
                <Field
                  type="checkbox"
                  name="use_suggestion"
                  id="use_suggestion"
                  className="form-control m-0"
                  component={CheckboxField}
                  disabled={!currentBot.use_fulltext_search || application.has_reservation}
                />
                <LabelWithTooltip htmlFor="use_suggestion" name="application.web.useSuggestion" />
              </div>
            )}
            {platform === 'web' && this.renderWeb()}
            {platform === 'line' && this.renderLine()}
            {platform === 'line_works' && this.renderLINEWorksV1()}
            {platform === 'line_works_v2' && this.renderLINEWorksV2()}
            {platform === 'slack' && this.renderSlack()}
            {platform === 'azure_bot_service' && this.renderAzureBotService()}
            {platform === 'skype_for_business' && this.renderSkypeForBusiness()}
            {platform === 'teams' && this.renderTeams()}
            {platform === 'direct_line' && this.renderDirectLine()}
            {platform === 'mobilus' && this.renderMobilus()}
            {platform === 'api_v1' && this.renderAPIv1()}
            {platform === 'hangouts' && this.renderHangouts()}
            {this.props.params.id && platform !== 'azure_bot_service' && this.renderReservationPublishField()}
            <div className="form-group text-right">
              {this.renderReservationButtons()}
              {this.renderPublishingButtons()}
              {this.props.params.id && (
                <button
                  type="button"
                  className="btn btn-danger dm-btn"
                  onClick={this.handleDeleteConfirmOpen}
                  disabled={submitting || this.inPublishing(application)}
                >
                  {t('common.delete')}
                </button>
              )}
            </div>
            {currentBot.dirty && (
              <div
                className="form-group error"
                dangerouslySetInnerHTML={{ __html: t('application.needTraining', { bot_id: currentBot.id }) }}
              ></div>
            )}
          </form>
          <form className="text-left col-md-9" onSubmit={handleSubmit(this.handleSaveEmbeddedTag)}>
            {platform === 'web' && !isFetching && this.props.params.id && (
              <EmbeddedTagEdit
                application={application}
                inPublishing={this.inPublishing(application)}
                isPublished={this.isPublished(application)}
                submitting={submitting}
                formValues={formValues}
                handlePublishLauncherImage={this.handlePublishLauncherImage}
                handleCopy={this.handleCopy}
                restoreDefaultColor={this.restoreDefaultColor}
                changeLauncherImageFile={this.changeLauncherImageFile}
                launcherImageState={this.state.launcherImageState}
                isDirtyCustomize={this.props.isDirtyCustomize}
              />
            )}
          </form>
          {this.state.isDeleteConfirmOpened && this.renderDeleteConfirmForm()}
        </Loader>
      </div>
    )
  }

  renderError = field => {
    const { t } = this.context
    const {
      meta: { error, touched },
    } = field
    if (!error || !touched) return null

    return <div className="error">{t(error)}</div>
  }

  getIconSize = () => {
    const { platform } = this.props

    const iconSizes = {
      web: { height: 48, width: 48 },
      line: { height: 48, width: 48 },
      teams: { height: 32, width: 32 },
    }

    return iconSizes[platform] || {}
  }

  getAuthorizedIconFileFormats = () => {
    const { platform } = this.props

    const authorizedFileFormats = {
      web: ['image/png', 'image/jpeg', 'image/gif'],
      line: ['image/png', 'image/jpeg'],
      teams: ['image/png'],
    }

    return authorizedFileFormats[platform] || []
  }

  renderIconFieldNotes = () => {
    const { t } = this.context
    const { platform } = this.props

    return (
      <ul>
        <li>{t(`application.${platform}.iconSettingDialog.authorizedFileFormat`)}</li>
        {(platform === 'web' || platform === 'teams') && (
          <li>{t(`application.${platform}.iconSettingDialog.minimumImageSize`)}</li>
        )}
        <li>{t(`application.${platform}.iconSettingDialog.maxFileSize`)}</li>
      </ul>
    )
  }

  renderIconField = () => {
    const { application, platform, submitting } = this.props
    const { image_src } = this.state

    let defaultUrl
    let restoreDefault
    if (platform === 'web') {
      defaultUrl = 'https://dialogplay.jp/image/avator_system%402x.png'
      restoreDefault = this.restoreDefaultIcon
    } else {
      defaultUrl = undefined
      restoreDefault = undefined
    }

    const iconSize = this.getIconSize()

    return (
      <div className="form-group">
        <IconFieldWithDialog
          name={`application.${platform}.icon`}
          iconUrl={image_src}
          defaultUrl={defaultUrl}
          iconHeight={iconSize.height}
          iconWidth={iconSize.width}
          iconFileFormats={this.getAuthorizedIconFileFormats()}
          renderNotes={this.renderIconFieldNotes}
          restoreDefault={restoreDefault}
          handleSave={this.handleSaveToIcon}
          disabled={submitting || application.has_reservation}
        />
      </div>
    )
  }

  renderIconEachMessageFields = () => {
    const { application, platform, formValues, submitting } = this.props

    const iconSize = this.getIconSize()

    return (
      <React.Fragment>
        <div className="form-inline checkbox mb-2">
          <Field
            type="checkbox"
            name="use_icon_for_each_message"
            id="use_icon_for_each_message"
            className="form-control m-0"
            component={CheckboxField}
            disabled={application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="use_icon_for_each_message"
            name={`application.${platform}.iconForEachMessage.title`}
          />
        </div>
        {formValues.use_icon_for_each_message && (
          <div className="with-indent">
            <div className="flex">
              <div className="col-sm-6">
                <IconFieldWithDialog
                  name={`application.${platform}.iconForEachMessage.positive`}
                  iconUrl={this.state.positiveIconUrl}
                  iconHeight={iconSize.height}
                  iconWidth={iconSize.width}
                  iconFileFormats={this.getAuthorizedIconFileFormats()}
                  renderNotes={this.renderIconFieldNotes}
                  handleSave={this.handleSaveToPositiveIcon}
                  handleDelete={this.handleDeletePositiveIcon}
                  disabled={submitting || application.has_reservation}
                />
              </div>
              <div className="col-sm-6">
                <IconFieldWithDialog
                  name={`application.${platform}.iconForEachMessage.negative`}
                  iconUrl={this.state.negativeIconUrl}
                  iconHeight={iconSize.height}
                  iconWidth={iconSize.width}
                  iconFileFormats={this.getAuthorizedIconFileFormats()}
                  renderNotes={this.renderIconFieldNotes}
                  handleSave={this.handleSaveToNegativeIcon}
                  handleDelete={this.handleDeleteNegativeIcon}
                  disabled={submitting || application.has_reservation}
                />
              </div>
            </div>
            <Field name="icon_each_message" component={this.renderError} />
          </div>
        )}
      </React.Fragment>
    )
  }

  renderPushApiFields = () => {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { application, formValues } = this.props

    const copyableFields = [
      <div className="form-group" key="apikey">
        <LabelWithTooltip className="dm-title-mini" name="application.pushApi.apiKey" />
        <div className="input-group">{this.renderCopyableField('notification_api_service.api_key')}</div>
        <div className="input-group pt-1">
          <button
            type="button"
            className="btn btn-warning dm-btn"
            disabled={application.has_reservation}
            onClick={this.handleReissueApiKey}
          >
            {t('application.pushApi.reissue')}
          </button>
        </div>
      </div>,
    ]

    return (
      <div className="form-group" key="push">
        <Collapsible
          trigger={t('application.pushApi.title')}
          open={application.notification_api_service.use_api}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            <div className="form-group form-inline checkbox mb-2">
              <Field
                type="checkbox"
                name="notification_api_service.use_api"
                id="notification_api_service.use_api"
                className="form-control m-0"
                component={CheckboxField}
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                htmlFor="notification_api_service.use_api"
                name="application.pushApi.enabled"
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="allowed_addresses_for_push_api"
                className="dm-title-mini"
                name="application.pushApi.allowedAddresses.title"
              />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="notification_api_service.use_allowed_addresses"
                  id="notification_api_service.use_allowed_addresses"
                  component={CheckboxField}
                  disabled={!formValues.notification_api_service.use_api || application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="notification_api_service.use_allowed_addresses"
                  className="dm-checkbox"
                  name="application.pushApi.allowedAddresses.enabled"
                />
              </div>
              <Field
                name="notification_api_service.allowed_addresses"
                className="form-control dm-form-control"
                component={InputField}
                maxLength="10000"
                disabled={
                  !formValues.notification_api_service.use_api ||
                  !formValues.notification_api_service.use_allowed_addresses ||
                  application.has_reservation
                }
              />
            </div>
            {isUpdate && application.notification_api_service.use_api && copyableFields}
          </div>
        </Collapsible>
      </div>
    )
  }

  renderPnpApiFields = () => {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { application, formValues } = this.props

    const copyableFields = [
      <div className="form-group" key="apikey">
        <LabelWithTooltip className="dm-title-mini" name="application.pnpApi.apiKey" />
        <div className="input-group">{this.renderCopyableField('pnp_api_service.api_key')}</div>
        <div className="input-group pt-1">
          <button
            type="button"
            className="btn btn-warning dm-btn"
            disabled={application.has_reservation}
            onClick={this.handleReissuePnpApiKey}
          >
            {t('application.pnpApi.reissue')}
          </button>
        </div>
      </div>,
    ]

    return (
      <div className="form-group" key="pnp">
        <Collapsible
          trigger={t('application.pnpApi.title')}
          open={application.pnp_api_service.use_api}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            <div className="form-group form-inline checkbox mb-2">
              <Field
                type="checkbox"
                name="pnp_api_service.use_api"
                id="pnp_api_service.use_api"
                className="form-control m-0"
                component={CheckboxField}
                disabled={application.has_reservation}
              />
              <LabelWithTooltip htmlFor="pnp_api_service.use_api" name="application.pnpApi.enabled" />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="allowed_addresses_for_pnp_api"
                className="dm-title-mini"
                name="application.pnpApi.allowedAddresses.title"
              />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="pnp_api_service.use_allowed_addresses"
                  id="pnp_api_service.use_allowed_addresses"
                  component={CheckboxField}
                  disabled={!formValues.pnp_api_service.use_api || application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="pnp_api_service.use_allowed_addresses"
                  className="dm-checkbox"
                  name="application.pnpApi.allowedAddresses.enabled"
                />
              </div>
              <Field
                name="pnp_api_service.allowed_addresses"
                className="form-control dm-form-control"
                component={InputField}
                maxLength="10000"
                disabled={
                  !formValues.pnp_api_service.use_api ||
                  !formValues.pnp_api_service.use_allowed_addresses ||
                  application.has_reservation
                }
              />
            </div>
            {isUpdate && application.pnp_api_service.use_api && copyableFields}
          </div>
        </Collapsible>
      </div>
    )
  }

  renderWebAccountLinkApiFields = () => {
    const isUpdate = !!this.props.params.id
    const { t } = this.context
    const { application, formValues } = this.props

    const copyableFields = [
      <div className="form-group" key="apikey">
        <LabelWithTooltip className="dm-title-mini" name="application.webAccountLink.apiKey" />
        <div className="input-group">{this.renderCopyableField('web_account_link_api_service.api_key')}</div>
        <div className="input-group pt-1">
          <button
            type="button"
            className="btn btn-warning dm-btn"
            onClick={this.handleReissueAccountLinkApiKey}
            disabled={application.has_reservation}
          >
            {t('application.webAccountLink.reissue')}
          </button>
        </div>
      </div>,
    ]

    return (
      <div className="form-group" key="webAccountLink">
        <Collapsible
          trigger={t('application.webAccountLink.title')}
          open={application.web_account_link_api_service.use_api}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            <div className="form-group form-inline checkbox mb-2">
              <Field
                type="checkbox"
                name="web_account_link_api_service.use_api"
                id="web_account_link_api_service.use_api"
                className="form-control m-0"
                component={CheckboxField}
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                htmlFor="web_account_link_api_service.use_api"
                name="application.webAccountLink.enabled"
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="allowed_addresses_for_web_account_link_api"
                className="dm-title-mini"
                name="application.webAccountLink.allowedAddresses.title"
              />
              <div className="form-inline">
                <Field
                  type="checkbox"
                  name="web_account_link_api_service.use_allowed_addresses"
                  id="web_account_link_api_service.use_allowed_addresses"
                  component={CheckboxField}
                  disabled={!formValues.web_account_link_api_service.use_api || application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="web_account_link_api_service.use_allowed_addresses"
                  className="dm-checkbox"
                  name="application.webAccountLink.allowedAddresses.enabled"
                />
              </div>
              <Field
                name="web_account_link_api_service.allowed_addresses"
                className="form-control dm-form-control"
                component={InputField}
                maxLength="10000"
                disabled={
                  !formValues.web_account_link_api_service.use_api ||
                  !formValues.web_account_link_api_service.use_allowed_addresses ||
                  application.has_reservation
                }
              />
            </div>
            {isUpdate && application.web_account_link_api_service.use_api && copyableFields}
          </div>
        </Collapsible>
      </div>
    )
  }

  renderIdPFields = () => {
    const { t } = this.context
    const { application, formValues } = this.props
    const { isOpenIdpField } = this.state

    return (
      <div className="form-group" key="IdP">
        <Collapsible
          trigger={t('application.web.idp.title')}
          open={isOpenIdpField}
          transitionTime={300}
          overflowWhenOpen="visible"
          handleTriggerClick={() => this.setState({ isOpenIdpField: !isOpenIdpField })}
        >
          <div className="with-indent">
            <div className="form-group row">
              <div className="radio-inline col-sm-3">
                <Field
                  type="radio"
                  name="authentication_type"
                  id="authentication_type_none"
                  value="none"
                  className="form-control"
                  component="input"
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip htmlFor="authentication_type_none" name="application.web.idp.types.none" />
              </div>
              <div className="radio-inline col-sm-3">
                <Field
                  type="radio"
                  name="authentication_type"
                  id="authentication_type_saml"
                  value="saml"
                  className="form-control"
                  component="input"
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip htmlFor="authentication_type_saml" name="application.web.idp.types.saml" />
              </div>
              <div className="radio-inline col-sm-3">
                <Field
                  type="radio"
                  name="authentication_type"
                  id="authentication_type_oidc"
                  value="oidc"
                  className="form-control"
                  component="input"
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip htmlFor="authentication_type_oidc" name="application.web.idp.types.oidc" />
              </div>
            </div>
            {formValues.authentication_type === 'saml' && this.renderIdPForSAMLFields()}
            {formValues.authentication_type === 'oidc' && this.renderIdPForOIDCFields()}
          </div>
        </Collapsible>
      </div>
    )
  }

  renderIdPForSAMLFields = () => {
    const { t } = this.context
    const { application, dispatch } = this.props
    const state = this.context.store.getState()

    const downloadSpMetadata = e => {
      dispatch(fetchSpMetadata(state.session.token, application.id)).then(blob => {
        downloadBlob(blob, `sp_metadata_${application.id}.xml`)
      })
      e.preventDefault()
    }

    return (
      <>
        <div className="form-group">
          <LabelWithTooltip
            htmlFor="saml_idp_metadata"
            className="dm-title-mini"
            name="application.web.idp.saml.idpMetadata"
          />
          <Field
            type="file"
            name="saml_idp_metadata"
            className="form-control dm-form-control"
            component={FileField}
            disabled={application.has_reservation}
          />
        </div>
        {application.authentication_type === 'saml' && (
          <a href="#download_sp_metadata" onClick={downloadSpMetadata}>
            {t('application.web.idp.saml.downloadSpMetadata')}
          </a>
        )}
      </>
    )
  }

  renderIdPForOIDCFields = () => {
    const { t } = this.context
    const { application } = this.props

    return (
      <>
        <div className="form-group">
          <LabelWithTooltip
            htmlFor="oidc_idp_client_id"
            className="dm-title-mini"
            name="application.web.idp.oidc.clientId"
          />
          <Field
            name="oidc_idp_client_id"
            className="form-control dm-form-control"
            component={InputField}
            maxLength="255"
            disabled={application.has_reservation}
          />
        </div>
        <div className="form-group">
          <LabelWithTooltip
            htmlFor="oidc_idp_client_secret"
            className="dm-title-mini"
            name="application.web.idp.oidc.clientSecret"
          />
          <Field
            name="oidc_idp_client_secret"
            type="password"
            className="form-control dm-form-control"
            component={InputField}
            maxLength="255"
            placeholder={this.props.params.id ? '********' : ''}
            disabled={application.has_reservation}
          />
        </div>
        <div className="form-group idp-configuration-url">
          <LabelWithTooltip
            htmlFor="oidc_idp_configuration_url"
            className="dm-title-mini"
            name="application.web.idp.oidc.configurationURL"
          />
          <div className="row">
            <Field
              name="oidc_idp_configuration_url"
              className="form-control dm-form-control"
              component={InputField}
              maxLength="255"
              disabled={application.has_reservation}
              placeholder={t('application.web.idp.oidc.configurationURLPlaceholder')}
            />
            <LabelWithTooltip
              className="dm-title-mini"
              name="application.web.idp.oidc.configurationURLSuffix"
            />
          </div>
        </div>
        <div className="form-group">
          <LabelWithTooltip className="dm-title-mini" name="application.web.idp.oidc.redirectURL" />
          <div className="input-group">
            {application.authentication_type !== 'oidc' && (
              <input
                type="text"
                className="form-control dm-form-control"
                readOnly={true}
                value={t('application.web.idp.oidc.disabledRedirectURL')}
              />
            )}
            {application.authentication_type === 'oidc' && (
              <Field
                type="text"
                name="oidc_idp_redirect_url"
                className="form-control dm-form-control"
                readOnly={true}
                component={InputField}
              />
            )}
            <label className="input-group-addon btn btn-primary dm-btn is-gray" key="copy_button">
              {t('application.copy')}
              <button
                type="button"
                disabled={application.authentication_type !== 'oidc'}
                onClick={() => this.handleCopy('oidc_idp_redirect_url')}
              />
            </label>
          </div>
        </div>
      </>
    )
  }

  renderWebAdvancedFields = () => {
    const { t } = this.context
    const { application } = this.props

    const defaultNoticeModes = [
      { id: 'mute', name: t('application.web.advanced.notices.mute') },
      { id: 'sound', name: t('application.web.advanced.notices.sound') },
      { id: 'speech', name: t('application.web.advanced.notices.speech') },
    ]

    const defaultSpeakerNames = []
    defaultSpeakerNames.push({
      id: 'web_speech_api',
      name: t('application.web.advanced.speakers.webSpeechApi'),
    })
    if (isPermitted('feature_aitalk', this.context)) {
      defaultSpeakerNames.push({ id: 'miyabi', name: t('application.web.advanced.speakers.miyabi') })
      defaultSpeakerNames.push({ id: 'yamato', name: t('application.web.advanced.speakers.yamato') })
      defaultSpeakerNames.push({ id: 'nozomi', name: t('application.web.advanced.speakers.nozomi') })
      defaultSpeakerNames.push({ id: 'seiji', name: t('application.web.advanced.speakers.seiji') })
      defaultSpeakerNames.push({ id: 'chihiro', name: t('application.web.advanced.speakers.chihiro') })
      defaultSpeakerNames.push({ id: 'yuuto', name: t('application.web.advanced.speakers.yuuto') })
    }

    return (
      <div className="form-group" key="advanced">
        <Collapsible
          trigger={t('application.web.advanced.title')}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="default_notice_mode"
                className="dm-title-mini"
                name="application.web.advanced.notices.title"
              />
              <Field
                id="default_notice_mode"
                name="default_notice_mode"
                className="form-control dm-form-control"
                items={defaultNoticeModes}
                valueKey="id"
                displayKey="name"
                disabled={application.has_reservation}
                component={SelectField}
              />
            </div>
            <div className="form-group">
              <LabelWithTooltip
                htmlFor="default_speaker_name"
                className="dm-title-mini"
                name="application.web.advanced.speakers.title"
              />
              <Field
                id="default_speaker_name"
                name="default_speaker_name"
                className="form-control dm-form-control"
                items={defaultSpeakerNames}
                valueKey="id"
                displayKey="name"
                disabled={application.has_reservation}
                component={SelectField}
              />
            </div>
            <div className="form-group form-inline checkbox mb-2">
              <Field
                type="checkbox"
                name="keep_conversation_history"
                id="keep_conversation_history"
                className="form-control m-0"
                component={CheckboxField}
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                htmlFor="keep_conversation_history"
                className="dm-checkbox"
                name="application.web.advanced.keepConversationHistory"
              />
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }

  renderReservationPublishField = () => {
    const { application, formValues } = this.props
    const { timezone } = getCredentials(this.context)

    return (
      <div className="form-group">
        <div className="form-inline checkbox mb-2">
          <Field
            type="checkbox"
            name="has_reservation"
            id="has_reservation"
            className="form-control m-0"
            component={CheckboxField}
            disabled={application.has_reservation}
          />
          <LabelWithTooltip htmlFor="has_reservation" name="application.reservationPublish.title" />
        </div>
        {formValues.has_reservation && (
          <div className="with-indent">
            <div className="flex">
              <div className="col-lg-4">
                <Field
                  id="reserved_at.date"
                  name="reserved_at.date"
                  className="form-control dm-form-control"
                  type="date"
                  component={InputField}
                  min={moment().tz(timezone).format('YYYY-MM-DD')}
                  placeholder="YYYY/MM/DD"
                  required={true}
                  disabled={application.has_reservation}
                />
              </div>
              <div className="col-sm-4">
                <Field
                  id="reserved_at.time"
                  name="reserved_at.time"
                  className="form-control dm-form-control"
                  type="time"
                  component={InputField}
                  placeholder="hh:mm"
                  required={true}
                  disabled={application.has_reservation}
                  onChange={() => this.props.clearSubmitErrors()}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderPublishingButtons = () => {
    const { t } = this.context
    const {
      application,
      formValues,
      currentBot,
      submitting,
      handleSubmit,
      isFetchingApplication,
    } = this.props
    const { isInvalidImage, inDeleting } = this.state
    const inPublishing = this.inPublishing(application)
    const isCancellable = application && application.status === 'enqueued'

    if (formValues.has_reservation) return null

    const components = []
    components.push(
      <button
        key="publish"
        type="submit"
        className="btn btn-primary dm-btn"
        disabled={
          isFetchingApplication ||
          isInvalidImage ||
          submitting ||
          inPublishing ||
          inDeleting ||
          currentBot.dirty
        }
      >
        {t('common.publish')}
      </button>
    )
    if (inPublishing) {
      components.push(
        <button
          key="abort"
          type="button"
          className="btn btn-danger dm-btn"
          onClick={handleSubmit(this.handleAbort)}
          disabled={submitting || !isCancellable}
        >
          {t('application.abortPublishing')}
        </button>
      )
    }
    return components
  }

  renderReservationButtons = () => {
    const { t } = this.context
    const { application, formValues, submitting, handleSubmit, isFetchingApplication } = this.props
    const { isInvalidImage, inDeleting } = this.state
    const inPublishing = this.inPublishing(application)

    const components = []
    if (formValues.has_reservation) {
      components.push(
        <button
          key="reservation"
          type="submit"
          className="btn btn-primary dm-btn"
          disabled={
            isFetchingApplication ||
            isInvalidImage ||
            submitting ||
            inPublishing ||
            inDeleting ||
            application.has_reservation
          }
        >
          {t('common.reserve')}
        </button>
      )
    }
    if (application.has_reservation) {
      components.push(
        <button
          key="abort-reservation"
          type="button"
          className="btn btn-danger dm-btn"
          onClick={handleSubmit(this.handleAbortReservation)}
        >
          {t('application.abortReservation')}
        </button>
      )
    }
    return components
  }

  renderCopyableField(name) {
    const { t } = this.context
    const { application } = this.props
    const components = []

    if (this.inPublishing(application) || !this.isPublished(application)) {
      const message = this.inPublishing(application)
        ? t('application.inPublishingMessage')
        : t('application.isNotPublishedMessage')
      components.push(
        <input
          type="text"
          className="form-control dm-form-control"
          key={name}
          readOnly={true}
          value={message}
        />
      )
    } else {
      components.push(
        <Field
          key={name}
          type="text"
          name={name}
          className="form-control dm-form-control"
          readOnly={true}
          component={InputField}
        />
      )
    }

    components.push(
      <label className="input-group-addon btn btn-primary dm-btn is-gray" key="copy_button">
        {t('application.copy')}
        <button
          type="button"
          disabled={!this.isPublished(application)}
          onClick={() => this.handleCopy(name)}
        />
      </label>
    )

    return components
  }

  renderConstantDefinitions = () => {
    const { t } = this.context
    const { application } = this.props

    return (
      <div className="form-group constant-definitions" key="constant-definitions">
        <Collapsible
          trigger={
            <span>
              {t('application.constantDefinitions.title')}
              <Tooltip name="application.tooltip.constantDefinitions.title" />
            </span>
          }
          open={application.constant_definitions && application.constant_definitions.length > 0}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            <FieldArray name="constant_definitions" component={this.renderConstantDefinitionsField} />
          </div>
        </Collapsible>
      </div>
    )
  }

  renderConstantDefinitionsField = ({ fields }) => {
    const { t } = this.context
    const columns = [
      {
        Header: t('application.constantDefinitions.name'),
        Cell: (name, _index) => (
          <Field
            type="text"
            name={`${name}.name`}
            className="form-control dm-form-control"
            maxLength="30"
            component={InputField}
          />
        ),
      },
      {
        Header: (
          <React.Fragment>
            {t('application.constantDefinitions.value')}
            <Tooltip name="application.tooltip.constantDefinitions.value" />
          </React.Fragment>
        ),
        Cell: (name, rowIndex) => (
          <Field
            type={(fields.get(rowIndex) || {}).is_secret ? 'password' : 'text'}
            name={`${name}.value`}
            className="form-control dm-form-control"
            maxLength="1000"
            component={InputField}
            placeholder={(fields.get(rowIndex) || {}).is_secret ? '********' : null}
          />
        ),
      },
      {
        Header: index => (
          <th className="is-secret" key={index}>
            {t('application.constantDefinitions.secret')}
          </th>
        ),
        Cell: (name, _rowIndex, columnIndex) => (
          <td className="is-secret" key={columnIndex}>
            <Field
              type="checkbox"
              name={`${name}.is_secret`}
              className="form-control m-2"
              component={CheckboxField}
            />
          </td>
        ),
      },
    ]

    return <IncrementableTable fields={fields} columns={columns} />
  }

  renderWeb() {
    const { t } = this.context

    return (
      <div>
        {this.props.params.id && (
          <div className="form-group">
            <LabelWithTooltip htmlFor="token" className="dm-title-mini" name="application.web.token" />
            <div className="input-group">{this.renderCopyableField('token')}</div>
            <div className="input-group pt-1">
              <button
                type="button"
                className="btn btn-warning dm-btn"
                onClick={this.handleReissueApplicationToken}
                disabled={this.props.application.has_reservation}
              >
                {t('application.web.reissue')}
              </button>
            </div>
          </div>
        )}
        {this.renderConstantDefinitions()}
        {isPermitted('feature_push_api', this.context) && this.renderPushApiFields()}
        {isPermitted('feature_web_account_link_api', this.context) && this.renderWebAccountLinkApiFields()}
        {isPermitted('feature_idp_support', this.context) && this.renderIdPFields()}
        {this.renderWebAdvancedFields()}
      </div>
    )
  }

  renderLine() {
    const { t } = this.context
    const {
      application,
      formValues: { use_account_link, use_switcher_api },
    } = this.props

    // There are applications that don't have channel access token
    // They haven't been published yet or has been changed platform from LINE Switcher API to LINE by system
    const existChannelAccessToken = this.props.params.id && !application.channel_id

    const results = [
      <div className="form-group" key="channel_access_token">
        <LabelWithTooltip
          htmlFor="channel_access_token"
          className="dm-title-mini"
          name="application.line.channelAccessToken"
        />
        <Field
          type="password"
          name="channel_access_token"
          className="form-control dm-form-control"
          maxLength="255"
          component={InputField}
          placeholder={existChannelAccessToken ? '********' : ''}
          autoComplete="new-password"
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="channel_secret">
        <LabelWithTooltip
          htmlFor="channel_secret"
          className="dm-title-mini"
          name="application.line.channelSecret"
        />
        <Field
          type="password"
          name="channel_secret"
          className="form-control dm-form-control"
          maxLength="32"
          autoComplete="new-password"
          component={InputField}
          placeholder={this.props.params.id ? '********' : ''}
          disabled={application.has_reservation}
        />
      </div>,
    ]

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_line_switcher_api', this.context)) {
      const existSwitcherSecret = this.props.params.id && application.use_switcher_api
      results.push(
        <div className="form-group" key="switcher">
          <Collapsible
            trigger={t('application.line.switcher.title')}
            transitionTime={300}
            open={application.use_switcher_api}
            overflowWhenOpen="visible"
          >
            <div className="with-indent">
              <div className="form-group form-inline checkbox">
                <Field
                  type="checkbox"
                  className="form-control m-0"
                  name="use_switcher_api"
                  component={CheckboxField}
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip htmlFor="use_switcher_api" name="application.line.switcher.enabled" />
              </div>
              <div className="form-group form-inline checkbox">
                <Field
                  type="checkbox"
                  className="form-control m-0"
                  name="skip_follow_message"
                  component={CheckboxField}
                  disabled={!use_switcher_api || application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="skip_follow_message"
                  name="application.line.switcher.skipFollowMessage"
                />
              </div>
              <div className="form-group form-inline checkbox">
                <Field
                  type="checkbox"
                  className="form-control m-0"
                  name="disable_postback"
                  component={CheckboxField}
                  disabled={!use_switcher_api || application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="disable_postback"
                  name="application.line.switcher.disablePostback"
                />
              </div>
              <div className="form-group" key="service_code">
                <LabelWithTooltip
                  htmlFor="service_code"
                  className="dm-title-mini"
                  name="application.line.switcher.serviceCode"
                />
                <Field
                  type="text"
                  name="service_code"
                  className="form-control dm-form-control"
                  maxLength="36"
                  component={InputField}
                  disabled={!use_switcher_api || application.has_reservation}
                />
              </div>
              <div className="form-group" key="switcher_secret">
                <LabelWithTooltip
                  htmlFor="switcher_secret"
                  className="dm-title-mini"
                  name="application.line.switcher.switcherSecret"
                />
                <Field
                  type="password"
                  name="switcher_secret"
                  className="form-control dm-form-control"
                  maxLength="32"
                  placeholder={existSwitcherSecret ? '********' : ''}
                  autoComplete="new-password"
                  component={InputField}
                  disabled={!use_switcher_api || application.has_reservation}
                />
              </div>
            </div>
          </Collapsible>
        </div>
      )
    }

    if (isPermitted('feature_line_account_link', this.context)) {
      const contentTypes = ['application/json', 'application/x-www-form-urlencoded']

      results.push(
        <div className="form-group" key="account_link">
          <Collapsible
            trigger={t('application.line.accountLink.title')}
            transitionTime={300}
            open={application.use_account_link}
            overflowWhenOpen="visible"
          >
            <div className="with-indent">
              <div className="form-group form-inline checkbox mb-2">
                <Field
                  type="checkbox"
                  name="use_account_link"
                  id="use_account_link"
                  className="form-control m-0"
                  component={CheckboxField}
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip htmlFor="use_account_link" name="application.line.accountLink.enabled" />
              </div>
              <div>
                <div className="mt-3">
                  <LabelWithTooltip
                    htmlFor="external_service.login_url"
                    className="dm-title-mini"
                    name="application.line.accountLink.loginUrl"
                  />
                  <Field
                    name="external_service.login_url"
                    className="form-control dm-form-control"
                    component={InputField}
                    disabled={!use_account_link || application.has_reservation}
                    placeholder="https://example.com/login"
                  />
                </div>
                <div className="mt-3">
                  <LabelWithTooltip
                    htmlFor="external_service.fetch_user_id_url"
                    className="dm-title-mini"
                    name="application.line.accountLink.fetchUserIdUrl"
                  />
                  <Field
                    name="external_service.fetch_user_id_url"
                    className="form-control dm-form-control"
                    component={InputField}
                    disabled={!use_account_link || application.has_reservation}
                    placeholder="https://example.com/api/identity"
                  />
                </div>
                <div className="mt-3">
                  <LabelWithTooltip
                    htmlFor="external_service.unlink_url"
                    className="dm-title-mini"
                    name="application.line.accountLink.unlinkUrl"
                  />
                  <Field
                    name="external_service.unlink_url"
                    className="form-control dm-form-control"
                    component={InputField}
                    disabled={!use_account_link || application.has_reservation}
                    placeholder="https://example.com/api/unlink"
                  />
                </div>
                <div className="mt-3">
                  <span>
                    <label htmlFor="external_service.content_type" className="dm-title-mini">
                      Content-Type
                    </label>
                    <Tooltip name="application.tooltip.line.accountLink.contentTypeForPostRequest" />
                  </span>
                  <Field
                    name="external_service.content_type"
                    className="form-control dm-form-control"
                    component={SelectField}
                    items={contentTypes}
                    disabled={!use_account_link || application.has_reservation}
                  />
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
      )
    }

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }
    if (isPermitted('feature_line_pnp', this.context)) {
      results.push(this.renderPnpApiFields())
    }

    if (this.props.params.id) {
      results.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.line.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    results.push(
      <label key="note" className="dm-note strong">
        {t('application.line.note')}
        <ul>
          <li>{t('application.line.restrictedFeature1')}</li>
          <li>{t('application.line.restrictedFeature2')}</li>
          <li>{t('application.line.restrictedFeature3')}</li>
        </ul>
      </label>
    )

    return results
  }

  renderSlack() {
    const results = [
      <div className="form-group" key="access_token">
        <LabelWithTooltip
          htmlFor="access_token"
          className="dm-title-mini"
          name="application.slack.accessToken"
        />
        <Field
          type="password"
          name="access_token"
          className="form-control dm-form-control"
          autoComplete="new-password"
          maxLength="255"
          component={InputField}
          disabled={this.props.application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
        />
      </div>,
    ]

    if (this.props.params.id) {
      results.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.slack.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }

    results.push(this.renderSlackAdvancedFields())

    return results
  }

  renderSlackAdvancedFields = () => {
    const { t } = this.context
    const { application } = this.props

    return (
      <div className="form-group dm-form-group" key="advanced">
        <Collapsible
          trigger={t('application.slack.advanced.title')}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent form-group">
            <LabelWithTooltip className="dm-title-mini" name="application.slack.advanced.inThread.title" />
            <div className="form-inline">
              <Field
                name="slack_reaction_condition_in_thread"
                id="slack_reaction_condition_in_thread_mentioned"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="mentioned"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.inThread.reaction.mentioned"
                htmlFor="slack_reaction_condition_in_thread_mentioned"
              />
            </div>
            <div className="form-inline">
              <Field
                name="slack_reaction_condition_in_thread"
                id="slack_reaction_condition_in_thread_all"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="all"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.inThread.reaction.all"
                htmlFor="slack_reaction_condition_in_thread_all"
              />
            </div>
          </div>
          <div className="with-indent form-group">
            <LabelWithTooltip
              className="dm-title-mini"
              name="application.slack.advanced.outsideThread.title"
            />
            <div className="form-inline">
              <Field
                name="slack_reaction_condition_outside_thread"
                id="slack_reaction_condition_outside_thread_mentioned"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="mentioned"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.outsideThread.reaction.mentioned"
                htmlFor="slack_reaction_condition_outside_thread_mentioned"
              />
            </div>
            <div className="form-inline">
              <Field
                name="slack_reaction_condition_outside_thread"
                id="slack_reaction_condition_outside_thread_all"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="all"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.outsideThread.reaction.all"
                htmlFor="slack_reaction_condition_outside_thread_all"
              />
            </div>
          </div>
          <div className="with-indent form-group">
            <LabelWithTooltip
              className="dm-title-mini"
              name="application.slack.advanced.emojiReaction.title"
            />
            <div className="form-inline">
              <Field
                name="slack_reaction_to_emoji"
                id="slack_reaction_to_emoji_ignore"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="ignore"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.emojiReaction.reaction.ignore"
                htmlFor="slack_reaction_to_emoji_ignore"
              />
            </div>
            <div className="form-inline">
              <Field
                name="slack_reaction_to_emoji"
                id="slack_reaction_to_emoji_accept"
                className="form-control dm-form-control"
                component="input"
                type="radio"
                value="accept"
                disabled={application.has_reservation}
              />
              <LabelWithTooltip
                className="vertical-centering"
                name="application.slack.advanced.emojiReaction.reaction.accept"
                htmlFor="slack_reaction_to_emoji_accept"
              />
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }

  renderAzureBotService() {
    const results = [
      <div className="form-group" key="azure_bot_service_application_id">
        <LabelWithTooltip
          htmlFor="azure_bot_service_application_id"
          className="dm-title-mini"
          name="application.azureBotService.applicationId"
        />
        <Field
          type="text"
          name="azure_bot_service_application_id"
          className="form-control dm-form-control"
          maxLength="36"
          component={InputField}
        />
      </div>,
      <div className="form-group" key="azure_bot_service_password">
        <LabelWithTooltip
          htmlFor="azure_bot_service_password"
          className="dm-title-mini"
          name="application.azureBotService.password"
        />
        <Field
          type="password"
          name="azure_bot_service_password"
          className="form-control dm-form-control"
          maxLength="255"
          autoComplete="new-password"
          component={InputField}
          placeholder={this.props.params.id ? '********' : ''}
        />
      </div>,
    ]

    if (this.props.params.id) {
      results.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.azureBotService.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }

    return results
  }

  renderApplicationToken() {
    const { t } = this.context
    return (
      <div className="form-group">
        <LabelWithTooltip htmlFor="token" className="dm-title-mini" name="application.token" />
        <div className="input-group">
          <Field
            type="text"
            name="token"
            className="form-control dm-form-control"
            readOnly={true}
            component={InputField}
          />
          <span className="input-group-btn">
            <button
              type="button"
              className="btn btn-default dm-btn"
              onClick={this.handleReissueApplicationToken}
            >
              {t('application.reissue')}
            </button>
          </span>
        </div>
      </div>
    )
  }

  renderSkypeForBusiness() {
    const { params } = this.props
    const results = []

    if (params.id) {
      results.push(
        <div className="form-group" key="azure_bot_service_application_id">
          <LabelWithTooltip
            htmlFor="azure_bot_service_application_id"
            className="dm-title-mini"
            name="application.skypeForBusiness.applicationId"
          />
          <div className="input-group">{this.renderCopyableField('azure_bot_service_application_id')}</div>
        </div>
      )
    }

    return results
  }

  renderTeams() {
    const { t } = this.context
    const {
      application,
      params,
      isFetching,
      formValues: { use_allowed_domains },
    } = this.props
    const results = []

    if (isPermitted('feature_teams_restricted_access', this.context)) {
      results.push(
        <div className="form-group" key="allowed_domains">
          <LabelWithTooltip
            htmlFor="allowed_domains"
            className="dm-title-mini"
            name="application.teams.allowedDomains.title"
          />
          <div className="form-inline">
            <Field
              type="checkbox"
              name="use_allowed_domains"
              id="use_allowed_domains"
              component={CheckboxField}
              disabled={application.has_reservation}
            />
            <LabelWithTooltip
              htmlFor="use_allowed_domains"
              className="dm-checkbox"
              name="application.teams.allowedDomains.enabled"
            />
          </div>
          <Field
            name="allowed_domains"
            className="form-control dm-form-control"
            component={InputField}
            maxLength="10000"
            disabled={!use_allowed_domains || isFetching || application.has_reservation}
          />
          <div className="dm-note">{t('common.beta')}</div>
        </div>
      )
    }

    if (params.id) {
      results.push(
        <div className="form-group" key="azure_bot_service_application_id">
          <LabelWithTooltip
            htmlFor="azure_bot_service_application_id"
            className="dm-title-mini"
            name="application.teams.applicationId"
          />
          <div className="input-group">{this.renderCopyableField('azure_bot_service_application_id')}</div>
          <div className="dm-note">
            {t('application.teams.note3')}
            <br />
            {t('application.teams.note4')}
          </div>
        </div>
      )
    }

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }
    results.push(this.renderTeamsAdvancedFields())

    return results
  }

  renderTeamsAdvancedFields = () => {
    const { t } = this.context
    const { application } = this.props

    //  Hide entire block if all configurations are disabled
    if (!isPermitted('feature_logging_url_access', this.context)) return null

    return (
      <div className="form-group" key="advanced">
        <Collapsible
          trigger={t('application.teams.advanced.title')}
          transitionTime={300}
          overflowWhenOpen="visible"
        >
          <div className="with-indent">
            {isPermitted('feature_logging_url_access', this.context) && (
              <div className="form-group form-inline checkbox mb-2">
                <Field
                  type="checkbox"
                  name="enable_logging_url_access"
                  id="enable_logging_url_access"
                  className="form-control m-0"
                  component={CheckboxField}
                  disabled={application.has_reservation}
                />
                <LabelWithTooltip
                  htmlFor="enable_logging_url_access"
                  className="dm-checkbox"
                  name="application.teams.advanced.enableLoggingUrlAccess"
                />
              </div>
            )}
          </div>
        </Collapsible>
      </div>
    )
  }

  renderDirectLine() {
    const {
      application,
      params,
      isFetching,
      formValues: { use_allowed_addresses },
    } = this.props
    const results = []

    results.push(
      <div className="form-group" key="directline">
        <LabelWithTooltip
          htmlFor="allowed_addresses"
          className="dm-title-mini"
          name="application.directLine.allowedAddresses.title"
        />
        <div className="form-inline">
          <Field
            type="checkbox"
            name="use_allowed_addresses"
            id="use_allowed_addresses"
            value="false"
            component={CheckboxField}
            disabled={!isPermitted('feature_restricted_access', this.context) || application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="use_allowed_addresses"
            className="dm-checkbox"
            name="application.directLine.allowedAddresses.enabled"
          />
        </div>
        <Field
          name="allowed_addresses"
          className="form-control dm-form-control"
          component={InputField}
          maxLength="10000"
          disabled={
            !isPermitted('feature_restricted_access', this.context) ||
            !use_allowed_addresses ||
            isFetching ||
            application.has_reservation
          }
        />
      </div>
    )

    if (params.id) {
      results.push(
        <div className="form-group" key="direct_line_secret_key">
          <LabelWithTooltip
            htmlFor="direct_line_secret_key"
            className="dm-title-mini"
            name="application.directLine.secretKey"
          />
          <div className="input-group">{this.renderCopyableField('direct_line_secret_key')}</div>
        </div>
      )
    }

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }

    return results
  }

  renderMobilus() {
    const { t } = this.context
    const { application, mobilus_domain_type } = this.props

    const results = [
      <div className="form-group dm-form-group" key="mobilus_push_domain">
        <LabelWithTooltip
          htmlFor="mobilus_push_domain"
          className="dm-title-mini"
          name="application.mobilus.mobilus_push_domain.title"
        />
        <div className="form-inline">
          <Field
            name="mobilus_domain_type"
            id="mobilus_domain_type_production"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="production"
            disabled={application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="mobilus_domain_type_production"
            className="vertical-centering"
            name="application.mobilus.mobilus_push_domain.production"
          />
        </div>
        <div className="form-inline">
          <Field
            name="mobilus_domain_type"
            id="mobilus_domain_type_trial"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="trial"
            disabled={application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="mobilus_domain_type_trial"
            className="vertical-centering"
            name="application.mobilus.mobilus_push_domain.trial"
          />
        </div>
        <div className="form-inline">
          <Field
            name="mobilus_domain_type"
            id="mobilus_domain_type_custom"
            className="form-control dm-form-control"
            component="input"
            type="radio"
            value="custom"
            disabled={application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="mobilus_domain_type_custom"
            className="vertical-centering"
            name="application.mobilus.mobilus_push_domain.custom"
          />
        </div>
        {mobilus_domain_type === 'custom' && (
          <div className="ml-3 form-inline">
            <LabelWithTooltip
              className="col-sm-2"
              name="application.mobilus.mobilus_push_domain.customDomain"
            />
            <Field
              name="mobilus_push_domain"
              id="mobilus_push_domain"
              className="col-sm-10 form-control dm-form-control"
              component={InputField}
              type="text"
              size="30"
              maxLength="255"
              disabled={application.has_reservation}
              placeholder={t('application.mobilus.mobilus_push_domain.placeholder')}
            />
          </div>
        )}
      </div>,
      <div className="form-group" key="mobilus_tenant_id">
        <LabelWithTooltip
          htmlFor="mobilus_tenant_id"
          className="dm-title-mini"
          name="application.mobilus.mobilus_tenant_id"
        />
        <Field
          type="text"
          name="mobilus_tenant_id"
          className="form-control dm-form-control"
          maxLength="255"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="mobilus_user_id">
        <LabelWithTooltip
          htmlFor="mobilus_user_id"
          className="dm-title-mini"
          name="application.mobilus.mobilus_user_id"
        />
        <Field
          type="text"
          name="mobilus_user_id"
          className="form-control dm-form-control"
          maxLength="255"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="mobilus_api_key">
        <LabelWithTooltip
          htmlFor="mobilus_api_key"
          className="dm-title-mini"
          name="application.mobilus.mobilus_api_key"
        />
        <Field
          type="password"
          name="mobilus_api_key"
          className="form-control dm-form-control"
          autoComplete="new-password"
          maxLength="255"
          component={InputField}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
        />
      </div>,
    ]

    if (this.props.params.id) {
      results.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.mobilus.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>,
        <div className="form-group" key="mobilus_signature_key_string">
          <LabelWithTooltip
            htmlFor="mobilus_signature_key_string"
            className="dm-title-mini"
            name="application.mobilus.mobilus_signature_key_string"
          />
          <div className="input-group">{this.renderCopyableField('mobilus_signature_key_string')}</div>
          <div className="input-group pt-1">
            <button
              type="button"
              className="btn btn-warning dm-btn"
              disabled={application.has_reservation}
              onClick={this.handleMobilusReissueApplicationToken}
            >
              {t('application.mobilus.reissue')}
            </button>
          </div>
        </div>
      )
    }

    results.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      results.push(this.renderPushApiFields())
    }

    return results
  }

  renderLINEWorksV1() {
    const { application } = this.props
    const fields = [
      <div className="form-group" key="line_works_api_id">
        <LabelWithTooltip
          htmlFor="line_works_api_id"
          className="dm-title-mini"
          name="application.lineWorksV1.apiId"
        />
        <Field
          type="text"
          name="line_works_api_id"
          className="form-control dm-form-control"
          maxLength="13"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="line_works_server_api_consumer_key">
        <LabelWithTooltip
          htmlFor="line_works_server_api_consumer_key"
          className="dm-title-mini"
          name="application.lineWorksV1.serverApiConsumerKey"
        />
        <Field
          type="password"
          name="line_works_server_api_consumer_key"
          className="form-control dm-form-control"
          maxLength="20"
          component={InputField}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
          autoComplete="new-password"
        />
      </div>,
      <div className="form-group" key="line_works_server_id">
        <LabelWithTooltip
          htmlFor="line_works_server_id"
          className="dm-title-mini"
          name="application.lineWorksV1.serverId"
        />
        <Field
          type="text"
          name="line_works_server_id"
          className="form-control dm-form-control"
          maxLength="32"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="line_works_server_authentication_key">
        <LabelWithTooltip
          htmlFor="line_works_server_authentication_key"
          className="dm-title-mini"
          name="application.lineWorksV1.serverAuthenticationKey"
        />
        <Field
          type="password"
          name="line_works_server_authentication_key"
          className="form-control dm-form-control"
          component={TextAreaField}
          minRows={4}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
          autoComplete="new-password"
        />
      </div>,
    ]

    if (this.props.params.id) {
      fields.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.lineWorksV1.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    fields.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      fields.push(this.renderPushApiFields())
    }

    return fields
  }

  renderLINEWorksV2() {
    const { application } = this.props
    const fields = [
      <div className="form-group" key="line_works_clinet_id">
        <LabelWithTooltip
          htmlFor="line_works_clinet_id"
          className="dm-title-mini"
          name="application.lineWorksV2.clientId"
        />
        <Field
          type="text"
          name="line_works_client_id"
          className="form-control dm-form-control"
          maxLength="20"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="line_works_client_secret">
        <LabelWithTooltip
          htmlFor="line_works_client_secret"
          className="dm-title-mini"
          name="application.lineWorksV2.clientSecret"
        />
        <Field
          type="password"
          name="line_works_client_secret"
          className="form-control dm-form-control"
          maxLength="10"
          component={InputField}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
          autoComplete="new-password"
        />
      </div>,
      <div className="form-group" key="line_works_service_account">
        <LabelWithTooltip
          htmlFor="line_works_service_account"
          className="dm-title-mini"
          name="application.lineWorksV2.serviceAccount"
        />
        <Field
          type="text"
          name="line_works_service_account"
          className="form-control dm-form-control"
          maxLength="255"
          component={InputField}
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="line_works_private_key">
        <LabelWithTooltip
          htmlFor="line_works_private_key"
          className="dm-title-mini"
          name="application.lineWorksV2.privateKey"
        />
        <Field
          type="password"
          name="line_works_private_key"
          className="form-control dm-form-control"
          component={TextAreaField}
          minRows={4}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
          autoComplete="new-password"
        />
      </div>,
      <div className="form-group" key="line_works_bot_secret">
        <LabelWithTooltip
          htmlFor="line_works_bot_secret"
          className="dm-title-mini"
          name="application.lineWorksV2.botSecret"
        />
        <Field
          type="password"
          name="line_works_bot_secret"
          className="form-control dm-form-control"
          maxLength="30"
          component={InputField}
          disabled={application.has_reservation}
          placeholder={this.props.params.id ? '********' : ''}
          autoComplete="new-password"
        />
      </div>,
    ]

    if (this.props.params.id) {
      fields.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.lineWorksV2.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    fields.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      fields.push(this.renderPushApiFields())
    }

    return fields
  }

  renderAPIv1() {
    const { t } = this.context
    const {
      application,
      isFetching,
      formValues: { message_format, use_allowed_addresses },
    } = this.props
    const messageFormats = [{ id: 'dialogplay', name: t('application.apiv1.messageFormats.dialogplay') }]
    if (isPermitted('feature_directline_message_format', this.context)) {
      messageFormats.push({ id: 'directline', name: t('application.apiv1.messageFormats.directline') })
    }
    const fields = [
      <div className="form-group" key="allowed_addresses">
        <LabelWithTooltip
          htmlFor="allowed_addresses"
          className="dm-title-mini"
          name="application.apiv1.allowedAddresses.title"
        />
        <div className="form-inline">
          <Field
            type="checkbox"
            name="use_allowed_addresses"
            id="use_allowed_addresses"
            component={CheckboxField}
            disabled={!isPermitted('feature_restricted_access', this.context) || application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="use_allowed_addresses"
            className="dm-checkbox"
            name="application.apiv1.allowedAddresses.enabled"
          />
        </div>
        <Field
          name="allowed_addresses"
          className="form-control dm-form-control"
          component={InputField}
          maxLength="10000"
          disabled={
            !isPermitted('feature_restricted_access', this.context) ||
            !use_allowed_addresses ||
            isFetching ||
            application.has_reservation
          }
        />
      </div>,
      <div className="form-group" key="message_format">
        <LabelWithTooltip
          htmlFor="message_format"
          className="dm-title-mini"
          name="application.apiv1.messageFormat"
        />
        <Field
          name="message_format"
          className="form-control dm-form-control"
          component={SelectField}
          items={messageFormats}
          valueKey="id"
          displayKey="name"
          disabled={application.has_reservation}
        />
      </div>,
      <div className="form-group" key="use_simple_conversation">
        <LabelWithTooltip className="dm-title-mini" name="application.apiv1.useSimpleConversation.title" />
        <div className="form-inline">
          <Field
            type="checkbox"
            name="use_simple_conversation"
            id="use_simple_conversation"
            component={CheckboxField}
            disabled={application.has_reservation}
          />
          <LabelWithTooltip
            htmlFor="use_simple_conversation"
            className="dm-checkbox"
            name="application.apiv1.useSimpleConversation.enabled"
          />
        </div>
      </div>,
      this.props.params.id && (
        <div className="form-group" key="client_id">
          <LabelWithTooltip htmlFor="token" className="dm-title-mini" name="application.apiv1.clientId" />
          <div className="input-group">{this.renderCopyableField('token')}</div>
        </div>
      ),
      this.props.params.id && (
        <div className="form-group" key="client_secret">
          <LabelWithTooltip htmlFor="token" className="dm-title-mini" name="application.apiv1.clientSecret" />
          <div className="input-group">{this.renderCopyableField('secret')}</div>
        </div>
      ),
      this.renderConstantDefinitions(),
      isPermitted('feature_push_api', this.context) && this.renderPushApiFields(),
      message_format === 'dialogplay' && (
        <div className="advanced-menu" key="advanced_menu">
          <Collapsible
            trigger={t('application.apiv1.advancedSettings.title')}
            transitionTime={300}
            overflowWhenOpen="visible"
          >
            <div className="with-indent">
              <div className="form-group">
                <LabelWithTooltip
                  className="dm-title-mini"
                  name="application.apiv1.advancedSettings.useLineExtra.title"
                />
                <div className="form-inline">
                  <Field
                    type="checkbox"
                    name="use_line_extra"
                    id="use_line_extra"
                    component={CheckboxField}
                    disabled={application.has_reservation}
                  />
                  <LabelWithTooltip
                    htmlFor="use_line_extra"
                    className="dm-checkbox"
                    name="application.apiv1.advancedSettings.useLineExtra.enabled"
                  />
                </div>
              </div>
            </div>
          </Collapsible>
        </div>
      ),
    ]

    return fields
  }

  renderHangouts() {
    const fields = [
      <div className="form-group" key="access_token">
        <LabelWithTooltip
          htmlFor="access_token"
          className="dm-title-mini"
          name="application.hangouts.serviceAccount"
        />
        <Field
          type="file"
          name="service_account_file"
          className="form-control dm-form-control"
          component={FileField}
          disabled={this.props.application.has_reservation}
        />
      </div>,
      <div className="form-group" key="project_no">
        <LabelWithTooltip
          htmlFor="project_no"
          className="dm-title-mini"
          name="application.hangouts.projectNo"
        />
        <Field
          type="text"
          name="project_no"
          className="form-control dm-form-control"
          maxLength="12"
          component={InputField}
          disabled={this.props.application.has_reservation}
        />
      </div>,
    ]

    if (this.props.params.id) {
      fields.push(
        <div className="form-group" key="callback_url">
          <LabelWithTooltip
            htmlFor="callback_url"
            className="dm-title-mini"
            name="application.hangouts.callbackUrl"
          />
          <div className="input-group">{this.renderCopyableField('callback_url')}</div>
        </div>
      )
    }

    fields.push(this.renderConstantDefinitions())

    if (isPermitted('feature_push_api', this.context)) {
      fields.push(this.renderPushApiFields())
    }

    return fields
  }
}

const ApplicationEditForm = reduxForm({
  form: 'ApplicationEdit',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate,
})(ApplicationEdit)

const selector = formValueSelector('ApplicationEdit')

export const mapStateToProps = (state, props) => {
  const account = state.session.account || {}
  const credentials = {
    ...account,
    token: state.session.token,
    role: state.session.role,
  }
  const application = {
    platform: account.plan && !isPermittedCredentials('platform_web', credentials) ? 'line' : 'web',
    operator_function: false,
    message_format: 'dialogplay',
    mobilus_push_domain: 'agent.mobilus.me',
    notification_api_service: {},
    pnp_api_service: {},
    web_account_link_api_service: {},
    restrict_image_file_uploading: false,
    keep_conversation_history: true,
    enable_translation: true,
    enable_logging_url_access: false,
    default_notice_mode: 'mute',
    default_speaker_name: 'web_speech_api',
    authentication_type: 'none',
    slack_reaction_condition_in_thread: 'mentioned',
    slack_reaction_condition_outside_thread: 'mentioned',
    slack_reaction_to_emoji: 'ignore',
    ...(state.entities.applications[props.params.id] || {}),
  }
  const timezone = account.timezone ? account.timezone : 'Asia/Tokyo'

  const initialValues = { ...application }
  if (!initialValues.external_service) {
    initialValues.external_service = {
      content_type: 'application/json',
    }
  }
  if (!initialValues.callback_url) {
    initialValues.callback_url = ''
  }
  if (initialValues.reserved_at) {
    const reserved_at = moment.tz(initialValues.reserved_at, timezone)
    initialValues.reserved_at = {
      date: reserved_at.format('YYYY-MM-DD'),
      time: reserved_at.format('HH:mm'),
    }
  }
  if (initialValues.authentication_type === 'oidc') {
    initialValues.oidc_idp_redirect_url = `https://${initialValues.token}.auth.ap-northeast-1.amazoncognito.com/oauth2/idpresponse`
  }

  const bots = lodash.filter(state.entities.bots)
  const bot_id = (application.latest_bot || {}).original_id
  const formValues = getFormValues('ApplicationEdit')(state) || {}
  if (props.params.id) {
    if (!lodash.includes(['web', 'simulator'], application.platform) && application.callback_path) {
      const messagingApiServer = Config.messagingServer ? Config.messagingServer : Config.server
      initialValues.callback_url = messagingApiServer + application.callback_path
    }
  }

  const currentBotId = parseInt(selector(state, 'bot_id'), 10)
  const currentBot = state.entities.bots[currentBotId] || {}
  const platform = selector(state, 'platform')

  const embeddedColors = application.colors && JSON.parse(application.colors)
  const isDirtyCustomize = isDirty('ApplicationEdit')(state, [
    'embedded_title',
    'embedded_position',
    'embedded_launcher_type',
    'embedded_closer_type',
    'launcher_image_file',
    'embedded_theme',
    'embedded_font_size',
    'fit_height',
    'auto_hide_scrollbar',
    'colors',
    'auto_popup',
    'auto_popup_seconds',
  ])

  let initialMobilusDomainType
  if (application.mobilus_push_domain === 'agent.mobilus.me') {
    initialMobilusDomainType = 'production'
  } else if (application.mobilus_push_domain === 'agent.trial-mobilus.chat') {
    initialMobilusDomainType = 'trial'
  } else {
    initialMobilusDomainType = 'custom'
  }

  return {
    platform: platform,
    application,
    formValues,
    bots,
    currentBot,
    isFetching: isFetching(state),
    isFetchingApplication: isFetching(state, 'applications'),
    isDirtyCustomize,
    mobilus_domain_type: selector(state, 'mobilus_domain_type'),
    timezone,
    initialValues: {
      ...initialValues,
      operator_function: application.operator_function ? application.operator_function.toString() : 'false',
      bot_id,
      embedded_title:
        application.embedded_title !== null && application.embedded_title !== undefined
          ? application.embedded_title
          : application.name,
      embedded_position: application.embedded_position ? application.embedded_position : 'position-right',
      embedded_launcher_type: application.embedded_launcher_type
        ? application.embedded_launcher_type
        : 'title-header',
      embedded_closer_type: application.embedded_closer_type ? application.embedded_closer_type : 'header',
      embedded_theme: application.embedded_theme ? application.embedded_theme : 'theme-blue',
      embedded_font_size: application.embedded_font_size ? application.embedded_font_size : 0,
      embedded_pause: application.embedded_pause != null ? application.embedded_pause : true,
      colors: embeddedColors ? embeddedColors : defaultColors,
      fit_height: application.fit_height ? application.fit_height : false,
      auto_hide_scrollbar: application.auto_hide_scrollbar ? application.auto_hide_scrollbar : false,
      mobilus_domain_type: initialMobilusDomainType,
      auto_popup: application.auto_popup ? application.auto_popup : false,
      auto_popup_seconds: application.auto_popup ? application.auto_popup_seconds : 15,
    },
  }
}

export default connect(mapStateToProps)(ApplicationEditForm)
