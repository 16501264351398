import lodash from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Collapse from 'react-collapse'
import { connect } from 'react-redux'
import { Field, Fields, reduxForm, propTypes, getFormValues } from 'redux-form'
import Sticky from 'react-sticky-state'

import { addNotice } from '../../actions/notice'
import { fetchApplications } from '../../actions/application'
import { fetchBot } from '../../actions/bot'
import { fetchFaq } from '../../actions/faq'
import { fetchFaqCategories } from '../../actions/faq_category'
import {
  fetchFaqItem,
  updateFaqItem,
  createFaqItem,
  deleteFaqItem,
  generateFaqAnswer,
} from '../../actions/faq_item'
import { resetChannel } from '../../actions/embedded'

import Loader from '../../components/common/Loader'
import Sidebar from '../../components/common/Sidebar'
import LabelWithTooltip from '../../components/common/LabelWithTooltip'
import Tooltip from '../../components/common/Tooltip'
import { InputField, AutoCompleteField } from '../../components/common/fields/FormFields'
import FaqMatchingTab from '../../components/faq/FaqMatchingTab'
import Simulator from '../../containers/simulator/Simulator'

import Config from '../../helpers/config'
import { lengthWithoutVariableAndUrl } from '../../helpers/validation'
import { checkApplications } from '../../helpers/checkApplications'
import { isFetching } from '../../helpers/selector'
import cancelUrl, { isDirtyForm } from '../../helpers/cancelurl'
import { getReplaceSystemVariableValue } from '../../helpers/replaceSystemVariable'
import Trainer from '../bot/Trainer'

const validate = (data, props) => {
  const errors = {}
  // name
  if (!data.name) {
    errors.name = 'validate.required'
  } else if (data.name.length > 255) {
    errors.name = { id: 'validate.exceededMaxLength', values: { length: 255 } }
  } else if (data.name.match(/^\[.*\]$/)) {
    errors.name = 'validate.reservedName'
  }
  // answers
  if (lodash.compact(data.answers).length === 0) {
    errors.answers = ['validate.required']
  } else {
    const faqAnswersErrors = lodash.compact(data.answers).map(answer => {
      if (!answer) return {}
      if (lengthWithoutVariableAndUrl(answer) > props.maxLengthFaqAnswer || answer.length > 2000) {
        return {
          id: 'validate.exceededMaxLength',
          values: { length: props.maxLengthFaqAnswer },
        }
      }
      return {}
    })
    if (!lodash.every(faqAnswersErrors, lodash.isEmpty)) errors.answers = faqAnswersErrors
  }
  // questions
  const squareBracketRegexp = /\[\[([^[\]]*?)\]\]/g
  const faqQuestionErrors = lodash.compact(data.questions).map(question => {
    if (question.length > 280) {
      return { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    if (lodash.size(question.match(squareBracketRegexp)) > Config.maximumEntitySpecificationNum) {
      return {
        id: 'validate.exceededMaxEntitySpecification',
        values: { length: Config.maximumEntitySpecificationNum },
      }
    }
    return {}
  })
  if (!lodash.every(faqQuestionErrors, lodash.isEmpty)) errors.questions = faqQuestionErrors

  if (lodash.uniq(lodash.compact(data.questions)).length !== lodash.compact(data.questions).length) {
    errors.questions = { _error: 'validate.faqQuestionDuplicated' }
  }
  // patterns
  const patternErrors = data.patterns.map(pattern => {
    if (!pattern) return {}
    if (pattern.length > 280) {
      return { id: 'validate.exceededMaxLength', values: { length: 280 } }
    }
    return {}
  })
  if (!lodash.every(patternErrors, lodash.isEmpty)) {
    errors.patterns = patternErrors
  }

  if (lodash.uniq(lodash.compact(data.patterns)).length !== lodash.compact(data.patterns).length) {
    errors.patterns = { _error: 'validate.patternDuplicated' }
  }

  // categories
  errors.categories = lodash.map(data.categories, (categoryName, index) => {
    if (!categoryName && lodash.some(data.categories.slice(index + 1, 5))) {
      return 'validate.required'
    }

    if (categoryName && categoryName.length > 40) {
      return { id: 'validate.exceededMaxLength', values: { length: 40 } }
    }
    return null
  })

  return errors
}

export const convertSystemVariableOfFaqItem = (faqItem, isSave) => {
  lodash.forEach(faqItem.answers, (answer, index) => {
    const afterValue = getReplaceSystemVariableValue(answer, isSave, true)
    if (afterValue !== answer) {
      lodash.set(faqItem, `answers[${index}]`, afterValue)
    }
  })
}

export class FaqItemEdit extends Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  }
  static propTypes = {
    ...propTypes,
    dispatch: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    isGenerating: PropTypes.bool,
    params: PropTypes.shape({
      bot_id: PropTypes.string,
      faq_id: PropTypes.string,
      id: PropTypes.string,
    }),
    faq: PropTypes.object,
    root_category: PropTypes.object,
    category1: PropTypes.object,
    category2: PropTypes.object,
    category3: PropTypes.object,
    category4: PropTypes.object,
    item: PropTypes.object,
    applications: PropTypes.array,
    currentChannel: PropTypes.string,
    defaultOpenedFaqMatching: PropTypes.bool,
    maxLengthFaqAnswer: PropTypes.number.isRequired,
    formValues: PropTypes.object,
  }

  constructor() {
    super()
    this.state = { isOpened: false }
  }

  componentDidMount() {
    const state = this.context.store.getState()
    const {
      dispatch,
      params: { bot_id, faq_id, id },
    } = this.props
    dispatch(fetchBot(state.session.token, bot_id))
    dispatch(fetchFaq(state.session.token, faq_id))
    dispatch(fetchFaqCategories(state.session.token, faq_id))

    const questions = this.props.location?.state?.questions || []
    const answer = this.props.location?.state?.answer
    if (id) {
      dispatch(fetchFaqItem(state.session.token, faq_id, id)).then(() => {
        questions.forEach(question => {
          this.props.array.push('questions', question)
        })
      })
    } else {
      questions.forEach(question => {
        this.props.array.push('questions', question)
      })
      if (answer) {
        this.props.array.push('answers', answer)
      }
    }

    dispatch(fetchApplications(state.session.token, { original_bot_id: this.props.faq.bot_id }))
    cancelUrl.setRouteLeaveHook(this)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    if (this.controller) {
      this.controller.abort()
    }
  }

  toggleCategories = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  handleSave = (data, dispatch, notice = true) => {
    const {
      faq,
      params: { bot_id },
    } = this.props
    const { t, router } = this.context
    const session = this.context.store.getState().session

    if (this.controller) {
      this.controller.abort()
    }

    let faqItem = {
      name: data.name,
      categories: lodash.compact(data.categories.map(category => category && category.trim())),
      questions: lodash.compact(data.questions.map(question => question && question.trim())),
      patterns: lodash.compact(data.patterns.map(pattern => pattern && pattern.trim())),
      answers: lodash.compact(data.answers.map(answer => answer && answer.trimEnd())),
    }

    convertSystemVariableOfFaqItem(faqItem, true)

    const isNeedExpandFaqAnswer = faqItem.answers.some(answer => lengthWithoutVariableAndUrl(answer) > 280)
    if (this.props.params.id) {
      faqItem.id = this.props.params.id
      return dispatch(resetChannel(this.props.currentChannel))
        .then(() => dispatch(updateFaqItem(session.token, faq, faqItem)))
        .then(() => dispatch(fetchBot(session.token, bot_id)))
        .then(() => dispatch(fetchFaqCategories(session.token, faq.id)))
        .then(() => {
          return notice ? dispatch(addNotice('info', t('common.saveSuccessMessage'))) : false
        })
        .then(() => {
          if (isNeedExpandFaqAnswer) dispatch(addNotice('warn', t('faqItem.exceededAnswer')))
        })
        .then(() => {
          if (notice) {
            checkApplications(faq.bot_id, this.props.applications, dispatch, this.context)
          }
        })
    } else {
      return dispatch(createFaqItem(session.token, faq, faqItem))
        .then(json =>
          router.push({
            pathname: `/bots/${bot_id}/faqs/${faq.id}/items/${json.result}`,
            state: { ignoreBlocking: true },
          })
        )
        .then(() => dispatch(fetchBot(session.token, bot_id)))
        .then(() => dispatch(fetchFaqCategories(session.token, faq.id)))
        .then(() => cancelUrl.setRouteLeaveHook(this))
        .then(() => {
          return notice ? dispatch(addNotice('info', t('common.saveSuccessMessage'))) : false
        })
        .then(() => {
          if (isNeedExpandFaqAnswer) dispatch(addNotice('warn', t('faqItem.exceededAnswer')))
        })
        .then(() => {
          if (notice) {
            checkApplications(faq.bot_id, this.props.applications, dispatch, this.context)
          }
        })
    }
  }

  handleDelete = () => {
    const { t, router } = this.context
    const {
      dispatch,
      applications,
      faq,
      item,
      params: { bot_id, id },
    } = this.props
    const state = this.context.store.getState()

    if (this.controller) {
      this.controller.abort()
    }

    if (!window.confirm(t('common.deleteConfirmMessage', { type: t('faqItem.faqItem'), name: item.name })))
      return

    return dispatch(resetChannel(this.props.currentChannel))
      .then(() => dispatch(deleteFaqItem(state.session.token, id, faq.id)))
      .then(() => dispatch(fetchBot(state.session.token, bot_id)))
      .then(() =>
        router.push({ pathname: `/bots/${bot_id}/faqs/${faq.id}`, state: { ignoreBlocking: true } })
      )
      .then(() => dispatch(addNotice('info', t('common.deleteSuccessMessage'))))
      .then(() => checkApplications(bot_id, applications, dispatch, this.context))
  }

  handleCategoryValueChanged = (name, newValue, previousValue) => {
    const { dispatch, clearFields, form } = this.props
    if (newValue !== previousValue) {
      if (name === 'category1') {
        dispatch(
          clearFields(form, false, false, 'categories[1]', 'categories[2]', 'categories[3]', 'categories[4]')
        )
      } else if (name === 'category2') {
        dispatch(clearFields(form, false, false, 'categories[2]', 'categories[3]', 'categories[4]'))
      } else if (name === 'category3') {
        dispatch(clearFields(form, false, false, 'categories[3]', 'categories[4]'))
      } else if (name === 'category4') {
        dispatch(clearFields(form, false, false, 'categories[4]'))
      }
    }
  }

  generateFaqAnswer = () => {
    const { dispatch, params, formValues } = this.props
    const { name, questions } = formValues
    const { t } = this.context
    const session = this.context.store.getState().session

    const timmedName = (name || '').trim()
    const trimmedQuestions = lodash.compact(questions.map(question => question && question.trim()))
    if (!timmedName || trimmedQuestions.length === 0) {
      dispatch(addNotice('error', t('faqItem.answerGenerator.validationErrorMessage')))
      return
    }

    this.controller = new window.AbortController()

    dispatch(
      generateFaqAnswer(
        session.token,
        params.faq_id,
        params.id,
        timmedName,
        lodash.sample(trimmedQuestions),
        {
          signal: this.controller.signal,
        }
      )
    ).then(response => {
      this.props.change('answers[0]', response.answer)
      this.controller = undefined
    })
  }

  render() {
    const { t } = this.context
    const { isOpened } = this.state
    const {
      bot,
      faq,
      root_category,
      category1,
      category2,
      category3,
      category4,
      isFetching,
      submitting,
      handleSubmit,
      defaultOpenedFaqMatching,
      formValues,
    } = this.props
    const handleSave = handleSubmit(this.handleSave)

    const hierarchicalCategory =
      lodash.dropRightWhile(formValues?.categories || [], lodash.isEmpty).join(' > ') ||
      t('faqItem.unspecified')
    return (
      <div>
        <Loader loaded={!isFetching && !submitting} type="show">
          <div className="dm-faq-items">
            <form className="text-left col-md-9" onSubmit={handleSave}>
              {/* Faq item name */}
              <div className="form-group">
                <LabelWithTooltip htmlFor="name" className="dm-title" name="faqItem.name" />
                <Field
                  type="text"
                  name="name"
                  className="form-control dm-form-control"
                  maxLength="255"
                  component={InputField}
                />
              </div>

              {/* Faq item questions / Patterns */}
              <div className="form-group">
                <Fields
                  names={['questions', 'patterns']}
                  component={FaqMatchingTab}
                  defaultOpened={defaultOpenedFaqMatching}
                />
              </div>

              {/* Faq categories */}
              <div className={isOpened ? 'form-group is-open' : 'form-group'}>
                <span className="toggleCategories" onClick={() => this.toggleCategories()}>
                  <label className="dm-title">{t('faqItem.categories')}</label>
                  <span>(</span>
                  <span className="hierarchical-categories">{hierarchicalCategory}</span>
                  <span>)</span>
                  <Tooltip name="faqItem.tooltip.categories" />
                  <span type="button" className="btn btn-link glyphicon glyphicon-collapse-down" />
                </span>
                <Collapse isOpened={isOpened}>
                  <div className="form-group dm-form-group row">
                    <LabelWithTooltip
                      htmlFor="categories[0]"
                      className="dm-label indent form-inline col-sm-2"
                      name="faqItem.category1"
                    />
                    <div className="col-sm-10">
                      <Field
                        type="text"
                        name="categories[0]"
                        className="form-control dm-form-control"
                        maxLength={40}
                        component={AutoCompleteField}
                        items={root_category ? root_category.categories : []}
                        displayKey="name"
                        valueKey="name"
                        onChange={(event, newValue, previousValue) =>
                          this.handleCategoryValueChanged('category1', newValue, previousValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group dm-form-group row">
                    <LabelWithTooltip
                      htmlFor="categories[1]"
                      className="dm-label indent form-inline col-sm-2"
                      name="faqItem.category2"
                    />
                    <div className="col-sm-10">
                      <Field
                        type="text"
                        name="categories[1]"
                        className="form-control dm-form-control"
                        maxLength={40}
                        component={AutoCompleteField}
                        items={category1 ? category1.categories : []}
                        displayKey="name"
                        valueKey="name"
                        onChange={(event, newValue, previousValue) =>
                          this.handleCategoryValueChanged('category2', newValue, previousValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group dm-form-group row">
                    <LabelWithTooltip
                      htmlFor="categories[2]"
                      className="dm-label indent form-inline col-sm-2"
                      name="faqItem.category3"
                    />
                    <div className="col-sm-10">
                      <Field
                        type="text"
                        name="categories[2]"
                        className="form-control dm-form-control"
                        maxLength={40}
                        component={AutoCompleteField}
                        items={category2 ? category2.categories : []}
                        displayKey="name"
                        valueKey="name"
                        onChange={(event, newValue, previousValue) =>
                          this.handleCategoryValueChanged('category3', newValue, previousValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group dm-form-group row">
                    <LabelWithTooltip
                      htmlFor="categories[3]"
                      className="dm-label indent form-inline col-sm-2"
                      name="faqItem.category4"
                    />
                    <div className="col-sm-10">
                      <Field
                        type="text"
                        name="categories[3]"
                        className="form-control dm-form-control"
                        maxLength={40}
                        component={AutoCompleteField}
                        items={category3 ? category3.categories : []}
                        displayKey="name"
                        valueKey="name"
                        onChange={(event, newValue, previousValue) =>
                          this.handleCategoryValueChanged('category4', newValue, previousValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group dm-form-group row">
                    <LabelWithTooltip
                      htmlFor="categories[4]"
                      className="dm-label indent form-inline col-sm-2"
                      name="faqItem.category5"
                    />
                    <div className="col-sm-10">
                      <Field
                        type="text"
                        name="categories[4]"
                        className="form-control dm-form-control"
                        maxLength={40}
                        component={AutoCompleteField}
                        items={category4 ? category4.categories : []}
                        displayKey="name"
                        valueKey="name"
                      />
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* Faq item answer */}
              <div className="form-group">
                <LabelWithTooltip htmlFor="answers" className="dm-title" name="faqItem.answer" />
              </div>

              {/* Save and SaveTrain and Delete bottons */}
              <Sticky>
                <div className="sticky bottom">
                  <div className="dm-sticky-buttons">
                    <button
                      type="submit"
                      className="btn btn-primary dm-btn"
                      disabled={submitting}
                      onClick={handleSave}
                    >
                      {t('common.save')}
                    </button>
                    <Trainer
                      bot={bot}
                      handleSubmit={handleSubmit}
                      handleSave={handleSave}
                      isSubmitting={submitting}
                    />
                    {this.props.params.id && (
                      <button
                        type="button"
                        className="btn btn-danger dm-btn"
                        onClick={this.handleDelete}
                        disabled={submitting}
                      >
                        {t('common.delete')}
                      </button>
                    )}
                  </div>
                </div>
              </Sticky>
            </form>
          </div>

          {/* Faq classifier simulator */}
          <Sidebar titles={[t('simulator.title')]}>
            {faq.id && (
              <Simulator
                tabs={['faqClassifier', 'chat']}
                faq={faq}
                currentPageItemId={this.props.params.id}
                isCurrentPageFormDirty={isDirtyForm(this)}
                bot={bot}
              />
            )}
          </Sidebar>
        </Loader>
      </div>
    )
  }
}

const FaqItemEditForm = reduxForm({
  form: 'FaqItemEdit',
  enableReinitialize: true,
  validate,
})(FaqItemEdit)

export const mapStateToProps = (state, props) => {
  const account = lodash.first(Object.values(state.entities.accounts)) || {}
  const bot = state.entities.bots[props.params.bot_id] || {}
  const faq = state.entities.faqs[props.params.faq_id] || {}
  const item = state.entities.faq_items[props.params.id] || {}
  const root_category = lodash.find(state.entities.faq_categories, { faq_id: faq.id, parent_id: null })
  const applications = lodash.filter(state.entities.applications)
  const defaultValues = {
    categories: [],
    questions: [],
    patterns: [],
  }
  const formValues = getFormValues('FaqItemEdit')(state)
  let category1 = undefined
  if (root_category) {
    category1 = lodash.find(root_category.categories, { name: formValues?.categories[0] })
    category1 = category1 ? lodash.find(state.entities.faq_categories, { id: category1.id }) : undefined
  }
  let category2 = undefined
  if (category1) {
    category2 = lodash.find(category1.categories, { name: formValues?.categories[1] })
    category2 = category2 ? lodash.find(state.entities.faq_categories, { id: category2.id }) : undefined
  }
  let category3 = undefined
  if (category2) {
    category3 = lodash.find(category2.categories, { name: formValues?.categories[2] })
    category3 = category3 ? lodash.find(state.entities.faq_categories, { id: category3.id }) : undefined
  }
  let category4 = undefined
  if (category3) {
    category4 = lodash.find(category3.categories, { name: formValues?.categories[3] })
    category4 = category4 ? lodash.find(state.entities.faq_categories, { id: category4.id }) : undefined
  }

  // whether question field is opened or not. This is for the "add to question " feature in the PFL table.
  let defaultOpenedFaqMatching = false
  if (!props.params.id && props.location.query.example) {
    defaultOpenedFaqMatching = true
    defaultValues.questions = [props.location.query.example]
  }

  convertSystemVariableOfFaqItem(item, false)

  const initialValues = { ...defaultValues, ...item }
  initialValues.questions = [...initialValues.questions]
  initialValues.answers = initialValues.answers ? [...initialValues.answers] : []

  return {
    isFetching: isFetching(state, ['faqs', 'faq_categories', 'faq_items']),
    isGenerating: isFetching(state, 'generator'),
    initialValues: initialValues,
    bot: bot,
    faq: faq,
    root_category: root_category,
    category1: category1,
    category2: category2,
    category3: category3,
    category4: category4,
    item: item,
    applications: applications,
    currentChannel: state.chat.currentChannel,
    defaultOpenedFaqMatching,
    maxLengthFaqAnswer: account.max_length_faq_answer,
    formValues: formValues,
  }
}

export default connect(mapStateToProps)(FaqItemEditForm)
